import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-enregistrernotes',
  templateUrl: './enregistrernotes.component.html',
  styleUrls: ['./enregistrernotes.component.css']
})
export class EnregistrernotesComponent implements OnInit {

  classevvd : any = [];
  classevvdf : any = [];
  allbloquer: any = [];
  typeuser: String="";
  evall: String="";
  idclassevv: String="";
public items1: any;
public niveauvv: any;
public classevv: any;
public matierclasse: any;
public matierclasseF: any;
classematierevv: any = [];
//public eleveabsv: any;
eleveabs = [];
vuevv: String="Vue par tout le monde";
evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];

  constructor(private http: HttpClient,private url:UrlsService, private router: Router ,   private toastr: ToastrService) { 

  }
  showSuccessnote() {
    this.toastr.error('Error', "Les notes de cette matière existent déjà", {timeOut: 5000,});
  }
  showSuccessnotecc() {
    this.toastr.error('Error', "Veuillez choisir l'évaluation ", {timeOut: 5000,});
  }
  showSuccessnotecch() {
    this.toastr.error('Error', "Le trimestre a été clôturé", {timeOut: 5000,});
  }
  ngOnInit(): void {

        if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

   // localStorage.setItem('typeuser',"Super-administrateur");
    this.typeuser=localStorage.getItem('typeuser');
    

    
  this.recuptype_niveau().subscribe(
    data => {
      this.niveauvv = JSON.parse(JSON.stringify(data)); 
     // console.log(this.elevevv);
    },
      );
if(this.typeuser==='Enseignant'){
 

  this.http.post<any>(this.url.getclassebyenseignant,
    JSON.stringify({  
      anseignant:  localStorage.getItem('login'),

     })).subscribe(data => {



      console.log(data);

      this. classevvd = [];
      this. classevvdf = [];
      this. classevv = [];
      this.classematierevv= [];
      this.classevvd = JSON.parse(JSON.stringify(data)); 
      for (let i =0; i <this.classevvd.length; i++){
        this.classematierevv.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                                        "id_matiere":this.classevvd[i].id_matiere,
                                        "matriculE":this.classevvd[i].matriculE,
                                        "nom_matiere":this.classevvd[i].nom_matiere})); 
        let vv =0;
        for (let ig =0; ig <this.classevvdf.length; ig++){
          if(this.classevvd[i].id_classe==this.classevvdf[ig].idClasse){
            vv =1;
            break;
          }
        }
            if( vv ==0){
        this.classevvdf.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                                        "id_matiere":this.classevvd[i].id_matiere,
                                        "matriculE":this.classevvd[i].matriculE,
                                        "nom_matiere":this.classevvd[i].nom_matiere}));     
                                        
                                      }
      }
      this.classevv = JSON.parse(JSON.stringify( this.classevvdf)); 

/*
      window.location.reload();
     this.router.navigate(['Enregistrernotes']);
*/



      });
}else{
      this.recuptype_all_classe().subscribe(
        data => {
          this.classevv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );
        }


        
  }
  recuptype_niveau() {
    return this.http
    .get<Eleve[]>(this.url.all_niveaux)
  }
  recuptype_all_classe() {
    return this.http
    .get<Eleve[]>(this.url.all_classse)
  }

    recuptype_classe_niveau(niv: String) {
    return this.http
    .get<Eleve[]>(this.url.classebyniveau+niv)
  }

  recuptype_matiere_classe(classs: String) {
    return this.http
    .get<Eleve[]>(this.url.matierebyclasse+classs)
  }

  choixniveau(e: any) {
  
    console.log("----------------------"+e.target.value);
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  }


  choixclasse(e: any) {

    console.log("******-------------------------*********"+e.target.value);

    if(this.typeuser==='Enseignant'){
      this.idclassevv=e.target.value;
      localStorage.setItem('idclasse', e.target.value);
      this. matierclasse = [];
      this. matierclasseF = [];
      for (let i =0; i <this.classematierevv.length; i++){
        if(e.target.value==this.classematierevv[i].idClasse){

        this.matierclasseF.push(Object.assign({"id_matiere":this.classematierevv[i].id_matiere,
                                        "nom_matiere":this.classematierevv[i].nom_matiere}));     
                                        
              }
      }
      this.matierclasse = JSON.parse(JSON.stringify( this.matierclasseF)); 


    }else{



    this.idclassevv=e.target.value;
    localStorage.setItem('idclasse', e.target.value);
    this.recuptype_matiere_classe(e.target.value).subscribe(
      data => {
        this.matierclasse = JSON.parse(JSON.stringify(data)); 
       
      },
        );
      }
  }

  choixeval(e: any) {
    this.evall=e.target.value;
    localStorage.setItem('eval', e.target.value);


  }
  recuptype_alltrimestre() {
    return this.http
    .get<Eleve[]>(this.url.allbloquer)
  }

  selectmatiere(inCartProductId: String){

    
  

    if(this.evall==""){

      this.showSuccessnotecc()
    }else{

        let evalbb=this.evall;
      if(this.evall=="1"||this.evall=="2"){
        evalbb="Trimestre 1";
      }else if(this.evall=="3"||this.evall=="4"){
        evalbb="Trimestre 2";
      }else if(this.evall=="5"||this.evall=="6"){
        evalbb="Trimestre 3";
      }else{
        evalbb=this.evall;
      
      }










      let eval1=this.evall;
      if(this.evall=="Trimestre 1"){
        eval1="1";
      }else if(this.evall=="Trimestre 2"){
        eval1="3";
      }else if(this.evall=="Trimestre 3"){
        eval1="5";
      }else{
        eval1=this.evall;
      
      }
      
      
        /*  this.http.post<any>(this.url.verifnotes,
            JSON.stringify({  
              idclasse:  this.idclassevv,
              nommatiere: inCartProductId,
              evaluation:eval1,
             })).subscribe(data => {
             
              console.log(data);
              if(data.length==0){
      
               
                this.router.navigate(['Enregistrernotespropre/'+inCartProductId]);
            
            
              }else{
             console.log("Les notes de cette matière existe déjà");
             this.showSuccessnote();
              }
      
              });*/




    
      this.recuptype_alltrimestre().subscribe(
        data => {
          let iff =0;
          this.allbloquer = JSON.parse(JSON.stringify(data)); 

             if(this.allbloquer.length==0){
              iff=0;
             }else{

          for (let i =0; i <this.allbloquer.length; i++){
            if(evalbb==this.allbloquer[i].trimestre){
              iff=1;
              break;
            }
          }
        }


          if(iff==1){

            this.showSuccessnotecch()
          }else{
          this.http.post<any>(this.url.verifnotes,
            JSON.stringify({  
              idclasse:  this.idclassevv,
              nommatiere: inCartProductId,
              evaluation:eval1,
             })).subscribe(data => {
             
              console.log(data);
              if(data.length==0){
      
               
                this.router.navigate(['Enregistrernotespropre/'+inCartProductId]);
            
            
              }else{
             console.log("Les notes de cette matière existe déjà");
             this.showSuccessnote();
              }
      
              });


          }
        },
          );








          

        
      
    }


  }







}

