<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">PUBLIER UN MESSAGE</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>ACCUEIL</a></li>
                        <li>PUBLIER UN MESSAGE</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >

        <div class="row">
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">


                    <div class="form-group">
                        <select selected="" class="form-control"  (change)="Vue($event)" >
                            <option    style="background-color: transparent;  color: #07095f; ">Vue par tout le monde</option>
                            <option *ngFor="let u of cars" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
                          </select> 
                    </div>

                    <div class="form-group">
                        <input (change)="valChangeTitre($event)" type="text" name="Titre" id="Titre" class="form-control" placeholder="Titre:">
                    </div>

                    <div class="form-group">
                        <input (change)="valChangeDescription($event)" type="text" name="Description" id="Description" class="form-control" placeholder="Description:">
                    </div>

                    <div class="form-group">
                        <textarea (change)="valChangemessage($event)" type="text"  name="message" id="message" class="form-control" placeholder="Détails du message:"></textarea>
                     
                        
                    </div>



                    <div class="form-btn">
      
                        <button (click)="getValues()" class="theme-btn btn-orange">Publier</button>
                        <!--<button type="submit" class="default-btn-two">Get A Full Quote</button>--> 
                    </div>


                </div>
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">


                    <div class="col-lg-12 col-md-12">

                       
                
                            <div class="form-group">
                                <select selected="" class="form-control"  (change)="choixniveau($event)" >
                                    <option    style="background-color: transparent;  color: #07095f; ">Tous les niveaux</option>
                                    <option *ngFor="let u of niveauvv" [value]="u.nomniveau"    style="background-color: transparent;  color: #07095f; ">{{u.nomniveau}}</option>
                                  </select> 
                            </div>
                            <div class="form-group">
                                <select selected="" class="form-control"  (change)="choixclasse($event)" >
                                    <option    style="background-color: transparent;  color: #07095f; ">Toutes les classe</option>
                                    <option *ngFor="let u of classevv" [value]="u.idClasse"    style="background-color: transparent;  color: #07095f; ">{{u.idClasse}}</option>
                                  </select> 
                            </div>
                
                           
                       
                
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <ng2-smart-table [settings]="settings" class="table-responsive"
                        [source]="elevevv"
                        (custom)="Custom($event)">
                        >
                        </ng2-smart-table>  
                    </div>




                </div>
            </div>
    
        </div>
    </div>
</section>



        <div class="col-lg-12">
            <div class="table-responsive">
                <table class="table check-tbl">
                    <thead>
                        <tr>
                            <th>Photo</th>
                            <th>Matricule</th>
                            <th>Nom et Prénom</th>
                            <th>Classe</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>


                        <tr *ngFor="let ur of eleveabs" class="alert">
                            <td class="product-item-img">
                                <img style="width:30px;height:30px;" src="assets/etablissement/clipart.png" alt="">
                            </td>
                            <td class="product-item-name">{{ur.matricule}}</td>
                            <td class="product-item-price">{{ur.nom}} {{ur.prenom}}</td>
                            <td class="product-item-totle">{{ur.idClasse}}</td>
                            <td> <button (click)="delete(ur.matricule)" class='btn btn-danger' ><i class='bx bx-message-square-x'></i></button> </td>
                            <!--<td mat-cell *matCellDef="let cartItem"> <button mat-fab color="warn" (click)=onDeleteProduct(cartItem.inCartProductId)><mat-icon>delete</mat-icon></button> </td>
                           
                             <td class="product-item-close">
                                <a href="javascript:void(0);" data-dismiss="alert" aria-label="close" class="ti-close"></a>
                            </td> -->
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>


<!-- End Skillset -->
<app-footer-one></app-footer-one>































