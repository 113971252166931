import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { FormsModule ,ReactiveFormsModule} from '@angular/forms';

import { Ng2SmartTableModule } from 'ng2-smart-table';

import { AppComponent } from './app.component';
import { HeaderOneComponent } from './components/layouts/header-one/header-one.component';
import { HeaderTwoComponent } from './components/layouts/header-two/header-two.component';
import { HeaderThreeComponent } from './components/layouts/header-three/header-three.component';
import { FooterOneComponent } from './components/layouts/footer-one/footer-one.component';
import { FooterTwoComponent } from './components/layouts/footer-two/footer-two.component';
import { FooterThreeComponent } from './components/layouts/footer-three/footer-three.component';
import { NewsSidebarComponent } from './components/layouts/news-sidebar/news-sidebar.component';
import { ServiceSidebarComponent } from './components/layouts/service-sidebar/service-sidebar.component';
import { ShopSidebarComponent } from './components/layouts/shop-sidebar/shop-sidebar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceOneComponent } from './components/pages/service-one/service-one.component';
import { ServiceTwoComponent } from './components/pages/service-two/service-two.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PortfolioOneComponent } from './components/pages/portfolio-one/portfolio-one.component';
import { PortfolioTwoComponent } from './components/pages/portfolio-two/portfolio-two.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { NewsGridComponent } from './components/pages/news-grid/news-grid.component';
import { NewsStandardComponent } from './components/pages/news-standard/news-standard.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { ShopDetailComponent } from './components/pages/shop-detail/shop-detail.component';
import { ProjectDetailComponent } from './components/pages/project-detail/project-detail.component';
import { ServiceDetailComponent } from './components/pages/service-detail/service-detail.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule, HashLocationStrategy } from '@angular/common';
import { RouteReuseStrategy } from '@angular/router';
import { ImporterdonneeComponent } from './components/pages/parametre/importerdonnee/importerdonnee.component';

import { EnregistrernotesComponent } from './components/pages/notes/enregistrernotes/enregistrernotes.component';
import { EnregistrernotespropreComponent } from './components/pages/notes/enregistrernotespropre/enregistrernotespropre.component';
import { ConsulternotesComponent } from './components/pages/notes/consulternotes/consulternotes.component';
import { ConsulternotespropreComponent } from './components/pages/notes/consulternotespropre/consulternotespropre.component';
import { ExportPvComponent } from './components/pages/notes/export-pv/export-pv.component';
import { ExporternotesComponent } from './components/pages/notes/exporternotes/exporternotes.component';
import { ModifiernotesComponent } from './components/pages/notes/modifiernotes/modifiernotes.component'
import { ListemessageComponent } from './components/pages/messages/listemessage/listemessage.component';
import { PublierMessageComponent } from './components/pages/messages/publier-message/publier-message.component';
import { DetailmessageComponent } from './components/pages/messages/detailmessage/detailmessage.component';

import { MyLoginComponent } from './components/my-login/my-login.component';
import { EnregistrerUserComponent } from './components/pages/user/enregistrer-user/enregistrer-user.component';
import { ModifUserComponent } from './components/pages/user/modif-user/modif-user.component';
import { ConsultUserComponent } from './components/pages/user/consult-user/consult-user.component';
import { ModifUserPropreComponent } from './components/pages/user/modif-user-propre/modif-user-propre.component';
import { ImprimBordereauNoteComponent } from './components/pages/notes/imprim-bordereau-note/imprim-bordereau-note.component';
import { EnseignenttouserComponent } from './components/pages/user/enseignenttouser/enseignenttouser.component';
import { EnregistrerAbsenceComponent } from './components/pages/discipline/enregistrer-absence/enregistrer-absence.component';
import { ConsulterAbsenceComponent } from './components/pages/discipline/consulter-absence/consulter-absence.component';
import { DeletedataComponent } from './components/pages/parametre/deletedata/deletedata.component';
import { ClassesanseleveComponent } from './components/pages/parametre/classesanseleve/classesanseleve.component';
import { ModifAbsenceComponent } from './components/pages/discipline/modif-absence/modif-absence.component';
import { EnregistrerCDComponent } from './components/pages/discipline/enregistrer-cd/enregistrer-cd.component';
import { ConsulterCDComponent } from './components/pages/discipline/consulter-cd/consulter-cd.component';
import { ModifierCDComponent } from './components/pages/discipline/modifier-cd/modifier-cd.component';
import { ExpoerterabsComponent } from './components/pages/discipline/expoerterabs/expoerterabs.component';
import { ExpoertercdComponent } from './components/pages/discipline/expoertercd/expoertercd.component';
import { InfoEtablissementComponent } from './components/pages/parametre/info-etablissement/info-etablissement.component';
import { BloquertrimestreComponent } from './components/pages/parametre/bloquertrimestre/bloquertrimestre.component';
import { DashboardvvComponent } from './components/pages/dashboard/dashboardvv/dashboardvv.component';
import { UsersgcenseurComponent } from './components/pages/user/usersgcenseur/usersgcenseur.component';
import { ModiftypeuserComponent } from './components/pages/user/modiftypeuser/modiftypeuser.component';
import { SupimernoteComponent } from './components/pages/notes/supimernote/supimernote.component';
import { RemplirtableetatnoteComponent } from './components/pages/parametre/remplirtableetatnote/remplirtableetatnote.component';
@NgModule({
  declarations: [
    AppComponent,
    HeaderOneComponent,
    HeaderTwoComponent,
    HeaderThreeComponent,
    FooterOneComponent,
    FooterTwoComponent,
    FooterThreeComponent,
    NewsSidebarComponent,
    ServiceSidebarComponent,
    ShopSidebarComponent,
    HomeComponent,
    HomeTwoComponent,
    HomeThreeComponent,
    AboutComponent,
    ServiceOneComponent,
    ServiceTwoComponent,
    TeamComponent,
    PortfolioOneComponent,
    PortfolioTwoComponent,
    CareersComponent,
    NewsGridComponent,
    NewsStandardComponent,
    NewsDetailComponent,
    ContactComponent,
    ShopComponent,
    ShopDetailComponent,
    ProjectDetailComponent,
    ServiceDetailComponent,
    EnregistrernotesComponent,
    EnregistrernotespropreComponent,
    ConsulternotesComponent,
    ConsulternotespropreComponent,
    ExportPvComponent,
    ExporternotesComponent,
    ModifiernotesComponent,
    ListemessageComponent,
    DetailmessageComponent,
    ImporterdonneeComponent,
    PublierMessageComponent,
    MyLoginComponent,

    EnregistrerUserComponent,
    ModifUserComponent,
    ConsultUserComponent,
    ModifUserPropreComponent,
    ImprimBordereauNoteComponent,
    EnseignenttouserComponent,
    EnregistrerAbsenceComponent,
    ConsulterAbsenceComponent,
    DeletedataComponent,
    ClassesanseleveComponent,
    ModifAbsenceComponent,
    EnregistrerCDComponent,
    ConsulterCDComponent,
    ModifierCDComponent,
    ExpoerterabsComponent,
    ExpoertercdComponent,
    InfoEtablissementComponent,
    BloquertrimestreComponent,
    DashboardvvComponent,
    UsersgcenseurComponent,
    ModiftypeuserComponent,
    SupimernoteComponent,
    RemplirtableetatnoteComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule ,
    ReactiveFormsModule,
    Ng2SmartTableModule,



    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot(), // ToastrModule added

  ],
  providers: [
      Location, {
        //provide: LocationStrategy, useClass: HashLocationStrategy ,
        //useClass: PathLocationStrategy
        provide: LocationStrategy,
        useClass: HashLocationStrategy
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
