import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Message } from '../../../service/message';
import {Router} from '@angular/router';
@Component({
  selector: 'app-listemessage',
  templateUrl: './listemessage.component.html',
  styleUrls: ['./listemessage.component.scss']
})
export class ListemessageComponent implements OnInit {
  public message: any;
  constructor(private http: HttpClient,private url:UrlsService, private router: Router) { 


  }

  ngOnInit(): void {

    /*if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG"||localStorage.getItem('typeuser')=="Personnels de l'établissement"  ){

      }else{
        this.router.navigate(['accueil']);
      }
    }else{
      this.router.navigate(['']);
    }*/



    this.recup_all_message().subscribe(
      data => {
        this.message = JSON.parse(JSON.stringify(data)); 
        console.log(this.message[0].code);
        
     
      },
        );
  }
  recup_all_message() {
    return this.http
    .get<Message[]>(this.url.all_message)
  }
}
