<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">SUPPRIMER LES DONNEES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>PARAMETRE</a></li>
                        <li>SUPPRIMER LES DONNEES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Paramètre</span></h6>
                <h3 class="text-blue fw-700 title">Cliquer sur une table pour la vider<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">

            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="table-responsive">
                        <table class="table check-tbl">
                            <thead>
                                <tr>
                                    <th>Nom de la table</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
            
            
                                <tr *ngFor="let ur of data" class="alert">
                                    <td class="product-item-name">{{ur}}</td>
                                    <td> <button (click)="selectmatiere(ur)" class='btn btn-primary' ><i class="fa fa-pencil"></i></button> </td>
                                    <!--<td mat-cell *matCellDef="let cartItem"> <button mat-fab color="warn" (click)=onDeleteProduct(cartItem.inCartProductId)><mat-icon>delete</mat-icon></button> </td>
                                   
                                     <td class="product-item-close">
                                        <a href="javascript:void(0);" data-dismiss="alert" aria-label="close" class="ti-close"></a>
                                    </td> -->
                                </tr>
            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>