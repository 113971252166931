<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">EXPORTER LES CD</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>DISCIPLINE</a></li>
                        <li>EXPORTER LES CD</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




 

<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">










            <div  class="col-lg-12 ">
                <div class="contact-info-box mb-md-30">


                    <div  class="form-group">
                        <select selected="" class="form-control"  (change)="choixeval($event)" >
                            <option    >Evaluation</option>
                            <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
                          </select> 
                    </div>
                 
                    <div class="form-btn">
                        <button (click)="exporter_nouvel_vagueL()"  class="theme-btn btn-orange">exporter</button>
                    </div>


                </div>
               </div>









 
               
            </div>
        </div>
    </div>
</section>




























































<!-- End Skillset -->
<app-footer-one></app-footer-one>