import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-modif-absence',
  templateUrl: './modif-absence.component.html',
  styleUrls: ['./modif-absence.component.css']
})
export class ModifAbsenceComponent implements OnInit {
  typeuser: String="";
  listeclasse : any = [];
  listeclasseyyyyyyyy : any = [];



  Notes : any = [];
  Notes2 : any = [];

  ANJ : any = [];
  AJ : any = [];
  Rds : any = [];
  Cos : any = [];

  idclasse: String ="";
  //eval: String ="";
  //idmatire: String ="";
 nommatire: String ="";
 competencevv: String ="";
  public matierenom: any;
  public alleleve: any;
  evall: String="1";
  ligneelevepasnom : any = [];
  eval: String ="";
  evall1: String ="";
  evall2: String ="";
  noteligne1: String ="";
  noteligne2: String ="";
  nomprenomligne: String ="";
  //texteelevenotepasliste: String ="";
  evalls1: String ="";
  evalls2: String ="";
   booleval=false;
   public niveauvv: any;
public classevv: any;
   evaltab = ["1","2","3","4","5","6"];
  constructor(private _Activatedroute: ActivatedRoute,
    private http: HttpClient,private url:UrlsService, private router: Router,
    private toastr: ToastrService ) { 


    }
    showSuccessnotedd() {
      this.toastr.error('error', "les absences de cette classe n'existent pas !", {timeOut: 5000,});
    }
    showSuccess() {
      this.toastr.success('Success', 'Disciplines Modifier avec succès!', {timeOut: 5000,});
    }
    showSuccessnote() {
      this.toastr.error('error', "La valeur saisie doit être un nombre !", {timeOut: 5000,});
    }
    showSuccessnotes() {
      this.toastr.error('error', "Enregistrement impossible toutes les valeurs ne sont pas des nombres !", {timeOut: 5000,});
    }
  ngOnInit(): void {


  
    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

    

    this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );



        this.typeuser=localStorage.getItem('typeuser');
        if(this.typeuser==='SG'){

          this.http.post<any>(this.url.classebyuser,
            JSON.stringify({  
              user:  localStorage.getItem('login'),
        
             })).subscribe(data => {
        
        
        
              console.log(data);
              this.listeclasseyyyyyyyy = [];
              this.listeclasse = [];
              this.listeclasse = JSON.parse(JSON.stringify(data)); 
              for (let i =0; i <this.listeclasse.length; i++){
                this.listeclasseyyyyyyyy.push(Object.assign({"idClasse":this.listeclasse[i].classe,
                                                "loguin":this.listeclasse[i].matricule})); 
              }
              this.classevv = JSON.parse(JSON.stringify( this.listeclasseyyyyyyyy)); 
 
              });
        }else{
          this.recuptype_all_classe().subscribe(
            data => {
              this.classevv = JSON.parse(JSON.stringify(data)); 
             // console.log(this.elevevv);
            },
              );
            
                }

          





  }








  choixniveau(e: any) {
  
    console.log("----------------------"+e.target.value);
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  }


  choixclasse(e: any) {
    this.idclasse=e.target.value;



        this.http.post<any>(this.url.allabsence,
          JSON.stringify({  
            eval:this.evall,
            idclasse:this.idclasse,
           })).subscribe(data => {
            this.alleleve = JSON.parse(JSON.stringify(data)); 
            if(this.alleleve.length>0){
              for (let i =0; i <this.alleleve.length; i++){



                this.ANJ.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["anj"]}));
                this.Cos.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["aj"]}));      
                this.Rds.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["reds"]}));  
                   this.AJ.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["cons"]}));
  
               
              }

            }else{
             this.showSuccessnotedd();

            }
   


            });

  }

  choixeval(e: any) {
    this.evall=e.target.value;

        this.http.post<any>(this.url.allabsence,
          JSON.stringify({  
            eval:this.evall,
            idclasse:this.idclasse,
           })).subscribe(data => {
            this.alleleve = JSON.parse(JSON.stringify(data)); 
            if(this.alleleve.length>0){
              for (let i =0; i <this.alleleve.length; i++){



                this.ANJ.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["anj"]}));
                this.Cos.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["aj"]}));      
                this.Rds.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["reds"]}));  
                   this.AJ.push(Object.assign({mat:this.alleleve[i]["Matricule"], note:this.alleleve[i]["cons"]}));
  
               
              }

            }else{
             this.showSuccessnotedd();

            }
   


            });

  }


  recuptype_niveau() {
    return this.http
    .get<Eleve[]>(this.url.all_niveaux)
  }
  recuptype_all_classe() {
    return this.http
    .get<Eleve[]>(this.url.all_classse)
  }

    recuptype_classe_niveau(niv: String) {
    return this.http
    .get<Eleve[]>(this.url.classebyniveau+niv)
  }

  elevebyclasse(idclasse: String) {
    return this.http
    .get<Eleve[]>(this.url.elevebyclasse+idclasse)
  }






  Enregistrer():void{



    let non=0;
   for (let j =0; j <this.ANJ.length; j++){
     
   if(this.verif_note_isnumber(this.ANJ[j].note)){
     
    }else{
     non=1;
     break;
    }
  }
  for (let j =0; j <this.AJ.length; j++){
     
   if(this.verif_note_isnumber(this.AJ[j].note)){
     
    }else{
     non=1;
     break;
    }
  }
  for (let j =0; j <this.Rds.length; j++){
     
   if(this.verif_note_isnumber(this.Rds[j].note)){
     
    }else{
     non=1;
     break;
    }
  }
  for (let j =0; j <this.Cos.length; j++){
     
   if(this.verif_note_isnumber(this.Cos[j].note)){
     
    }else{
     non=1;
     break;
    }
  }
  


if(non==1){
 this.showSuccessnotes() ;

}else{



 let chaineNotes2uuu="";

 for (let i =0; i <this.alleleve.length; i++){
   var anjbien ="0";
   var ajbien ="0";
   var Rdsbien ="0";
   var Cosbien ="0";

   for (let j =0; j <this.ANJ.length; j++){
   if(this.alleleve[i]["Matricule"]==this.ANJ[j].mat){
   anjbien =this.ANJ[j].note;
   break;
      }
      }
          for (let j =0; j <this.AJ.length; j++){
   if(this.alleleve[i]["Matricule"]==this.AJ[j].mat){
     ajbien =this.AJ[j].note;
   break;
      }
      }
          for (let j =0; j <this.Rds.length; j++){
   if(this.alleleve[i]["Matricule"]==this.Rds[j].mat){
     Rdsbien =this.Rds[j].note;
   break;
      }
      }
          for (let j =0; j <this.Cos.length; j++){
   if(this.alleleve[i]["Matricule"]==this.Cos[j].mat){
     Cosbien =this.Cos[j].note;
   break;
      }
      }



      chaineNotes2uuu=chaineNotes2uuu+this.alleleve[i]["Matricule"]+":"+this.alleleve[i]["id"]+":"+anjbien+":"+ajbien+":"+Rdsbien+":"+Cosbien+";";


}







 
 let ccf=chaineNotes2uuu.substring(0,chaineNotes2uuu.length-1);


 this.http.post<any>(this.url.updateabs,
   JSON.stringify({  
     discipline:ccf,
     evall:this.evall,
    })).subscribe(data => {


  // this.router.navigate(['Enregistrernotes']);
    this.showSuccess() ;
     });




}
  }










  ANJvv(e:Event, index:string):void{
  


    let v = (e.target as HTMLInputElement).value
  console.log("***************---------------**"+v);


    if(this.ANJ.length==0){
     

   if(this.verif_note_isnumber(v)){
      this.ANJ.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
      }else{
        let indexxx =0;
        let vv =0;
    for (let i =0; i <this.ANJ.length; i++){
     // console.log(this.Notes[i].mat);

    if(this.ANJ[i].mat==index){
     
      indexxx =i;
      vv =1;
      break;
    }
    }
    
    if(vv ==1){
      if(this.verif_note_isnumber(v)){
      this.ANJ[indexxx].note=v;
    }else{

      this.showSuccessnote() ;
    }
     }else{
      if(this.verif_note_isnumber(v)){
      this.ANJ.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
    }


  }
  console.log(this.ANJ);
  }


  AJvv(e:Event, index:string):void{
    let v = (e.target as HTMLInputElement).value
    if(this.AJ.length==0){
     

   if(this.verif_note_isnumber(v)){
      this.AJ.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
      }else{
        let indexxx =0;
        let vv =0;
    for (let i =0; i <this.AJ.length; i++){
     // console.log(this.Notes[i].mat);

    if(this.AJ[i].mat==index){
     
      indexxx =i;
      vv =1;
      break;
    }
    }
    
    if(vv ==1){
      if(this.verif_note_isnumber(v)){
      this.AJ[indexxx].note=v;
    }else{

      this.showSuccessnote() ;
    }
     }else{
      if(this.verif_note_isnumber(v)){
      this.AJ.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
    }


  }
  console.log(this.AJ);
  }



  Rdsvv(e:Event, index:string):void{
    let v = (e.target as HTMLInputElement).value
    if(this.Rds.length==0){
     

   if(this.verif_note_isnumber(v)){
      this.Rds.push(Object.assign({mat:index, note:v}));     
    }else{

      this.showSuccessnote() ;
    }
      }else{
        let indexxx =0;
        let vv =0;
    for (let i =0; i <this.Rds.length; i++){
     // console.log(this.Notes[i].mat);

    if(this.Rds[i].mat==index){
     
      indexxx =i;
      vv =1;
      break;
    }
    }
    
    if(vv ==1){
      if(this.verif_note_isnumber(v)){
      this.Rds[indexxx].note=v;
    }else{

      this.showSuccessnote() ;
    }
     }else{
      if(this.verif_note_isnumber(v)){
      this.Rds.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
    }


  }
  console.log(this.Rds);
  }

  Cosvv(e:Event, index:string):void{
    let v = (e.target as HTMLInputElement).value
    if(this.Cos.length==0){
     

   if(this.verif_note_isnumber(v)){
      this.Cos.push(Object.assign({mat:index, note:v})); 
    }else{

      this.showSuccessnote() ;
    }
      }else{
        let indexxx =0;
        let vv =0;
    for (let i =0; i <this.Cos.length; i++){
     // console.log(this.Notes[i].mat);

    if(this.Cos[i].mat==index){
     
      indexxx =i;
      vv =1;
      break;
    }
    }
    
    if(vv ==1){
      if(this.verif_note_isnumber(v)){
      this.Cos[indexxx].note=v;
    }else{

      this.showSuccessnote() ;
    }
     }else{
      if(this.verif_note_isnumber(v)){
      this.Cos.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
    }


  }
  console.log(this.Cos);
  }





  verif_note_isnumber(note:String) :boolean{

    let tt:boolean;
   
   
    if( note.length==0){
     tt=false;

     //Swal.fire('La note ne doit pas être vide.');
   }else{
   
   
   
         if(!isNaN(Number(note))){
         
           if(Number(note)>=0 && Number(note)<=500){
             tt=true;
           }else{
             tt=false;
           //  Swal.fire("La note doit être comprise entre 0 et 20.");
              }
   
           }else{
           if(note!="-"){
             tt=false;
           //  Swal.fire("La note saisie n'est pas valide.");
          
         }else{
           tt=true;
         }
           }
       
         }
   
       return tt;
     }

}
