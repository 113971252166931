import { Component, OnInit } from '@angular/core';


import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UrlsService } from '../services/urls.service';

@Component({
  selector: 'app-my-login',
  templateUrl: './my-login.component.html',
  styleUrls: ['./my-login.component.scss']
})
export class MyLoginComponent implements OnInit {






  index = 1;
  destroyByClick = true;
  duration = 5000;
  hasIcon = true;
  data: any = null;
  model: any = {};
  loading = false;
  returnUrl: string;
  erreur= false;
  message="";

  constructor(private _Activatedroute: ActivatedRoute,
    private router: Router ,
    private http: HttpClient,private url:UrlsService,

     ) {



     }










  onSubmit(username, password) {

    this.loading = true;

    console.log("-------------"+username)
    console.log("-------------"+password)
    
    this.http.post<any>(this.url.login,
      JSON.stringify({  
        login: username,
        password: password,
       })).subscribe(data => {
 
 console.log(data)
       if (data) {
          this.erreur=!data.success;
          console.log(data);
          if(!data.success){
               this.message="Il y a une erreur";
               this.loading = false;
             //  this.showToast('danger', 'ERREUR!', "bien vouloir contacter l'administrateur");
          }else{
            localStorage.setItem('token', 'token');
            localStorage.setItem('login', data.login);
            localStorage.setItem('id', data.id);
            localStorage.setItem('nomuser', data.nomuser);
            localStorage.setItem('prenomuser', data.prenomuser);
            localStorage.setItem('mail', data.mail);
            localStorage.setItem('typeuser', data.typeuser);
           
            
            window.location.reload();



            this.router.navigate(['accueil']);
          
            this.loading = false;

      
      
          }
            
          }
       }); 

 






   
 
 
  }
    

  ngOnInit() {
//localStorage.removeItem('user');
//localStorage.removeItem('token');
  }

}
