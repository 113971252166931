import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.css']
})
export class HeaderOneComponent implements OnInit {
  typeuser: String ="";
  constructor(private router: Router ) { }

  ngOnInit(): void {

   
    this.typeuser=localStorage.getItem('typeuser');


    console.log("*****************************"+this.typeuser)
  }
  Deconnexion() {
    // localStorage.removeItem('user');
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.removeItem('id');
    localStorage.removeItem('nomuser');
     localStorage.removeItem('prenomuser');
     localStorage.removeItem('mail');
     localStorage.removeItem('typeuser');
     this.router.navigate(['']);


 }
}
