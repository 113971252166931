<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">About Us</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start About us -->
<section class="section-padding-bottom">


    
    <div class="top-about-sec">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="about-wrap-box">
                        <div class="row no-gutters">
                            <div class="col-lg-6">
                                <div class="left-side full-height">
                                    <img src="assets/images/about-page.jpg" class="image-fit" alt="">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="right-side">
                                    <div class="section-header left-heading">
                                        <div class="section-heading">
                                            <h6 class="text-orange mb-xl-10 sub-heading"><span>About Us</span></h6>
                                            <h3 class="text-blue fw-700 title">Core level values and amazing team<span class="text-orange">.</span></h3>
                                            <p class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                                        </div>
                                    </div>
                                    <ul class="custom list-block">
                                        <li>
                                            <span class="text-orange"><i class="fal fa-check"></i></span>
                                            Kind business & Shop
                                        </li>
                                        <li>
                                            <span class="text-orange"><i class="fal fa-check"></i></span>
                                            24/7 online support
                                        </li>
                                        <li>
                                            <span class="text-orange"><i class="fal fa-check"></i></span>
                                            Solid solution with great
                                        </li>
                                        <li>
                                            <span class="text-orange"><i class="fal fa-check"></i></span>
                                            Good team members
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>





    <div class="counter-sec-2 style-2 count-section">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="counter-box">
                        <div class="row">
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-md-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-men-group-outline"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">5000</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Case Solved</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-md-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-trophy"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">120</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Get Rewards</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon mb-xs-40">
                                <div class="icon text-orange">
                                    <i class="flaticon-coffee"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">4921</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Cup Coffee</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="counter-with-icon">
                                <div class="icon text-orange">
                                    <i class="flaticon-remote"></i>
                                </div>
                                <div class="counter-text">
                                    <span class="count">100</span><span><i class="fal fa-plus"></i></span>
                                    <p class="no-margin fw-500">Country Cover</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About us -->
<!-- Start Services -->
<section class="bg-light-white section-padding-top">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Services</span></h6>
                <h3 class="text-blue fw-700 title">What we do<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="service-slider style-2 row">
                    <div class="col-12 slide-item">
                        <div class="service-block-3">
                            <div class="service-image animate-img">
                                <img src="assets/images/service-1.jpg" class="image-fit" alt="">
                            </div>
                            <div class="service-caption">
                                <div class="left-side">
                                    <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Finance</span></p>
                                    <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                                </div>
                                <a routerLink='/service-detail' class="block-btn">
                                    <i class="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="service-block-3">
                            <div class="service-image animate-img">
                                <img src="assets/images/service-2.jpg" class="image-fit" alt="">
                            </div>
                            <div class="service-caption">
                                <div class="left-side">
                                    <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Consultancy</span></p>
                                    <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                                </div>
                                <a routerLink='/service-detail' class="block-btn">
                                    <i class="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="service-block-3">
                            <div class="service-image animate-img">
                                <img src="assets/images/service-3.jpg" class="image-fit" alt="">
                            </div>
                            <div class="service-caption">
                                <div class="left-side">
                                    <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Strategy & Planning</span></p>
                                    <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                                </div>
                                <a routerLink='/service-detail' class="block-btn">
                                    <i class="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="service-block-3">
                            <div class="service-image animate-img">
                                <img src="assets/images/service-1.jpg" class="image-fit" alt="">
                            </div>
                            <div class="service-caption">
                                <div class="left-side">
                                    <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Finance</span></p>
                                    <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                                </div>
                                <a routerLink='/service-detail' class="block-btn">
                                    <i class="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="service-block-3">
                            <div class="service-image animate-img">
                                <img src="assets/images/service-2.jpg" class="image-fit" alt="">
                            </div>
                            <div class="service-caption">
                                <div class="left-side">
                                    <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Consultancy</span></p>
                                    <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                                </div>
                                <a routerLink='/service-detail' class="block-btn">
                                    <i class="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="service-block-3">
                            <div class="service-image animate-img">
                                <img src="assets/images/service-3.jpg" class="image-fit" alt="">
                            </div>
                            <div class="service-caption">
                                <div class="left-side">
                                    <p class="text-orange double-lines fw-600 mb-1 fs-16"><span>Strategy & Planning</span></p>
                                    <h4 class="fw-600 no-margin"><a routerLink='/service-detail'>Digital Consultancy</a></h4>
                                </div>
                                <a routerLink='/service-detail' class="block-btn">
                                    <i class="fal fa-arrow-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>












<section class="section-padding bg-light-white">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 mb-md-80">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Profit Share</span></h6>
                        <h3 class="text-blue fw-700 title">Any kind business profit share<span class="text-orange">.</span></h3>
                        <p class="desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam.</p>
                    </div>
                </div>
                <ul class="custom profit-list">
                    <li>Kind business & shop profit share</li>
                    <li>Solid solution with great intregation</li>
                </ul>
                <a routerLink='/project-detail' class="theme-btn btn-orange">
                    Case Study <i class="fal fa-plus"></i>
                </a>
            </div>
            <div class="col-lg-6">
                <div class="profit-chart-box">
                    <a routerLink='/' class="chart-logo">
                        <img src="assets/images/logo-icon.png" class="image-fit" alt="">
                    </a>
                    <a href="#" class="profit-icon icon-ps-1">
                        <span class="icon">
                            <i class="flaticon-receipt"></i>
                        </span>
                        <span class="icon-text">Finance</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-2">
                        <span class="icon">
                            <i class="flaticon-businessman"></i>
                        </span>
                        <span class="icon-text">Consultancy</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-3">
                        <span class="icon">
                            <i class="flaticon-tax"></i>
                        </span>
                        <span class="icon-text">Tax</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-4">
                        <span class="icon">
                            <i class="flaticon-label"></i>
                        </span>
                        <span class="icon-text">Profit Share</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-5">
                        <span class="icon">
                            <i class="flaticon-payment-method"></i>
                        </span>
                        <span class="icon-text">Banking</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-6">
                        <span class="icon">
                            <i class="flaticon-presentation"></i>
                        </span>
                        <span class="icon-text">Growth</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-7">
                        <span class="icon">
                            <i class="flaticon-suitcase"></i>
                        </span>
                        <span class="icon-text">Policy</span>
                    </a>
                    <a href="#" class="profit-icon icon-ps-8">
                        <span class="icon">
                            <i class="flaticon-credit"></i>
                        </span>
                        <span class="icon-text">Home Loan</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>










<!-- End Services -->
<!-- Start Progress bar -->
<section class="progress-bar-sec">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="custom-progress-bar">
                    <div class="progressbar-text">
                        <p class="fw-600">UI/UX Design</p>
                        <p class="text-orange fw-600">65%</p>
                    </div>
                    <div class="progressbar-wrap">
                        <div class="progressbar" data-width="65"></div>
                    </div>
                </div>
                <div class="custom-progress-bar">
                    <div class="progressbar-text">
                        <p class="fw-600">Financial Business</p>
                        <p class="text-orange fw-600">72%</p>
                    </div>
                    <div class="progressbar-wrap">
                        <div class="progressbar" data-width="72"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="custom-progress-bar">
                    <div class="progressbar-text">
                        <p class="fw-600">Consultancy</p>
                        <p class="text-orange fw-600">90%</p>
                    </div>
                    <div class="progressbar-wrap">
                        <div class="progressbar" data-width="90"></div>
                    </div>
                </div>
                <div class="custom-progress-bar">
                    <div class="progressbar-text">
                        <p class="fw-600">Support & Planning</p>
                        <p class="text-orange fw-600">82%</p>
                    </div>
                    <div class="progressbar-wrap">
                        <div class="progressbar" data-width="82"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Progress bar -->
<!-- Slider gallery -->
<div class="image-slider-gallery-sec">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="image-slider-gallery col-12">
                    <div class="slide-item">
                        <div class="animate-img">
                            <img src="assets/images/img-gallery-1.jpg" class="image-fit" alt="">
                        </div>
                    </div>
                    <div class="slide-item">
                        <div class="animate-img">
                            <img src="assets/images/img-gallery-2.jpg" class="image-fit" alt="">
                        </div>
                    </div>
                    <div class="slide-item">
                        <div class="animate-img">
                            <img src="assets/images/img-gallery-3.jpg" class="image-fit" alt="">
                        </div>
                    </div>
                    <div class="slide-item">
                        <div class="animate-img">
                            <img src="assets/images/img-gallery-1.jpg" class="image-fit" alt="">
                        </div>
                    </div>
                    <div class="slide-item">
                        <div class="animate-img">
                            <img src="assets/images/img-gallery-2.jpg" class="image-fit" alt="">
                        </div>
                    </div>
                    <div class="slide-item">
                        <div class="animate-img">
                            <img src="assets/images/img-gallery-3.jpg" class="image-fit" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Slider gallery -->
<!-- Start brand slider -->
<div class="section-padding clients bg-orange">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-10">
                <div class="brand-slider row">
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-1.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-2.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-3.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-4.png" class="image-fit" alt="">
                        </a>
                    </div>
                    <div class="slide-item col-12">
                        <a href="#" class="brand-box">
                            <img src="assets/images/client-5.png" class="image-fit" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End brand slider -->
<!-- Start Blog -->
<section class="section-padding">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>News</span></h6>
                <h3 class="text-blue fw-700 title">Get connect with us<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="blog-slider row">
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">24</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Finance</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            24th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-2.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">18</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Business</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            18th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 slide-item">
                        <div class="blog-style-1">
                            <div class="post-img animate-img">
                                <a routerLink='/news-detail'>
                                    <img src="assets/images/blog-1.jpg" class="image-fit" alt="">
                                </a>
                            </div>
                            <div class="post-date">
                                <span class="date">04</span>
                                <span class="month">June</span>
                            </div>
                            <div class="post-content">
                                <div class="post-category">
                                    <p class="double-lines text-orange fw-600 mb-1"><span><a href="#" class="text-orange">Consultancy</a></span></p>
                                </div>
                                <h2 class="post-title"><a routerLink='/news-detail'>Improving lives with tech HSE lighthouse project</a></h2>
                                <ul class="custom-flex post-meta">
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-user text-orange"></i>
                                            Rosalia D.
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i class="fal fa-calendar-alt text-orange"></i>
                                            04th June 2020
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog --> 
<app-footer-one></app-footer-one>