import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import { VERSION } from '@angular/core';
import * as XLSX from 'xlsx';
import { Infoe } from '../../../service/infoe';

import 'jspdf-autotable';
//import { jsPDF } from "jspdf";

import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

import 'jspdf';
import { decimalDigest } from '@angular/compiler/src/i18n/digest';

@Component({
  selector: 'app-export-pv',
  templateUrl: './export-pv.component.html',
  styleUrls: ['./export-pv.component.css']
})
export class ExportPvComponent implements OnInit {

  listeclasse : any = [];
  listeclasseyyyyyyyy : any = [];



  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notess: any;
  public notessmatiere: any;
  public notessmatiere2: any;
  public noteselevepasnom: any;
  //public eleveabsv: any;
  evall: String="Evaluation";
  matierevv: String="0";
  eleveabs = [];
  infoe : any = [];
  vuevv: String="Vue par tout le monde";
  vague: String="0";
  nommatirevvv: String="";
  typefichier: String="300";
  classematierevv: any = [];
  idclassevv: String="Toutes les classes";
  idniveau: String="Tous les niveaux";
  notevv = [];
  public alleleve: any;
  public nommatirecc: any;
  name = 'ExcelSheet.xlsx';
  evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];
  dataRecup=[];
  dataRecup2=[];
  elevepaslistevv=[];
  public matierclasseF: any;
  typeuser: String="";

  classevvd : any = [];
  classevvdf : any = [];

  NomFvv: String="";
NomEvv: String="";
Telvv: String="";
Villevv: String="";
Regionvv: String="";
Departementvv: String="";
devisevv: String="";
Nomresponsablevv: String="";


    constructor(private http: HttpClient,private url:UrlsService, private router: Router ) { 
  
    }
    /*showSuccess() {
      this.toastr.success('Success', 'Note modifiée avec succès!', {timeOut: 3000,});
    }
    showError() {
      this.toastr.success("Erreur", "Choisir la classe et l'évaluation!", {timeOut: 3000,});
    }*/
    ngOnInit(): void {
     if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
        ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
        ||localStorage.getItem('typeuser')=="SG" ){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }

      
   this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  


        this.typeuser=localStorage.getItem('typeuser');


            if(this.typeuser==='Enseignant'){


              this.http.post<any>(this.url.getclassebyenseignant,
                JSON.stringify({  
                  anseignant:  localStorage.getItem('login'),
            
                 })).subscribe(data => {
            
            
            
                  console.log(data);
            
                  this. classevvd = [];
                  this. classevvdf = [];
                  this. classevv = [];
                  this.classematierevv = [];
                  this.classevvd = JSON.parse(JSON.stringify(data)); 
                  for (let i =0; i <this.classevvd.length; i++){

                    this.classematierevv.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                    "id_matiere":this.classevvd[i].id_matiere,
                    "matriculE":this.classevvd[i].matriculE,
                    "nom_matiere":this.classevvd[i].nom_matiere})); 
            
                    let vv =0;
                    for (let ig =0; ig <this.classevvdf.length; ig++){
                      if(this.classevvd[i].id_classe==this.classevvdf[ig].idClasse){
                        vv =1;
                        break;
                      }
                    }
                        if( vv ==0){
                    this.classevvdf.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                                                    "id_matiere":this.classevvd[i].id_matiere,
                                                    "matriculE":this.classevvd[i].matriculE,
                                                    "nom_matiere":this.classevvd[i].nom_matiere}));     
                                                    
                                                  }
                  }
                  this.classevv = JSON.parse(JSON.stringify( this.classevvdf)); 
            
            /*
                  window.location.reload();
                 this.router.navigate(['Enregistrernotes']);
            */
            
            
            
                  });
            }else{


              if(this.typeuser==='Censeurs'){

                this.http.post<any>(this.url.classebyuser,
                  JSON.stringify({  
                    user:  localStorage.getItem('login'),
              
                   })).subscribe(data => {
              
              
              
                    console.log(data);
                    this.listeclasseyyyyyyyy = [];
                    this.listeclasse = [];
                    this.listeclasse = JSON.parse(JSON.stringify(data)); 
                    for (let i =0; i <this.listeclasse.length; i++){
                      this.listeclasseyyyyyyyy.push(Object.assign({"idClasse":this.listeclasse[i].classe,
                                                      "loguin":this.listeclasse[i].matricule})); 
                    }
                    this.classevv = JSON.parse(JSON.stringify( this.listeclasseyyyyyyyy)); 
       
                    });
              }else{

              this.recuptype_all_classe().subscribe(
                data => {
                  this.classevv = JSON.parse(JSON.stringify(data)); 
                 // console.log(this.elevevv);
                },
                  );

                }


                    }












    }







   recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }
  
    recuptype_matiere_classe(classs: String) {
      return this.http
      .get<Eleve[]>(this.url.matierebyclasse+classs)
    }


  

    choixniveau(e: any) {

      if(e.target.value=="Tous les niveaux"){

      }else{
        this.idniveau=e.target.value;
      
        this.recuptype_classe_niveau(e.target.value).subscribe(
          data => {
            this.classevv = JSON.parse(JSON.stringify(data)); 
           // console.log(this.elevevv);
          },
            );
      }


    }
  
  
    choixclasse(e: any) {
     

        this.idclassevv=e.target.value;










        if(this.typeuser==='Enseignant'){
          this. matierclasse = [];
          this. matierclasseF = [];
          for (let i =0; i <this.classematierevv.length; i++){
            if(e.target.value==this.classematierevv[i].idClasse){
    
            this.matierclasseF.push(Object.assign({"id_matiere":this.classematierevv[i].id_matiere,
                                            "nom_matiere":this.classematierevv[i].nom_matiere}));     
                                            
                  }
          }
          this.matierclasse = JSON.parse(JSON.stringify( this.matierclasseF)); 
    
    
        }else{
          this.recuptype_matiere_classe(e.target.value).subscribe(
            data => {
              this.matierclasse = JSON.parse(JSON.stringify(data)); 
             
            },
              );
          }
  
    }
    

  

    choixeval(e: any) {
    this.evall=e.target.value;
   /* if(  this.idclassevv=="Toutes les classes" ||this.evall=="Evaluation"){

      this.showError();

    }else{

      this.http.post<any>(this.url.getnoteclassev,
        JSON.stringify({  
          idclasse:this.idclassevv,
          evaluation:e.target.value,
         })).subscribe(data => {
          this.notessmatiere = JSON.parse(JSON.stringify(data)); 
  
  
             console.log(this.notessmatiere);
          });

    }*/



  }
  choixmatiere(e: any) {
     

    this.matierevv=e.target.value;


  }
    choixtypefichier(e: any) {
     

      this.typefichier=e.target.value;

    }

  


    exportexcel(tab: any,titreg:any): void {
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tab);
     
      /* generate workbook and add the worksheet */

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, titreg);
  }

















  recuptype_infoe() {
    return this.http
    .get<Infoe[]>(this.url.allinfoe)
  }


  
  export(){



    this.recuptype_infoe().subscribe(
      data => {
        this.infoe = JSON.parse(JSON.stringify(data)); 
        if(this.infoe.length>0){
          this.NomFvv=this.infoe[0]["nomF"];
          this.NomEvv=this.infoe[0]["nomE"];
          this.Telvv=this.infoe[0]["tel"];
          this.Villevv=this.infoe[0]["ville"];
          this.Regionvv=this.infoe[0]["region"];
          this.Departementvv=this.infoe[0]["departement"];
          this.devisevv=this.infoe[0]["devise"];
          this.Nomresponsablevv=this.infoe[0]["nomresponsable"];

        }





    // Col spans and row spans
    var doc= new jsPDF('l', 'pt') ;
    if(this.matierevv=="0"){
      doc= new jsPDF('l', 'pt') ;
      doc.setFontSize(12);
      doc.setTextColor(0);
      doc.setFontStyle('bold');
      doc.text('REPUBLIQUE DU CAMEROUN', 50, 50);
      doc.text('REPUBLIC OF CAMEROON', 500, 50);
      doc.text('PAIX-TRAVAIL-PATRIE', 50, 70);
      doc.text('PEACE-WORK-FATHERLAND', 500, 70);
      doc.text('-----------------', 50, 85);
      doc.text('-----------------', 500, 85);
      doc.text('MINISTERE DES ENSEIGNEMENTS SECONDAIRES', 50, 105);
      doc.text('MINISTRY OF SECONDARY EDUCATION', 500, 105);
      doc.text(this.NomFvv, 50, 125);
      doc.text(this.NomEvv, 500, 125);
      
      doc.setFontSize(14);
      doc.text('PV DES NOTES', 330, 160);
      doc.setFontSize(13);
      doc.setFontStyle('italic');
    }else{
      doc= new jsPDF('p', 'pt') ;
      doc.setFontSize(8);
      doc.setTextColor(0);
      doc.setFontStyle('bold');
      doc.text('REPUBLIQUE DU CAMEROUN', 50, 50);
      doc.text('REPUBLIC OF CAMEROON', 370, 50);
      doc.text('PAIX-TRAVAIL-PATRIE', 50, 70);
      doc.text('PEACE-WORK-FATHERLAND', 370, 70);
      doc.text('-----------------', 50, 85);
      doc.text('-----------------', 370, 85);
      doc.text('MINISTERE DES ENSEIGNEMENTS SECONDAIRES', 50, 105);
      doc.text('MINISTRY OF SECONDARY EDUCATION', 370, 105);
      doc.text(this.NomFvv, 50, 125);
      doc.text(this.NomEvv, 370, 125);
      
      doc.setFontSize(14);
      doc.text('PV DES NOTES', 240, 160);
      doc.setFontSize(13);
      doc.setFontStyle('italic');
    }




    if(this.matierevv=="0"){
      doc.text('Classe: '+this.idclassevv, 50, 190);
      doc.text('Evaluation: '+ this.evall, 300, 190);
      doc.text(' ', 600, 190);
    }else{
      doc.text('Classe: '+this.idclassevv, 50, 190);
      doc.text('Evaluation: '+ this.evall, 190, 190);
      doc.text('Matière: '+ localStorage.getItem('nomMatierefvvv'), 350, 190);
      doc.text(' ', 600, 190);
    }

    if(this.matierevv=="0"){
      var img = new Image();
      img.src = 'assets/etablissement/logo lgl n.png';
      doc.addImage(img, 'png', 370, 30, 80, 80);
    }else{
      var img = new Image();
      img.src = 'assets/etablissement/logo lgl n.png';
      doc.addImage(img, 'png', 250, 30, 80, 80);
    }


    for (var i = 0; i < this.dataRecup.length; i++) {
        var row = this.dataRecup[i];
        if (i % 5 === 0) {
        row['id'] = {rowSpan: 14, content: i / 5 + 1, styles: {valign: 'middle', halign: 'center'}};
        }
    }


    if(this.evall=="Trimestre 1"|| this.evall=="Trimestre 2" ||this.evall=="Trimestre 3"){
      let head1 = [];

      head1.push({content: 'N°', colSpan: 1, styles: {halign: 'center'}}) ;
      head1.push({content: 'Matricule', colSpan: 1, styles: {halign: 'center'}}) ;
      head1.push( {content: 'Nom et Prénom', colSpan: 1, styles: {halign: 'center'}}) ;
      if(this.matierevv=="0"){
        for (var i = 0; i < this.notessmatiere[0]["notematiere"].length; i++) {
          head1.push( {content: this.notessmatiere[0]["notematiere"][i].matiere, colSpan: 1, styles: {halign: 'center'}}) ;
    
        }
      }else{
        head1.push( {content: "Notes", colSpan: 1, styles: {halign: 'center'}}) ;
      }
  
      let headj = [];
      headj.push(head1) ;
     // console.log(headj);

      doc.autoTable({
        headerStyles: {
          lineWidth: 1,
          lineColor: [110,110,110 ],
          fillColor: [183,183,183 ],
          textColor: "#000000"
      },
      startY: 220,
      head: headj,
      body: this.dataRecup,
      theme: 'grid'
      });
  








      let head12 = [];

      head12.push({content: 'N°', colSpan: 1, styles: {halign: 'center'}}) ;
      head12.push({content: 'Matricule', colSpan: 1, styles: {halign: 'center'}}) ;
      head12.push( {content: 'Nom et Prénom', colSpan: 1, styles: {halign: 'center'}}) ;
      if(this.matierevv=="0"){
        for (var i = 0; i < this.notessmatiere2[0]["notematiere"].length; i++) {
          head12.push( {content: this.notessmatiere2[0]["notematiere"][i].matiere, colSpan: 1, styles: {halign: 'center'}}) ;
    
        }
      }else{
        head12.push( {content: "Notes", colSpan: 1, styles: {halign: 'center'}}) ;
      }
  
      let headj2 = [];
      headj2.push(head12) ;
     // console.log(headj);

      doc.autoTable({
        headerStyles: {
          lineWidth: 1,
          lineColor: [110,110,110 ],
          fillColor: [183,183,183 ],
          textColor: "#000000"
      },
      startY: 900,
      head: headj2,
      body: this.dataRecup2,
      theme: 'grid'
      });












      let head123 = [];
      head123.push( {content: 'Nom et Prénom', colSpan: 1, styles: {halign: 'center'}}) ;
      head123.push( {content: 'Eval', colSpan: 1, styles: {halign: 'center'}}) ;
      head123.push( {content: "Matière", colSpan: 1, styles: {halign: 'center'}}) ;
      head123.push( {content: "Notes", colSpan: 1, styles: {halign: 'center'}}) ;
      let headj2h = [];
      headj2h.push(head123) ;
     // console.log(headj);

      doc.autoTable({
        headerStyles: {
          lineWidth: 1,
          lineColor: [110,110,110 ],
          fillColor: [183,183,183 ],
          textColor: "#000000"
      },
      startY: 1900,
      head: headj2h,
      body: this.elevepaslistevv,
      theme: 'grid'
      });















    }else{
      let head1 = [];

      head1.push({content: 'N°', colSpan: 1, styles: {halign: 'center'}}) ;
      head1.push({content: 'Matricule', colSpan: 1, styles: {halign: 'center'}}) ;
      head1.push( {content: 'Nom et Prénom', colSpan: 1, styles: {halign: 'center'}}) ;
      if(this.matierevv=="0"){
        for (var i = 0; i < this.notessmatiere[0]["notematiere"].length; i++) {
          head1.push( {content: this.notessmatiere[0]["notematiere"][i].matiere, colSpan: 1, styles: {halign: 'center'}}) ;
    
        }
      }else{
        head1.push( {content: "Notes", colSpan: 1, styles: {halign: 'center'}}) ;
      }
  
      let headj = [];
      headj.push(head1) ;
     // console.log(headj);
  
      doc.autoTable({
        headerStyles: {
          lineWidth: 1,
          lineColor: [110,110,110 ],
          fillColor: [183,183,183 ],
          textColor: "#000000"
      },
      startY: 220,
      head: headj,
      body: this.dataRecup,
      theme: 'grid'
      });










      
      let head123 = [];
      head123.push( {content: 'Nom et Prénom', colSpan: 1, styles: {halign: 'center'}}) ;
      head123.push( {content: 'Eval', colSpan: 1, styles: {halign: 'center'}}) ;
      head123.push( {content: "Matière", colSpan: 1, styles: {halign: 'center'}}) ;
      head123.push( {content: "Notes", colSpan: 1, styles: {halign: 'center'}}) ;
      let headj2h = [];
      headj2h.push(head123) ;
     // console.log(headj);

      doc.autoTable({
        headerStyles: {
          lineWidth: 1,
          lineColor: [110,110,110 ],
          fillColor: [183,183,183 ],
          textColor: "#000000"
      },
      startY: 900,
      head: headj2h,
      body: this.elevepaslistevv,
      theme: 'grid'
      });
  
    }


    doc.save('PV_DES_NOTES.pdf');




      },
        );








    }







    bodyRowsPRODUI_DETAIL() {

     }

     recuptype_matiere_nom(id: String) {
      return this.http
      .get<Eleve[]>(this.url.matirebyidmatirer+id)
    }



    getValues() {

if(this.evall=="Trimestre 1"|| this.evall=="Trimestre 2" ||this.evall=="Trimestre 3"){
   let eval1vv1="";
   let eval1vv2="";
  if(this.evall=="Trimestre 1"){
    eval1vv1="1";
    eval1vv2="2";
  }else if(this.evall=="Trimestre 2" ){
    eval1vv1="3";
    eval1vv2="4";
  }else if(this.evall=="Trimestre 3"){
    eval1vv1="5";
    eval1vv2="6";
  }
  this.dataRecup=[];
  this.dataRecup2=[];
  let body = [];
  let body2 = [];
  this.elevepaslistevv=[];


  if(this.matierevv=="0"){


 
        this.http.post<any>(this.url.getnoteelevepasliste,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  eval1vv1,
           })).subscribe(data => {
            this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
            for (var i=0;i< this.noteselevepasnom.length;i++){
              var gett = []
              gett.push(this.noteselevepasnom[i].nom);
              gett.push(this.noteselevepasnom[i].eval);
              gett.push(this.noteselevepasnom[i].matiere);
              gett.push(this.noteselevepasnom[i].note);
        

              this.elevepaslistevv.push(gett);

              
                  }
          
            this.http.post<any>(this.url.getnoteclassev,
              JSON.stringify({  
                idclasse:this.idclassevv,
                evaluation:  eval1vv1,
               })).subscribe(data => {
                this.notessmatiere = JSON.parse(JSON.stringify(data)); 
            console.log(this.notessmatiere);
            for (var i=0;i< this.notessmatiere.length;i++){
              var gett = []
              gett.push((i+1));
              gett.push(this.notessmatiere[i].Matricule);
              gett.push(this.notessmatiere[i].nom+" "+this.notessmatiere[i].pnom);
              for (var j=0;j< this.notessmatiere[i]["notematiere"].length;j++){
                gett.push(this.notessmatiere[i]["notematiere"][j].notel);
              }
              body.push(gett);
              }
                  this.dataRecup=body;
                  this.export(); 
                 
                });
            });
  }else{
 
    this.recuptype_matiere_nom(this.matierevv).subscribe(
      data => {
        this.nommatirecc = JSON.parse(JSON.stringify(data)); 
        localStorage.setItem('nomMatierefvvv', this.nommatirecc[0]["nomMatiere"]);
        this.http.post<any>(this.url.getnoteelevepasliste,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  eval1vv1,
            matiere:  this.nommatirecc[0]["nomMatiere"],
           })).subscribe(data => {
            this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
            for (var i=0;i< this.noteselevepasnom.length;i++){
              var gett = []
              gett.push(this.noteselevepasnom[i].nom);
              gett.push(this.noteselevepasnom[i].eval);
              gett.push(this.noteselevepasnom[i].matiere);
              gett.push(this.noteselevepasnom[i].note);
        

              this.elevepaslistevv.push(gett);
                  }
            this.http.post<any>(this.url.getnoteclassev,
              JSON.stringify({  
                idclasse:this.idclassevv,
                evaluation:  eval1vv1,
               })).subscribe(data => {
                this.notessmatiere = JSON.parse(JSON.stringify(data)); 
            console.log(this.notessmatiere);
            for (var i=0;i< this.notessmatiere.length;i++){
              var gett = []
              gett.push((i+1));
              gett.push(this.notessmatiere[i].Matricule);
              gett.push(this.notessmatiere[i].nom+" "+this.notessmatiere[i].pnom);
              var nn="";
              for (var j=0;j< this.notessmatiere[i]["notematiere"].length;j++){
                if(this.matierevv==this.notessmatiere[i]["notematiere"][j].matiere){
               
                nn=this.notessmatiere[i]["notematiere"][j].notel;
              }
              }
              gett.push(nn);
              body.push(gett);
                
              }

      

                  this.dataRecup=body;
                  this.export(); 
                 
                });
            });
      },
        );
     

  }



  if(this.matierevv=="0"){


 
    this.http.post<any>(this.url.getnoteelevepasliste,
      JSON.stringify({  
        idclasse:this.idclassevv,
        evaluation:  eval1vv2,
       })).subscribe(data => {
        this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
        for (var i=0;i< this.noteselevepasnom.length;i++){
          var gett = []
          gett.push(this.noteselevepasnom[i].nom);
          gett.push(this.noteselevepasnom[i].eval);
          gett.push(this.noteselevepasnom[i].matiere);
          gett.push(this.noteselevepasnom[i].note);
    

          this.elevepaslistevv.push(gett);
              }
        console.log( this.noteselevepasnom );
        this.http.post<any>(this.url.getnoteclassev,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  eval1vv2,
           })).subscribe(data => {
            this.notessmatiere2 = JSON.parse(JSON.stringify(data)); 
        console.log(this.notessmatiere2);
        for (var i=0;i< this.notessmatiere2.length;i++){
          var gett = []
          gett.push((i+1));
          gett.push(this.notessmatiere2[i].Matricule);
          gett.push(this.notessmatiere2[i].nom+" "+this.notessmatiere2[i].pnom);
          for (var j=0;j< this.notessmatiere2[i]["notematiere"].length;j++){
            gett.push(this.notessmatiere2[i]["notematiere"][j].notel);
          }
          body2.push(gett);
          }
              this.dataRecup2=body2;
              this.export(); 
             
            });
        });
}else{

this.recuptype_matiere_nom(this.matierevv).subscribe(
  data => {
    this.nommatirecc = JSON.parse(JSON.stringify(data)); 
    localStorage.setItem('nomMatierefvvv', this.nommatirecc[0]["nomMatiere"]);
    this.http.post<any>(this.url.getnoteelevepasliste,
      JSON.stringify({  
        idclasse:this.idclassevv,
        evaluation:  eval1vv2,
        matiere:  this.nommatirecc[0]["nomMatiere"],
       })).subscribe(data => {
        this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
        for (var i=0;i< this.noteselevepasnom.length;i++){
          var gett = []
          gett.push(this.noteselevepasnom[i].nom);
          gett.push(this.noteselevepasnom[i].eval);
          gett.push(this.noteselevepasnom[i].matiere);
          gett.push(this.noteselevepasnom[i].note);
    

          this.elevepaslistevv.push(gett);
              }
        this.http.post<any>(this.url.getnoteclassev,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  eval1vv2,
           })).subscribe(data => {
            this.notessmatiere2 = JSON.parse(JSON.stringify(data)); 
        console.log(this.notessmatiere2);
        for (var i=0;i< this.notessmatiere2.length;i++){
          var gett = []
          gett.push((i+1));
          gett.push(this.notessmatiere2[i].Matricule);
          gett.push(this.notessmatiere2[i].nom+" "+this.notessmatiere2[i].pnom);
          var nn="";
          for (var j=0;j< this.notessmatiere2[i]["notematiere"].length;j++){
            if(this.matierevv==this.notessmatiere2[i]["notematiere"][j].matiere){
           
            nn=this.notessmatiere2[i]["notematiere"][j].notel;
          }
          }
          gett.push(nn);
          body2.push(gett);
            
          }

       

              this.dataRecup2=body2;
              this.export(); 
             
            });
        });
  },
    );
 

}

}else{



  this.dataRecup=[];
  let body = [];




  if(this.matierevv=="0"){


 
        this.http.post<any>(this.url.getnoteelevepasliste,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  this.evall,
           })).subscribe(data => {
            this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
            for (var i=0;i< this.noteselevepasnom.length;i++){
              var gett = []
              gett.push(this.noteselevepasnom[i].nom);
              gett.push(this.noteselevepasnom[i].eval);
              gett.push(this.noteselevepasnom[i].matiere);
              gett.push(this.noteselevepasnom[i].note);
        

              this.elevepaslistevv.push(gett);
                  }
 
            this.http.post<any>(this.url.getnoteclassev,
              JSON.stringify({  
                idclasse:this.idclassevv,
                evaluation:  this.evall,
               })).subscribe(data => {
                this.notessmatiere = JSON.parse(JSON.stringify(data)); 
            console.log(this.notessmatiere);
            for (var i=0;i< this.notessmatiere.length;i++){
              var gett = []
              gett.push((i+1));
              gett.push(this.notessmatiere[i].Matricule);
              gett.push(this.notessmatiere[i].nom+" "+this.notessmatiere[i].pnom);
              for (var j=0;j< this.notessmatiere[i]["notematiere"].length;j++){
                gett.push(this.notessmatiere[i]["notematiere"][j].notel);
              }
              body.push(gett);
              }
                  this.dataRecup=body;
                  this.export(); 
                 
                });
            });
  }else{
 
    this.recuptype_matiere_nom(this.matierevv).subscribe(
      data => {
        this.nommatirecc = JSON.parse(JSON.stringify(data)); 
        localStorage.setItem('nomMatierefvvv', this.nommatirecc[0]["nomMatiere"]);
        this.http.post<any>(this.url.getnoteelevepasliste,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  this.evall,
            matiere:  this.nommatirecc[0]["nomMatiere"],
           })).subscribe(data => {
            this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
            for (var i=0;i< this.noteselevepasnom.length;i++){
              var gett = []
              gett.push(this.noteselevepasnom[i].nom);
              gett.push(this.noteselevepasnom[i].eval);
              gett.push(this.noteselevepasnom[i].matiere);
              gett.push(this.noteselevepasnom[i].note);
        

              this.elevepaslistevv.push(gett);
                  }
            this.http.post<any>(this.url.getnoteclassev,
              JSON.stringify({  
                idclasse:this.idclassevv,
                evaluation:  this.evall,
               })).subscribe(data => {
                this.notessmatiere = JSON.parse(JSON.stringify(data)); 
            console.log(this.notessmatiere);
            for (var i=0;i< this.notessmatiere.length;i++){
              var gett = []
              gett.push((i+1));
              gett.push(this.notessmatiere[i].Matricule);
              gett.push(this.notessmatiere[i].nom+" "+this.notessmatiere[i].pnom);
              var nn="";
              for (var j=0;j< this.notessmatiere[i]["notematiere"].length;j++){
                if(this.matierevv==this.notessmatiere[i]["notematiere"][j].matiere){
               
                nn=this.notessmatiere[i]["notematiere"][j].notel;
              }
              }
              gett.push(nn);
              body.push(gett);
                
              }

              

                  this.dataRecup=body;
                  this.export(); 
                 
                });
            });
      },
        );
     

  }


}









    
    












    
        
     










  }
  }
  