import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { User } from '../../../service/user';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-modif-user',
  templateUrl: './modif-user.component.html',
  styleUrls: ['./modif-user.component.scss']
})
export class ModifUserComponent implements OnInit {

  settings = {
   
    columns: {
      nomuser: {
        title: 'nom',
        type: 'string',
       // width: '200px'
      },
      prenomuser: {
        title: 'prenom',
        type: 'string',
     //   width: '200px'
      },
      phonenumber: {
        title: 'Tel',
        type: 'string',
      },
      login: {
        title: 'Login',
        type: 'string',
      //  width: '20px'
      },
      typeuser: {
        title: 'Type utilisateur',
        type: 'string',
      //  width: '20px'
      },
      activated: {
        title: 'Activé',
        type: 'string',
      //  width: '20px'
      },
      id: {
        title: 'ID',
        type: 'string',
      //  width: '20px'
      },

    },

    pager: {
      display: false,
      perPage: 3,
    },
    actions: {
     delete: false,
     add: false,
     edit: false,
     select: false,
     title: 'Détail',
     custom: [
      {
        name: "routeToAPage1",
        title: " <i class='bx bx-add-to-queue'></i>"
      }
    ],
   },
   attr: {
    class: 'table table-hover'
  }


  };







public items1: any;
public elevevv: any;
//public eleveabsv: any;
eleveabs = [];
vuevv: String="Vue par tout le monde";
public users: any;
public classevv: any;
idclassevv: String="";
  constructor(private router: Router ,private http: HttpClient,private url:UrlsService,private toastr: ToastrService) { 

  }

  ngOnInit(): void {
    
   /* if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"){

      }else{
        this.router.navigate(['accueil']);
      }
    }else{
      this.router.navigate(['']);
    }*/
      this.recuptype_user().subscribe(
        data => {
          this.users = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );

  }

  recuptype_user() {
    return this.http
    .get<User[]>(this.url.users)
  }



  Custom(event){

   
    
    let lien = 'ModifUserPropre/'+ event.data.id;
    this.router.navigateByUrl(lien);
 /*
    this.eleveabs.push({ 
      "nom": event.data.nom,
      "prenom": event.data.prenom,
      "idClasse": event.data.idClasse,
      "matricule": event.data.matricule,
      "id": event.data.id,
    });*/

   }

}
