<!-- Start Footer -->
<footer class="section-padding bg-dark-blue footer">
    <div class="container">
        <div class="row">
            <div class="col-xl-2 col-md-3 col-sm-6">
              
            </div>
            <div class="col-xl-2 col-md-3 col-sm-6">
                <div class="footer-widgets mb-sm-80">
                    <h5 class="text-custom-white fw-600">Discipline<span class="text-orange">.</span></h5>
                  
                </div>
            </div>
            <div class="col-xl-2 col-md-3 col-sm-6">
                <div class="footer-widgets mb-sm-80">
                    <h5 class="text-custom-white fw-600">Travail<span class="text-orange">.</span></h5>
                  
                </div>
            </div>
            <div class="col-xl-2 col-md-3 col-sm-6">
                <div class="footer-widgets mb-xs-80">
                    <h5 class="text-custom-white fw-600">Succès<span class="text-orange">.</span></h5>
                  
                </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-6">
                <div class="footer-widgets newsletter">
                    <h5 class="text-custom-white fw-600">Abonnez-vous maintenant aux information de l'établissement<span class="text-orange">.</span></h5>
                    <div class="input-group mb-xl-20">
                        <input type="email" name="#" class="form-control form-control-custom" placeholder="Adresse e-mail">
                        <div class="input-group-append">
                            <button>
                                <i class="fal fa-envelope"></i>
                            </button>
                        </div>
                    </div>
                    <img src="assets/etablissement/logovv.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="copyright">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-6">
                <ul class="custom-flex ft-menu">
                    <li>
                        <a href="#" class="text-custom-white">Products</a>
                    </li>
                    <li>
                        <a href="#" class="text-custom-white">Platform</a>
                    </li>
                    <li>
                        <a href="#" class="text-custom-white">Refund Policy</a>
                    </li>
                </ul>
            </div>
            <div class="col-lg-4 col-md-6">
                <p class="text-custom-white fw-500">COPYRIGHT BY@<a href="#" class="text-custom-white">INTSCHOOL</a></p>
            </div>




        </div>
    </div>
</div>
<div id="back-top" class="back-top">
    <a href="#top"><i class="fal fa-long-arrow-up"></i></a>
</div>
<!-- End Footer -->