import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User } from '../../../service/user';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-enseignenttouser',
  templateUrl: './enseignenttouser.component.html',
  styleUrls: ['./enseignenttouser.component.css']
})
export class EnseignenttouserComponent implements OnInit {


  settings = {
   
    columns: {
      matricule: {
        title: 'matricule',
        type: 'string',
      //  width: '20px'
      },
      nom: {
        title: 'nom',
        type: 'string',
       // width: '200px'
      },
      prenom: {
        title: 'prenom',
        type: 'string',
     //   width: '200px'
      }

    },

    pager: {
      display: false,
      perPage: 3,
    },
    actions: {
     delete: false,
     add: false,
     edit: false,
     select: false,
     title: 'Détail',
     custom: [
      {
        name: "routeToAPage1",
        title: " <i class='fa fa-user-plus' aria-hidden='true'></i>"
      }
    ],
   },
   attr: {
    class: 'table table-hover'
  }


  };








  public items1: any;
  public enseignantvv: any;
  public alluservv: any;
  enseignantvvte = [];
  public classevv: any;
  public matierclasse: any;
  public notess: any;
  booleval=false;
  eval1="1";
  eval2="2";
  public notessff: any;
  public notesstrimestre: any;
  //public eleveabsv: any;
  public alleleve: any;
  eleveabs = [];
  public elevebynote: any;
  vuevv: String="Vue par tout le monde";
  evall: String="";
  nommatierevv: String="";
  ifchoixeval: String="eval";
  idclassevv: String="";
  evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];
  
    constructor(private http: HttpClient,private url:UrlsService, private router: Router,
      private toastr: ToastrService  ) { 
  
    }
    showSuccess() {
      this.toastr.success('Success', 'Utilisateurs enregistrés avec succès!', {timeOut: 5000,});
    }
    showSuccessnote() {
      this.toastr.error('Error', "Tous les utilisateurs existent déjà", {timeOut: 5000,});
    }
    ngOnInit(): void {

    this.recup_enseignant().subscribe(
      data => {
        this.enseignantvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  
   
    }
    recup_enseignant() {
      return this.http
      .get<Eleve[]>(this.url.enseignantall)
    }

    recuptype_user() {
      return this.http
      .get<User[]>(this.url.users)
    }
  
    Custom(event){

      this.enseignantvvte.push({ 
        "matricule": event.data.matricule,
        "nom": event.data.nom,
        "prenom": event.data.prenom,
      });



  
     }


    deleteligne(inCartProductId: String){

      const product = this.enseignantvvte.find(p => {
        return p.matricule === inCartProductId;
      });

      if(product){
        this.enseignantvvte.splice(this.enseignantvvte.map(p => p.matricule).indexOf(inCartProductId), 1);
      }
 
    }
    Enregistrer() {
      let chaineNotes2="";











     this.recuptype_user().subscribe(
        data => {
          this.alluservv = JSON.parse(JSON.stringify(data)); 
         for (let i =0; i <this.enseignantvvte.length; i++){
            let ut =0;
            for (let p =0; p <this.alluservv.length; p++){
              if(this.alluservv[p]["login"]==this.enseignantvvte[i]["matricule"]){
                ut =1;
                  }
            }
            if( ut ==0){
            chaineNotes2=chaineNotes2+this.enseignantvvte[i]["matricule"]+":"+this.enseignantvvte[i]["nom"]+":"+this.enseignantvvte[i]["prenom"]+";";
                  }
          }


             if(chaineNotes2==""){
              this.showSuccessnote();
              
            }else{
              let ch2=chaineNotes2.substring(0,chaineNotes2.length-1);
              console.log("------------"+ch2);
              this.http.post<any>(this.url.adduseren,
                JSON.stringify({  
          
                  datavv:ch2 ,
        
                 })).subscribe(data => {
                 this.showSuccess();
                  }); 
            }


        },
          );








    }
  
  
  
  

  }
  