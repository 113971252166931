<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">DASHBOARD</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>DASHBOARD</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




<section class="section-padding">
    <div >
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Notes</span></h6>
                <h3 class="text-blue fw-700 title">Etat de remplissage des notes<span class="text-orange">.</span></h3>
            </div>
        </div>


        <div class="row">
            <div class="col-lg-6" >
                <div  id="container1"></div>
                <div class="score-stat" ><span><i style="color:#0ce629">{{pourceneval1}}</i> % <p style="color:#FF7A2A"> <B> EVALUATION 1</B></p></span></div> 
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixniveau1($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv1" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixclasse1($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv1" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                    
                                        <th>Code matière</th>
                                        <th>Nom matière</th>
                                        <th>Etats</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of matierclasse1" class="alert">
                                        <td class="product-item-name">{{ur?.matiere}}</td>
                                        <td class="product-item-price">{{ur?.nommatiere}}</td>
                                        <td class="product-item-price"> {{ur?.etat}}</td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>






        <div class="row">
            <div class="col-lg-6" >
                <div  id="container2"></div>
                <div class="score-stat" ><span><i style="color:#0ce629">{{pourceneval2}}</i> % <p style="color:#FF7A2A"><B> EVALUATION 2</B></p></span></div> 
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixniveau2($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv2" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixclasse2($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv2" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                    
                                        <th>Code matière</th>
                                        <th>Nom matière</th>
                                        <th>Etats</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of matierclasse2" class="alert">
                                        <td class="product-item-name">{{ur?.matiere}}</td>
                                        <td class="product-item-price">{{ur?.nommatiere}}</td>
                                        <td class="product-item-price"> {{ur?.etat}}</td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>






        <div class="row">
            <div class="col-lg-6" >
                <div  id="container3"></div>
                <div class="score-stat" ><span><i style="color:#0ce629">{{pourceneval3}}</i> % <p style="color:#FF7A2A"><B> EVALUATION 3</B></p></span></div> 
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixniveau3($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv3" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixclasse3($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv3" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                    
                                        <th>Code matière</th>
                                        <th>Nom matière</th>
                                        <th>Etats</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of matierclasse3" class="alert">
                                        <td class="product-item-name">{{ur?.matiere}}</td>
                                        <td class="product-item-price">{{ur?.nommatiere}}</td>
                                        <td class="product-item-price"> {{ur?.etat}}</td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>









        <div class="row">
            <div class="col-lg-6" >
                <div  id="container4"></div>
                <div class="score-stat" ><span><i style="color:#0ce629">{{pourceneval4}}</i> % <p style="color:#FF7A2A"><B> EVALUATION 4</B></p></span></div> 
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixniveau4($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv4" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixclasse4($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv4" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                    
                                        <th>Code matière</th>
                                        <th>Nom matière</th>
                                        <th>Etats</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of matierclasse4" class="alert">
                                        <td class="product-item-name">{{ur?.matiere}}</td>
                                        <td class="product-item-price">{{ur?.nommatiere}}</td>
                                        <td class="product-item-price"> {{ur?.etat}}</td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div class="row">
            <div class="col-lg-6" >
                <div  id="container5"></div>
                <div class="score-stat" ><span><i style="color:#0ce629">{{pourceneval5}}</i> % <p style="color:#FF7A2A"><B> EVALUATION 5</B></p></span></div> 
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixniveau5($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv5" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixclasse5($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv5" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                    
                                        <th>Code matière</th>
                                        <th>Nom matière</th>
                                        <th>Etats</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of matierclasse5" class="alert">
                                        <td class="product-item-name">{{ur?.matiere}}</td>
                                        <td class="product-item-price">{{ur?.nommatiere}}</td>
                                        <td class="product-item-price"> {{ur?.etat}}</td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>





        <div class="row">
            <div class="col-lg-6" >
                <div  id="container6"></div>
                <div class="score-stat" ><span><i style="color:#0ce629">{{pourceneval6}}</i> % <p style="color:#FF7A2A"><B> EVALUATION 6</B></p></span></div> 
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixniveau6($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv6" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" >
                        <select selected="" class="form-control"  (change)="choixclasse6($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv6" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>
                    <div class="form-group">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                    
                                        <th>Code matière</th>
                                        <th>Nom matière</th>
                                        <th>Etats</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of matierclasse6" class="alert">
                                        <td class="product-item-name">{{ur?.matiere}}</td>
                                        <td class="product-item-price">{{ur?.nommatiere}}</td>
                                        <td class="product-item-price"> {{ur?.etat}}</td>
                                      
                                    </tr>
                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

















































































<!-- End Skillset -->
<app-footer-one></app-footer-one>