import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import { User } from '../../../service/user';
@Component({
  selector: 'app-enregistrer-user',
  templateUrl: './enregistrer-user.component.html',
  styleUrls: ['./enregistrer-user.component.scss']
})
export class EnregistrerUserComponent implements OnInit {



  cars = [
    { id: "Super-administrateur", name: "Super-administrateur" },
    { id: "Administrateur", name: "Administrateur" },
    { id: "Enseignant", name: "Enseignant" },
    { id: "Personnels de l'établissement", name: "Personnels de l'établissement" },
    { id: "Parents", name: "Parents" },
    { id: "Eleves", name: "Eleves" },
    { id: "Invites", name: "Invites" },
    { id: "Censeurs", name: "Censeurs" },
    { id: "SG", name: "SG" },
    { id: "Notes", name: "Notes" },
];
sexe = [
  { id: "M", name: "M" },
  { id: "F", name: "F" },
];
public users: any;
typeuser: String="-";
sexegettt: String="M";
ChangeNom: String="";
ChangePrenom: String="";
ChangeTel: String="";
ChangeLogin: String="";
ChangePassword: String="";
  constructor(private router: Router ,private http: HttpClient,private url:UrlsService,private toastr: ToastrService) { 

  }
  showSuccess() {
    this.toastr.success('Success', 'Utilisateur enregistré avec succès!', {timeOut: 3000,});
  }
  showSuccessnotes() {
    this.toastr.error('error', "cet utilisateur existe déjà !", {timeOut: 5000,});
  }
  ngOnInit(): void {
   /* if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"){

      }else{
        this.router.navigate(['accueil']);
      }
    }else{
      this.router.navigate(['']);
    }*/

  }






  sexeget(e: any) {

    this.sexegettt=e.target.value;
  }


  Vue(e: any) {

    this.typeuser=e.target.value;
  }
  valChangeNom(e:Event):void{
    this.ChangeNom = (e.target as HTMLInputElement).value
  }
  valChangePrenom(e:Event):void{
    this.ChangePrenom = (e.target as HTMLInputElement).value
  }
  valChangeTel(e:Event):void{
    this.ChangeTel = (e.target as HTMLInputElement).value

    /*if(!isNaN(Number((e.target as HTMLInputElement).value))){
      this.ChangeTel =Number((e.target as HTMLInputElement).value) 
    }else{
      this.ChangeTel =0
    }*/

  }
  valChangeLogin(e:Event):void{
    this.ChangeLogin = (e.target as HTMLInputElement).value
  }
  valChangePassword(e:Event):void{
    this.ChangePassword = (e.target as HTMLInputElement).value
  }
  valChangePasswordc(e:Event):void{
    //this.ChangePassword = (e.target as HTMLInputElement).value
  }
  recuptype_user() {
    return this.http
    .get<User[]>(this.url.users)
  }
  Enregistrer() {

    this.recuptype_user().subscribe(
      data => {
        this.users = JSON.parse(JSON.stringify(data)); 
        let ut =0;
        for (let i =0; i <this.users.length; i++){
          if(this.users[i]["login"]==this.ChangeLogin){
            ut =1;
              }
        }


          if(ut==1){
            
            this.showSuccessnotes();
           }else{
            this.http.post<any>(this.url.adduser,
              JSON.stringify({  
        
                Password:this.ChangePassword ,
                Login:this.ChangeLogin,
                Tel:this.ChangeTel,
                Sexe:this.sexegettt,
                Prenom: this.ChangePrenom,
                Nom:this.ChangeNom,
                typeuser:this.typeuser
               })).subscribe(data => {
                this.router.navigate(['EnregistrerUser']);
               this.showSuccess();
                }); 
             }
        
      },
        );




}
}
