import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { User } from '../../../service/user';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-consult-user',
  templateUrl: './consult-user.component.html',
  styleUrls: ['./consult-user.component.scss']
})
export class ConsultUserComponent implements OnInit {
  settings = {
   
    columns: {
      nomuser: {
        title: 'nom',
        type: 'string',
       // width: '200px'
      },
      prenomuser: {
        title: 'prenom',
        type: 'string',
     //   width: '200px'
      },
      phonenumber: {
        title: 'Tel',
        type: 'string',
      },
      login: {
        title: 'Login',
        type: 'string',
      //  width: '20px'
      },
      typeuser: {
        title: 'Type utilisateur',
        type: 'string',
      //  width: '20px'
      },
      activated: {
        title: 'Activé',
        type: 'string',
      //  width: '20px'
      },
      id: {
        title: 'ID',
        type: 'string',
      //  width: '20px'
      },

    },

    pager: {
      display: false,
      perPage: 3,
    },
    actions: {
     delete: false,
     add: false,
     edit: false,
     select: false,

   },
   attr: {
    class: 'ttable table-bordered'
  }


  };







public items1: any;
public elevevv: any;
//public eleveabsv: any;
eleveabs = [];
vuevv: String="Vue par tout le monde";
public users: any;
public classevv: any;
idclassevv: String="";
  constructor(private http: HttpClient,private url:UrlsService,private router: Router) { 

  }

  ngOnInit(): void {
    
    /*if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur" ){

      }else{
        this.router.navigate(['accueil']);
      }
    }else{
      this.router.navigate(['']);
    }*/

      this.recuptype_user().subscribe(
        data => {
          this.users = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );

  }

  recuptype_user() {
    return this.http
    .get<User[]>(this.url.users)
  }




}
