import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ServiceOneComponent } from './components/pages/service-one/service-one.component';
import { ServiceTwoComponent } from './components/pages/service-two/service-two.component';
import { ServiceDetailComponent } from './components/pages/service-detail/service-detail.component';
import { PortfolioOneComponent } from './components/pages/portfolio-one/portfolio-one.component';
import { PortfolioTwoComponent } from './components/pages/portfolio-two/portfolio-two.component';
import { ProjectDetailComponent } from './components/pages/project-detail/project-detail.component';
import { ShopComponent } from './components/pages/shop/shop.component';
import { ShopDetailComponent } from './components/pages/shop-detail/shop-detail.component';
import { NewsGridComponent } from './components/pages/news-grid/news-grid.component';
import { NewsStandardComponent } from './components/pages/news-standard/news-standard.component';
import { NewsDetailComponent } from './components/pages/news-detail/news-detail.component';
import { TeamComponent } from './components/pages/team/team.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareersComponent } from './components/pages/careers/careers.component';


import { EnregistrernotesComponent } from './components/pages/notes/enregistrernotes/enregistrernotes.component';
import { EnregistrernotespropreComponent } from './components/pages/notes/enregistrernotespropre/enregistrernotespropre.component';
import { ConsulternotesComponent } from './components/pages/notes/consulternotes/consulternotes.component';
import { ConsulternotespropreComponent } from './components/pages/notes/consulternotespropre/consulternotespropre.component';
import { ExportPvComponent } from './components/pages/notes/export-pv/export-pv.component';
import { ExporternotesComponent } from './components/pages/notes/exporternotes/exporternotes.component';
import { ModifiernotesComponent } from './components/pages/notes/modifiernotes/modifiernotes.component'
import { PublierMessageComponent } from './components/pages/messages/publier-message/publier-message.component';
import { ListemessageComponent } from './components/pages/messages/listemessage/listemessage.component';
import { DetailmessageComponent } from './components/pages/messages/detailmessage/detailmessage.component';
import { ImporterdonneeComponent } from './components/pages/parametre/importerdonnee/importerdonnee.component';


import { EnregistrerUserComponent } from './components/pages/user/enregistrer-user/enregistrer-user.component';
import { ModifUserComponent } from './components/pages/user/modif-user/modif-user.component';
import { ConsultUserComponent } from './components/pages/user/consult-user/consult-user.component';
import { ModifUserPropreComponent } from './components/pages/user/modif-user-propre/modif-user-propre.component';
import { ImprimBordereauNoteComponent } from './components/pages/notes/imprim-bordereau-note/imprim-bordereau-note.component';
import { EnseignenttouserComponent } from './components/pages/user/enseignenttouser/enseignenttouser.component';
import { EnregistrerAbsenceComponent } from './components/pages/discipline/enregistrer-absence/enregistrer-absence.component';
import { ConsulterAbsenceComponent } from './components/pages/discipline/consulter-absence/consulter-absence.component';
import { DeletedataComponent } from './components/pages/parametre/deletedata/deletedata.component';
import { ClassesanseleveComponent } from './components/pages/parametre/classesanseleve/classesanseleve.component';
import { ModifAbsenceComponent } from './components/pages/discipline/modif-absence/modif-absence.component';

import { EnregistrerCDComponent } from './components/pages/discipline/enregistrer-cd/enregistrer-cd.component';
import { ConsulterCDComponent } from './components/pages/discipline/consulter-cd/consulter-cd.component';
import { ModifierCDComponent } from './components/pages/discipline/modifier-cd/modifier-cd.component';
import { ExpoerterabsComponent } from './components/pages/discipline/expoerterabs/expoerterabs.component';
import { ExpoertercdComponent } from './components/pages/discipline/expoertercd/expoertercd.component';
import { InfoEtablissementComponent } from './components/pages/parametre/info-etablissement/info-etablissement.component';
import { BloquertrimestreComponent } from './components/pages/parametre/bloquertrimestre/bloquertrimestre.component';
import { DashboardvvComponent } from './components/pages/dashboard/dashboardvv/dashboardvv.component';
import { UsersgcenseurComponent } from './components/pages/user/usersgcenseur/usersgcenseur.component';
import { ModiftypeuserComponent } from './components/pages/user/modiftypeuser/modiftypeuser.component';
import { SupimernoteComponent } from './components/pages/notes/supimernote/supimernote.component';
import { RemplirtableetatnoteComponent } from './components/pages/parametre/remplirtableetatnote/remplirtableetatnote.component';
const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'home-two',component:HomeTwoComponent},
  {path:'home-three',component:HomeThreeComponent},
  {path:'about',component:AboutComponent},
  {path:'service-1',component:ServiceOneComponent},
  {path:'service-2',component:ServiceTwoComponent},
  {path:'service-detail',component:ServiceDetailComponent},
  {path:'portfolio-1',component:PortfolioOneComponent},
  {path:'portfolio-2',component:PortfolioTwoComponent},
  {path:'project-detail',component:ProjectDetailComponent},
  {path:'shop',component:ShopComponent},
  {path:'shop-detail',component:ShopDetailComponent},
  {path:'news-grid',component:NewsGridComponent},
  {path:'news-standard',component:NewsStandardComponent},
  {path:'news-detail',component:NewsDetailComponent},
  {path:'team',component:TeamComponent},
  {path:'careers',component:CareersComponent},
  {path:'contact',component:ContactComponent},

  {path: 'Enregistrernotes', component: EnregistrernotesComponent},
  {path: 'Enregistrernotespropre/:id', component: EnregistrernotespropreComponent},
  {path: 'Consulternotes', component: ConsulternotesComponent},
  {path: 'Consulternotespropre', component: ConsulternotespropreComponent},
  {path: 'Modifiernotesv', component: ModifiernotesComponent},
  {path: 'Exporternotes', component: ExporternotesComponent},
  {path: 'ExportPV', component: ExportPvComponent},
  {path: 'PublierMessage', component: PublierMessageComponent},
  {path: 'Listemessage', component: ListemessageComponent},
  {path: 'Detailmessage', component: DetailmessageComponent},
  {path: 'Importerdonnee', component: ImporterdonneeComponent},
  

  {path: 'Enseignenttouser', component: EnseignenttouserComponent},
  {path: 'EnregistrerUser', component: EnregistrerUserComponent},
  {path: 'ModifUser', component: ModifUserComponent},
  {path: 'ConsultUser', component: ConsultUserComponent},
  {path: 'ModifUserPropre', component: ModifUserPropreComponent},
  {path: 'ImprimBordereauNote', component: ImprimBordereauNoteComponent},
  {path: 'EnregistrerAbsence', component: EnregistrerAbsenceComponent},
  {path: 'ConsulterAbsence', component: ConsulterAbsenceComponent},
  {path: 'Deletedata', component: DeletedataComponent},
  {path: 'Classesanseleve', component: ClassesanseleveComponent},
  {path: 'ModifAbsence', component: ModifAbsenceComponent},

  {path: 'EnregistrerCD', component: EnregistrerCDComponent},
  {path: 'ConsulterCD', component: ConsulterCDComponent},
  {path: 'ModifierCD', component: ModifierCDComponent},
  {path: 'Expoerterabs', component: ExpoerterabsComponent},
  {path: 'Expoertercd', component: ExpoertercdComponent},
  {path: 'InfoEtablissement', component: InfoEtablissementComponent},
  {path: 'Bloquertrimestre', component: BloquertrimestreComponent},
  {path: 'Dashboardvv', component: DashboardvvComponent},
  {path: 'Usersgcenseur', component: UsersgcenseurComponent},
  {path: 'Modiftypeuser', component: ModiftypeuserComponent},
  {path: 'Supimernote', component: SupimernoteComponent},
  {path: 'Remplirtableetatnote', component: RemplirtableetatnoteComponent},
 // {path: '**', component: HomeComponent} 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
  })],
  //imports: [RouterModule.forRoot(routes)],

  exports: [RouterModule]
})
export class AppRoutingModule { }
