<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">IMPRIMER LE BORDEREAU DES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>IMPRIMER LE BORDEREAU DES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->



<br />    <br />
<div class="row">
<div  class="col-lg-3"></div>
<div  class="col-lg-6 ">
    <div class="contact-info-box mb-md-30">

        <div class="form-group">
            <select selected="" class="form-control"  (change)="choixniveau($event)" >
                <option      >Tous les niveaux</option>
                <option *ngFor="let u of niveauvv" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
              </select> 
        </div>
        <div class="form-group">
            <select selected="" class="form-control"  (change)="choixclasse($event)" >
                <option    >Toutes les classes</option>
                <option *ngFor="let u of classevv" [value]="u.idClasse"     >{{u.idClasse}}</option>
              </select> 
        </div>

        <div class="form-group">
            <select selected="" class="form-control"  (change)="choixeval($event)" >
                <option     >Evaluation</option>
                <option *ngFor="let u of evaltab" [value]="u"     >{{u}}</option>


              </select> 
        </div>



        <div class="form-group">
            <select selected="" class="form-control"  (change)="choixmatiere($event)" >
                <option      [value]="0" >Toutes les Matières</option>

                <option *ngFor="let m of matierclasse " [value]="m.id_matiere"     >{{m.nom_matiere}}</option>


              </select> 
        </div>



        <div class="form-btn">
           
            <button (click)="getValues()" class="theme-btn btn-orange">exporter</button>
            <!--<button type="submit" class="default-btn-two">Get A Full Quote</button>--> 
        </div>


    </div>
   </div>
   <div  class="col-lg-3"></div>
</div>

<br /><br />

<!-- End Skillset -->
<app-footer-one></app-footer-one>