import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-modifiernotes',
  templateUrl: './modifiernotes.component.html',
  styleUrls: ['./modifiernotes.component.css']
})
export class ModifiernotesComponent implements OnInit {
  Notes : any = [];
  competencevv: String ="";
  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notessrecup: any;
  public notess: any;
  typeuser: String="";
  public matierclasseF: any;
  booleval=false;
  //public eleveabsv: any;
  eleveabs = [];
  allbloquer: any = [];
  Notesvv1 = [];
  Notesvv2 = [];

  vuevv: String="Vue par tout le monde";
  evall: String="";
  idclassevv: String="";
  classevvd : any = [];
  classevvdf : any = [];
  nommatierevv: String="";
  public alleleve= [];
  eval1="1";
  eval2="2";
  eval1vv="1";
  eval2vv="2";
  evaltab = ["1","2","3","4","5","6","Trimestre 1","Trimestre 2","Trimestre 3"];
  compet: String="";
  classematierevv: any = [];
    constructor(private http: HttpClient,private url:UrlsService, private router: Router,
      private toastr: ToastrService ) { 
  
    }
    showSuccess() {
      this.toastr.success('Success', 'Note modifiée avec succès!', {timeOut: 3000,});
    }
    showSuccessnote() {
      this.toastr.error('error', "La Notes saisie doit être compris entre 0 et 20 et doit être un nombre !", {timeOut: 5000,});
    }
    showSuccessnotehh() {
      this.toastr.error('Error', "Les notes de cette matière n'existent pas", {timeOut: 5000,});
    }
    showSuccessnotecch() {
      this.toastr.error('Error', "Le trimestre a été clôturé", {timeOut: 5000,});
    }
    ngOnInit(): void {
     if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
        ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
        ||localStorage.getItem('typeuser')=="SG" ){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }


      
    this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  


        this.typeuser=localStorage.getItem('typeuser');


if(this.typeuser==='Enseignant'){


  this.http.post<any>(this.url.getclassebyenseignant,
    JSON.stringify({  
      anseignant:  localStorage.getItem('login'),

     })).subscribe(data => {



      console.log(data);

      this. classevvd = [];
      this. classevvdf = [];
      this. classevv = [];
      this.classematierevv = [];
      this.classevvd = JSON.parse(JSON.stringify(data)); 
      for (let i =0; i <this.classevvd.length; i++){

        this.classematierevv.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
        "id_matiere":this.classevvd[i].id_matiere,
        "matriculE":this.classevvd[i].matriculE,
        "nom_matiere":this.classevvd[i].nom_matiere}));   

        let vv =0;
        for (let ig =0; ig <this.classevvdf.length; ig++){
          if(this.classevvd[i].id_classe==this.classevvdf[ig].idClasse){
            vv =1;
            break;
          }
        }
            if( vv ==0){
        this.classevvdf.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                                        "id_matiere":this.classevvd[i].id_matiere,
                                        "matriculE":this.classevvd[i].matriculE,
                                        "nom_matiere":this.classevvd[i].nom_matiere}));     
                                        
                                      }
      }
      this.classevv = JSON.parse(JSON.stringify( this.classevvdf)); 

/*
      window.location.reload();
     this.router.navigate(['Enregistrernotes']);
*/



      });
}else{
  this.recuptype_all_classe().subscribe(
    data => {
      this.classevv = JSON.parse(JSON.stringify(data)); 
     // console.log(this.elevevv);
    },
      );
        }









    }
    recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }
  
    recuptype_matiere_classe(classs: String) {
      return this.http
      .get<Eleve[]>(this.url.matierebyclasse+classs)
    }



    choixniveau(e: any) {
    
    
      this.recuptype_classe_niveau(e.target.value).subscribe(
        data => {
          this.classevv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );
    }
  
  
    choixclasse(e: any) {
     
   this.idclassevv=e.target.value;

  





          if(this.typeuser==='Enseignant'){
     
                  this. matierclasse = [];
                  this. matierclasseF = [];
                  for (let i =0; i <this.classematierevv.length; i++){
                    if(e.target.value==this.classematierevv[i].idClasse){
            
                    this.matierclasseF.push(Object.assign({"id_matiere":this.classematierevv[i].id_matiere,
                                                    "nom_matiere":this.classematierevv[i].nom_matiere}));     
                                                    
                          }
                  }
                  this.matierclasse = JSON.parse(JSON.stringify( this.matierclasseF)); 
            
            
                }else{
                  this.recuptype_matiere_classe(e.target.value).subscribe(
                    data => {
                      this.matierclasse = JSON.parse(JSON.stringify(data)); 
                    },
                      );
                  }







    }
    recuptype_alltrimestre() {
      return this.http
      .get<Eleve[]>(this.url.allbloquer)
    }
    choixeval(e: any) {
      this.evall=e.target.value;
      this.allbloquer= [];
      this.Notesvv1 = [];
      this.Notesvv2 = [];
      this.notess = [];
      this.Notes = [];
      this.notessrecup = [];
      this.alleleve= [];

      let evalbb=this.evall;
      if(this.evall=="1"||this.evall=="2"){
        evalbb="Trimestre 1";
      }else if(this.evall=="3"||this.evall=="4"){
        evalbb="Trimestre 2";
      }else if(this.evall=="5"||this.evall=="6"){
        evalbb="Trimestre 3";
      }else{
        evalbb=this.evall;
      
      }
    
      this.recuptype_alltrimestre().subscribe(
        data => {
          let iff =0;
          this.allbloquer = JSON.parse(JSON.stringify(data)); 
          for (let i =0; i <this.allbloquer.length; i++){
            if(evalbb==this.allbloquer[i].trimestre){
              iff=1;
              break;
            }
          }
          if(iff==1){

            this.showSuccessnotecch()
          }else{

           
            if(this.evall=="Trimestre 1"){
              this.eval1vv="1";
              this.eval2vv="2";
              this.booleval=true;
            }else if(this.evall=="Trimestre 2"){
              this.eval1vv="3";
              this.eval2vv="4";
              this.booleval=true;
            }else if(this.evall=="Trimestre 3"){
              this.eval1vv="5";
              this.eval2vv="6";
              this.booleval=true;
            }else{
            this.booleval=false;
             }
      
      

             
        // this.nommatierevv=e.target.value;
         
       
         if(this.nommatierevv==""||this.nommatierevv=="Matières"){
       
         }else{
       
       
           if(this.evall=="Trimestre 1"){
       
             this.eval1="1";
             this.eval2="2";
             this.http.post<any>(this.url.getnotetrimestre,
               JSON.stringify({  
                 idclasse:  this.idclassevv,
                 nommatiere:  this.nommatierevv,
                 evaluation:this.eval1,
                 evaluation2:this.eval2,
                })).subscribe(data => {
                
                
                 if(data.length==0){
                   console.log("-----------****-------");
               
                   this.showSuccessnotehh();
                 }else{
                   this.notessrecup = JSON.parse(JSON.stringify(data)); 
                   this.competencevv=this.notessrecup[0]["competence"];
                   this.elevebyclasse(this.idclassevv).subscribe(
                     datae => {
                       this.alleleve= JSON.parse(JSON.stringify(datae)); 
                       for (let i =0; i <this.alleleve.length; i++){
                          
                         let note1="00.00";
                         let note2="00.00";
                         let id1="";
                         let id2="";
                         for (let ij =0; ij <this.notessrecup.length; ij++){
                           if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval1==this.notessrecup[ij]["num_sq"]){
                            if(this.notessrecup[ij]["note"]=="-"){
                              note1="-";
                            }else{
                            note1=this.notessrecup[ij]["note"];
                          }
                             id1=this.notessrecup[ij]["id"];
                           }
                         }
                         for (let ij =0; ij <this.notessrecup.length; ij++){
                           if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval2==this.notessrecup[ij]["num_sq"]){
                            if(this.notessrecup[ij]["note"]==""){
                              note2="-";
                            }else{
                            note2=this.notessrecup[ij]["note"];
                             }
                             id2=this.notessrecup[ij]["id"];
                            
                           }
                         }
                         this.Notesvv1.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note1, id:id1}));
                         this.Notesvv2.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note2, id:id2}));
                         this.notess.push(Object.assign({matricule:this.alleleve[i]["matricule"],
                         nom:this.alleleve[i]["nom"],
                         prenom:this.alleleve[i]["prenom"],
                         note1:note1,
                         note2:note2}));
                         
                       }
                   
                       
                     
                     },
                     );
       
       
       
       
       
       
                 }
               
                 });
         }else if(this.evall=="Trimestre 2"){
           this.eval1="3";
           this.eval2="4";
           this.http.post<any>(this.url.getnotetrimestre,
             JSON.stringify({  
               idclasse:  this.idclassevv,
               nommatiere:  this.nommatierevv,
               evaluation:this.eval1,
               evaluation2:this.eval2,
              })).subscribe(data => {
              
              
               if(data.length==0){
                 console.log("-----------****-------");
             
                 this.showSuccessnotehh();
               }else{
                 this.notessrecup = JSON.parse(JSON.stringify(data)); 
                 this.competencevv=this.notessrecup[0]["competence"];
                 this.elevebyclasse(this.idclassevv).subscribe(
                   datae => {
                     this.alleleve= JSON.parse(JSON.stringify(datae)); 
                     for (let i =0; i <this.alleleve.length; i++){
                        
                       let note1="00.00";
                       let note2="00.00";
                       let id1="";
                       let id2="";
                       for (let ij =0; ij <this.notessrecup.length; ij++){
                         if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval1==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]=="-"){
                            note1="-";
                          }else{
                          note1=this.notessrecup[ij]["note"];
                        }
                           id1=this.notessrecup[ij]["id"];
                         }
                       }
                       for (let ij =0; ij <this.notessrecup.length; ij++){
                         if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval2==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]==""){
                            note2="-";
                          }else{
                          note2=this.notessrecup[ij]["note"];
                        }
                           id2=this.notessrecup[ij]["id"];
                         }
                       }
                       this.Notesvv1.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note1, id:id1}));
                       this.Notesvv2.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note2, id:id2}));
                       this.notess.push(Object.assign({matricule:this.alleleve[i]["matricule"],
                       nom:this.alleleve[i]["nom"],
                       prenom:this.alleleve[i]["prenom"],
                       note1:note1,
                       note2:note2}));
                       
                     }
                 
                     
                   
                   },
                   );
       
       
       
       
       
       
               }
             
               });
         }else if(this.evall=="Trimestre 3"){
           this.eval1="5";
           this.eval2="6";
           this.http.post<any>(this.url.getnotetrimestre,
             JSON.stringify({  
               idclasse:  this.idclassevv,
               nommatiere:  this.nommatierevv,
               evaluation:this.eval1,
               evaluation2:this.eval2,
              })).subscribe(data => {
              
              
               if(data.length==0){
                 console.log("-----------****-------");
             
                 this.showSuccessnotehh();
               }else{
                 this.notessrecup = JSON.parse(JSON.stringify(data)); 
                 this.competencevv=this.notessrecup[0]["competence"];
                 this.elevebyclasse(this.idclassevv).subscribe(
                   datae => {
                     this.alleleve= JSON.parse(JSON.stringify(datae)); 
                     for (let i =0; i <this.alleleve.length; i++){
                        
                       let note1="00.00";
                       let note2="00.00";
                       let id1="";
                       let id2="";
                       for (let ij =0; ij <this.notessrecup.length; ij++){
                         if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval1==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]=="-"){
                            note1="-";
                          }else{
                          note1=this.notessrecup[ij]["note"];
                        }
                           id1=this.notessrecup[ij]["id"];
                         }
                       }
                       for (let ij =0; ij <this.notessrecup.length; ij++){
                         if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval2==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]==""){
                            note2="-";
                          }else{
                          note2=this.notessrecup[ij]["note"];
                        }
                           id2=this.notessrecup[ij]["id"];
                         }
                       }
                       this.Notesvv1.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note1, id:id1}));
                       this.Notesvv2.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note2, id:id2}));
                       this.notess.push(Object.assign({matricule:this.alleleve[i]["matricule"],
                       nom:this.alleleve[i]["nom"],
                       prenom:this.alleleve[i]["prenom"],
                       note1:note1,
                       note2:note2}));
                       
                     }
                 
                     
                   
                   },
                   );
       
       
       
       
       
       
               }
             
               });
         }else if(this.evall=="1"||this.evall=="2"||this.evall=="3"||this.evall=="4"||this.evall=="5"||this.evall=="6"){
           this.http.post<any>(this.url.getnote,
             JSON.stringify({  
               idclasse:  this.idclassevv,
               nommatiere:  this.nommatierevv,
               evaluation:this.evall,
              })).subscribe(data => {
              
              
               if(data.length==0){
                 console.log("-----------****-------");
             
                 this.showSuccessnotehh();
               }else{
                 console.log("------------------");
                 this.notess = JSON.parse(JSON.stringify(data)); 
                 this.competencevv=this.notess[0]["competence"];
                 for (let i =0; i <this.notess.length; i++){
                  if(this.notess[i]["note"]=="-"){
                    this.Notes.push(Object.assign({mat:this.notess[i]["matricule"], note:"-", id:this.notess[i]["id"]}));
                  }else{
                   this.Notes.push(Object.assign({mat:this.notess[i]["matricule"], note:this.notess[i]["note"], id:this.notess[i]["id"]}));
                  }
                  }
               
               }
               //Swal.fire("Cancelled', 'Enregistrement effectué avec succèe.', 'ERREUR NOTES");
               });
         }
       
       
       
        
         }


          }
        },
          );







    }
  
    elevebyclasse(idclasse: String) {
      return this.http
      .get<Eleve[]>(this.url.elevebyclasse+idclasse)
    }
  
    choixmatire(e: any) {
      this.allbloquer= [];
      this.Notesvv1 = [];
      this.Notesvv2 = [];
      this.notess = [];
      this.Notes = [];
      this.notessrecup = [];
      this.alleleve= [];

      let evalbb=this.evall;
      if(this.evall=="1"||this.evall=="2"){
        evalbb="Trimestre 1";
      }else if(this.evall=="3"||this.evall=="4"){
        evalbb="Trimestre 2";
      }else if(this.evall=="5"||this.evall=="6"){
        evalbb="Trimestre 3";
      }else{
        evalbb=this.evall;
      
      }
    
      this.recuptype_alltrimestre().subscribe(
        data => {
          let iff =0;
          this.allbloquer = JSON.parse(JSON.stringify(data)); 
          for (let i =0; i <this.allbloquer.length; i++){
            if(evalbb==this.allbloquer[i].trimestre){
              iff=1;
              break;
            }
          }
          if(iff==1){

            this.showSuccessnotecch()
          }else{



            
        this.nommatierevv=e.target.value;
        
      
        if(this.nommatierevv==""||this.nommatierevv=="Matières"){
      
        }else{
      
      
          if(this.evall=="Trimestre 1"){
      
            this.eval1="1";
            this.eval2="2";
            this.http.post<any>(this.url.getnotetrimestre,
              JSON.stringify({  
                idclasse:  this.idclassevv,
                nommatiere:  this.nommatierevv,
                evaluation:this.eval1,
                evaluation2:this.eval2,
               })).subscribe(data => {
               
               
                if(data.length==0){
                  console.log("-----------****-------");
              
                  this.showSuccessnotehh();
                }else{
                  this.notessrecup = JSON.parse(JSON.stringify(data)); 
                  this.competencevv=this.notessrecup[0]["competence"];
                  this.elevebyclasse(this.idclassevv).subscribe(
                    datae => {
                      this.alleleve= JSON.parse(JSON.stringify(datae)); 
                      for (let i =0; i <this.alleleve.length; i++){
                         
                        let note1="00.00";
                        let note2="00.00";
                        let id1="";
                        let id2="";
                        for (let ij =0; ij <this.notessrecup.length; ij++){
                          if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval1==this.notessrecup[ij]["num_sq"]){
                            if(this.notessrecup[ij]["note"]==""){
                              note1="-";
                            }else{
                            note1=this.notessrecup[ij]["note"];
                          }
                            id1=this.notessrecup[ij]["id"];
                          }
                        }
                        for (let ij =0; ij <this.notessrecup.length; ij++){
                          if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval2==this.notessrecup[ij]["num_sq"]){
                            if(this.notessrecup[ij]["note"]=="-"){
                              note2="-";
                            }else{
                            note2=this.notessrecup[ij]["note"];
                          }
                            id2=this.notessrecup[ij]["id"];
                          }
                        }
                        this.Notesvv1.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note1, id:id1}));
                        this.Notesvv2.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note2, id:id2}));
                        this.notess.push(Object.assign({matricule:this.alleleve[i]["matricule"],
                        nom:this.alleleve[i]["nom"],
                        prenom:this.alleleve[i]["prenom"],
                        note1:note1,
                        note2:note2}));
                        
                      }
                  
                      
                    
                    },
                    );
      
      
      
      
      
      
                }
              
                });
        }else if(this.evall=="Trimestre 2"){
          this.eval1="3";
          this.eval2="4";
          this.http.post<any>(this.url.getnotetrimestre,
            JSON.stringify({  
              idclasse:  this.idclassevv,
              nommatiere:  this.nommatierevv,
              evaluation:this.eval1,
              evaluation2:this.eval2,
             })).subscribe(data => {
             
             
              if(data.length==0){
                console.log("-----------****-------");
            
                this.showSuccessnotehh();
              }else{
                this.notessrecup = JSON.parse(JSON.stringify(data)); 
                this.competencevv=this.notessrecup[0]["competence"];
                this.elevebyclasse(this.idclassevv).subscribe(
                  datae => {
                    this.alleleve= JSON.parse(JSON.stringify(datae)); 
                    for (let i =0; i <this.alleleve.length; i++){
                       
                      let note1="00.00";
                      let note2="00.00";
                      let id1="";
                      let id2="";
                      for (let ij =0; ij <this.notessrecup.length; ij++){
                        if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval1==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]=="-"){
                            note1="-";
                          }else{
                          note1=this.notessrecup[ij]["note"];
                        }
                          id1=this.notessrecup[ij]["id"];
                        }
                      }
                      for (let ij =0; ij <this.notessrecup.length; ij++){
                        if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval2==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]==""){
                            note2="-";
                          }else{
                          note2=this.notessrecup[ij]["note"];
                        }
                          id2=this.notessrecup[ij]["id"];
                        }
                      }
                      this.Notesvv1.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note1, id:id1}));
                      this.Notesvv2.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note2, id:id2}));
                      this.notess.push(Object.assign({matricule:this.alleleve[i]["matricule"],
                      nom:this.alleleve[i]["nom"],
                      prenom:this.alleleve[i]["prenom"],
                      note1:note1,
                      note2:note2}));
                      
                    }
                
                    
                  
                  },
                  );
      
      
      
      
      
      
              }
            
              });
        }else if(this.evall=="Trimestre 3"){
          this.eval1="5";
          this.eval2="6";
          this.http.post<any>(this.url.getnotetrimestre,
            JSON.stringify({  
              idclasse:  this.idclassevv,
              nommatiere:  this.nommatierevv,
              evaluation:this.eval1,
              evaluation2:this.eval2,
             })).subscribe(data => {
             
             
              if(data.length==0){
                console.log("-----------****-------");
            
                this.showSuccessnotehh();
              }else{
                this.notessrecup = JSON.parse(JSON.stringify(data)); 
                this.competencevv=this.notessrecup[0]["competence"];
                this.elevebyclasse(this.idclassevv).subscribe(
                  datae => {
                    this.alleleve= JSON.parse(JSON.stringify(datae)); 
                    for (let i =0; i <this.alleleve.length; i++){
                       
                      let note1="00.00";
                      let note2="00.00";
                      let id1="";
                      let id2="";
                      for (let ij =0; ij <this.notessrecup.length; ij++){
                        if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval1==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]=="-"){
                            note1="-";
                          }else{
                          note1=this.notessrecup[ij]["note"];
                        }
                          id1=this.notessrecup[ij]["id"];
                        }
                      }
                      for (let ij =0; ij <this.notessrecup.length; ij++){
                        if(this.alleleve[i]["matricule"]==this.notessrecup[ij]["matricule"] && this.eval2==this.notessrecup[ij]["num_sq"]){
                          if(this.notessrecup[ij]["note"]==""){
                            note2="-";
                          }else{
                          note2=this.notessrecup[ij]["note"];
                        }
                          id2=this.notessrecup[ij]["id"];
                        }
                      }
                      this.Notesvv1.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note1, id:id1}));
                      this.Notesvv2.push(Object.assign({mat:this.alleleve[i]["matricule"], note:note2, id:id2}));
                      this.notess.push(Object.assign({matricule:this.alleleve[i]["matricule"],
                      nom:this.alleleve[i]["nom"],
                      prenom:this.alleleve[i]["prenom"],
                      note1:note1,
                      note2:note2}));
                      
                    }
                
                    
                  
                  },
                  );
      
      
      
      
      
      
              }
            
              });
        }else if(this.evall=="1"||this.evall=="2"||this.evall=="3"||this.evall=="4"||this.evall=="5"||this.evall=="6"){
          this.http.post<any>(this.url.getnote,
            JSON.stringify({  
              idclasse:  this.idclassevv,
              nommatiere:  this.nommatierevv,
              evaluation:this.evall,
             })).subscribe(data => {
             
             
              if(data.length==0){
                console.log("-----------****-------");
            
                this.showSuccessnotehh();
              }else{
                console.log("------------------");
                this.notess = JSON.parse(JSON.stringify(data)); 
                this.competencevv=this.notess[0]["competence"];
                for (let i =0; i <this.notess.length; i++){

                  if(this.notess[i]["note"]=="-"){
                    this.Notes.push(Object.assign({mat:this.notess[i]["matricule"], note:"-", id:this.notess[i]["id"]}));
                  }else{
                  this.Notes.push(Object.assign({mat:this.notess[i]["matricule"], note:this.notess[i]["note"], id:this.notess[i]["id"]}));
                }
                }
              
              }
              //Swal.fire("Cancelled', 'Enregistrement effectué avec succèe.', 'ERREUR NOTES");
              });
        }
      
      
      
       
        }
          

          }
        },
          );







    }
  
  
    valChangecompe(e:Event):void{
      this.competencevv = (e.target as HTMLInputElement).value

    }
    getclosev():void{

    }




    Enregistrer():void{
      
      if(this.evall=="Trimestre 1"||this.evall=="Trimestre 2"||this.evall=="Trimestre 3"){
        let chaineNotes1="";
        let chaineNotes2="";
        for (let i =0; i <this.Notesvv1.length; i++){
          let bonnenoteinit=this.Notesvv1[i]["note"];
          let bonnenote="";
          let rr=0;
          let pos=0;
          for (let iff = 0; iff <bonnenoteinit.toString().length; iff++)
      {
            
            if(bonnenoteinit.toString().charAt(iff)=='.'){
               rr=1;
               pos=iff;
               break;
            }
      }
          if(rr==1){
            //il y a le point
            
            if(bonnenoteinit.toString().substring(0, pos).length==2){
    
              
              if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==2){
                bonnenote=bonnenoteinit;
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==1){
                bonnenote=bonnenoteinit+"0";
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==0){
                bonnenote=bonnenoteinit+"00";
              }else {
                bonnenote=bonnenoteinit.toString().substring(0, 5);
              }
              
              
              
              
              
            }else {
              if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==2){
                bonnenote="0"+bonnenoteinit;
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==1){
                bonnenote="0"+bonnenoteinit+"0";
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==0){
                bonnenote="0"+bonnenoteinit+"00";
              }else {
                bonnenote="0"+bonnenoteinit.toString().substring(0, 5);
              }
              
            }
            
            
            
            
          }else{
            //il n'y a pas le point
            if(Number(bonnenoteinit)<10){
              if(bonnenoteinit.toString().charAt(0)=='0'){
                bonnenote=bonnenoteinit+".00";
              }else{
                bonnenote="0"+bonnenoteinit+".00";
              }
                
              }else{
                if(bonnenoteinit=="-"){
                  bonnenote="-";
                }else{
                bonnenote=bonnenoteinit+".00";
                    }
              }
          }
          chaineNotes1=chaineNotes1+this.Notesvv1[i]["mat"]+":"+bonnenote+":"+this.Notesvv1[i]["id"]+";";
   
     }
     for (let i =0; i <this.Notesvv2.length; i++){
      let bonnenoteinit=this.Notesvv2[i]["note"];
      let bonnenote="";
      let rr=0;
      let pos=0;
      for (let iff = 0; iff <bonnenoteinit.toString().length; iff++)
  {
        
        if(bonnenoteinit.toString().charAt(iff)=='.'){
           rr=1;
           pos=iff;
           break;
        }
  }
      if(rr==1){
        //il y a le point
        
        if(bonnenoteinit.toString().substring(0, pos).length==2){

          
          if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==2){
            bonnenote=bonnenoteinit;
          }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==1){
            bonnenote=bonnenoteinit+"0";
          }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==0){
            bonnenote=bonnenoteinit+"00";
          }else {
            bonnenote=bonnenoteinit.toString().substring(0, 5);
          }
          
          
          
          
          
        }else {
          if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==2){
            bonnenote="0"+bonnenoteinit;
          }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==1){
            bonnenote="0"+bonnenoteinit+"0";
          }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==0){
            bonnenote="0"+bonnenoteinit+"00";
          }else {
            bonnenote="0"+bonnenoteinit.toString().substring(0, 5);
          }
          
        }
        
        
        
        
      }else{
        //il n'y a pas le point
        if(Number(bonnenoteinit)<10){
          if(bonnenoteinit.toString().charAt(0)=='0'){
            bonnenote=bonnenoteinit+".00";
          }else{
            bonnenote="0"+bonnenoteinit+".00";
          }
            
          }else{
            if(bonnenoteinit=="-"){
              bonnenote="-";
            }else{
            bonnenote=bonnenoteinit+".00";
               }
          }
      }
      chaineNotes2=chaineNotes2+this.Notesvv2[i]["mat"]+":"+bonnenote+":"+this.Notesvv2[i]["id"]+";";

 }





     let ch1=chaineNotes1.substring(0,chaineNotes1.length-1);
     let ch2=chaineNotes2.substring(0,chaineNotes2.length-1);
     console.log(ch1);
     console.log(ch2);
     this.http.post<any>(this.url.updatenotetri,
      JSON.stringify({  
        note1: ch1,
        note2: ch2,
        compet:this.competencevv,
       })).subscribe(data => {
        
        if(data){ 
          this.showSuccess();
        }

        });
  






















      }else{
        let chaineNotes="";
        for (let i =0; i <this.Notes.length; i++){
          let bonnenoteinit=this.Notes[i]["note"];
          let bonnenote="";
          let rr=0;
          let pos=0;
          for (let iff = 0; iff <bonnenoteinit.toString().length; iff++)
      {
            
            if(bonnenoteinit.toString().charAt(iff)=='.'){
               rr=1;
               pos=iff;
               break;
            }
      }
          if(rr==1){
            //il y a le point
            
            if(bonnenoteinit.toString().substring(0, pos).length==2){
    
              
              if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==2){
                bonnenote=bonnenoteinit;
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==1){
                bonnenote=bonnenoteinit+"0";
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==0){
                bonnenote=bonnenoteinit+"00";
              }else {
                bonnenote=bonnenoteinit.toString().substring(0, 5);
              }
              
              
              
              
              
            }else {
              if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==2){
                bonnenote="0"+bonnenoteinit;
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==1){
                bonnenote="0"+bonnenoteinit+"0";
              }else if(bonnenoteinit.toString().substring(pos+1, bonnenoteinit.toString().length ).length==0){
                bonnenote="0"+bonnenoteinit+"00";
              }else {
                bonnenote="0"+bonnenoteinit.toString().substring(0, 5);
              }
              
            }
            
            
            
            
          }else{
            //il n'y a pas le point
            if(Number(bonnenoteinit)<10){
              if(bonnenoteinit.toString().charAt(0)=='0'){
                bonnenote=bonnenoteinit+".00";
              }else{
                bonnenote="0"+bonnenoteinit+".00";
              }
                
              }else{
                if(bonnenoteinit=="-"){
                  bonnenote="-";
                }else{
                bonnenote=bonnenoteinit+".00";
              }
              }
          }
          chaineNotes=chaineNotes+this.Notes[i]["mat"]+":"+bonnenote+":"+this.Notes[i]["id"]+";";
   
     }
     
     let ch=chaineNotes.substring(0,chaineNotes.length-1);
     console.log(ch);
  
  
     this.http.post<any>(this.url.updatenote,
      JSON.stringify({  
        note: ch,
        compet:this.competencevv,
       })).subscribe(data => {
        
        if(data){ 
          this.showSuccess();
        }

        });
      }


   

  
  

     }










    valChange(e:Event, index:string):void{
      let v = (e.target as HTMLInputElement).value
      if(this.Notes.length==0){
       
  
     if(this.verif_note_isnumber(v)){
        this.Notes.push(Object.assign({mat:index, note:v}));
      }else{

        this.showSuccessnote() ;
      }
  
  
  
        }else{
          let indexxx =0;
          let vv =0;
      for (let i =0; i <this.Notes.length; i++){
       // console.log(this.Notes[i].mat);
  
      if(this.Notes[i].mat==index){
       
        indexxx =i;
        vv =1;
        break;
      }
      }
      
      if(vv ==1){
        if(this.verif_note_isnumber(v)){
        this.Notes[indexxx].note=v;
      }else{

        this.showSuccessnote() ;
      }
       }else{
        if(this.verif_note_isnumber(v)){
        this.Notes.push(Object.assign({mat:index, note:v}));
      }else{

        this.showSuccessnote() ;
      }
      }
  
  
    }
    }





    eval1recup(e:Event, index:string):void{
      let v = (e.target as HTMLInputElement).value
      if(  this.Notesvv1.length==0){
       
  
     if(this.verif_note_isnumber(v)){
        this.Notesvv1.push(Object.assign({mat:index, note:v}));
      }else{

        this.showSuccessnote() ;
      }
  
  
  
        }else{
          let indexxx =0;
          let vv =0;
      for (let i =0; i <this.Notesvv1.length; i++){
       // console.log(this.Notes[i].mat);
  
      if(this.Notesvv1[i].mat==index){
       
        indexxx =i;
        vv =1;
        break;
      }
      }
      
      if(vv ==1){
        if(this.verif_note_isnumber(v)){
        this.Notesvv1[indexxx].note=v;
      }else{

        this.showSuccessnote() ;
      }
       }else{
        if(this.verif_note_isnumber(v)){
        this.Notesvv1.push(Object.assign({mat:index, note:v}));
      }else{

        this.showSuccessnote() ;
      }
      }
  
  
    }
    }

    eval2recup(e:Event, index:string):void{
      let v = (e.target as HTMLInputElement).value
      if(this.Notesvv2 .length==0){
       
  
     if(this.verif_note_isnumber(v)){
        this.Notesvv2 .push(Object.assign({mat:index, note:v}));
      }else{

        this.showSuccessnote() ;
      }
  
  
  
        }else{
          let indexxx =0;
          let vv =0;
      for (let i =0; i <this.Notesvv2 .length; i++){
       // console.log(this.Notes[i].mat);
  
      if(this.Notesvv2 [i].mat==index){
       
        indexxx =i;
        vv =1;
        break;
      }
      }
      
      if(vv ==1){
        if(this.verif_note_isnumber(v)){
        this.Notesvv2 [indexxx].note=v;
      }else{

        this.showSuccessnote() ;
      }
       }else{
        if(this.verif_note_isnumber(v)){
        this.Notesvv2 .push(Object.assign({mat:index, note:v}));
      }else{

        this.showSuccessnote() ;
      }
      }
  
  
    }
    }














    verif_note_isnumber(note:String) :boolean{

      let tt:boolean;
     
     
      if( note.length==0){
       tt=false;
  
     //  Swal.fire('La note ne doit pas être vide.');
     }else{
     
     
     
           if(!isNaN(Number(note))){
           
             if(Number(note)>=0 && Number(note)<=20){
               tt=true;
             }else{
               tt=false;
              // Swal.fire("La note doit être comprise entre 0 et 20.");
                }
     
             }else{
             if(note!="-"){
               tt=false;
             //  Swal.fire("La note saisie n'est pas valide.");
            
           }else{
             tt=true;
           }
             }
         
           }
     
         return tt;
       }

  }
  