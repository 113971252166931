import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import { VERSION } from '@angular/core';
import * as XLSX from 'xlsx';
import { Infoe } from '../../../service/infoe';

import 'jspdf-autotable';
//import { jsPDF } from "jspdf";

import * as jsPDF from 'jspdf'
import 'jspdf-autotable';

import 'jspdf';
import { decimalDigest } from '@angular/compiler/src/i18n/digest';


@Component({
  selector: 'app-imprim-bordereau-note',
  templateUrl: './imprim-bordereau-note.component.html',
  styleUrls: ['./imprim-bordereau-note.component.css']
})
export class ImprimBordereauNoteComponent implements OnInit {
  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notess: any;

  public noteselevepasnom: any;
  //public eleveabsv: any;
  evall: String="Evaluation";
  matierevv: String="0";
  eleveabs = [];
  vuevv: String="Vue par tout le monde";
  vague: String="0";
  nommatirevvv: String="";
  typefichier: String="300";

  idclassevv: String="Toutes les classes";
  idniveau: String="Tous les niveaux";
  notevv = [];
  public alleleve: any;
  public nommatirecc: any;
  name = 'ExcelSheet.xlsx';
  evaltab = ["1","2","3","4","5","6","TRIMESTRE 1","TRIMESTRE 2","TRIMESTRE 3"];
  dataRecup=[];

  infoe : any = [];

  NomFvv: String="";
NomEvv: String="";
Telvv: String="";
Villevv: String="";
Regionvv: String="";
Departementvv: String="";
devisevv: String="";
Nomresponsablevv: String="";
    constructor(private http: HttpClient,private url:UrlsService, private router: Router ) { 
  
    }
    /*showSuccess() {
      this.toastr.success('Success', 'Note modifiée avec succès!', {timeOut: 3000,});
    }
    showError() {
      this.toastr.success("Erreur", "Choisir la classe et l'évaluation!", {timeOut: 3000,});
    }*/
    ngOnInit(): void {
     if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
        ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
        ||localStorage.getItem('typeuser')=="SG" ){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }

      
   this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  
        this.recuptype_all_classe().subscribe(
          data => {
            this.classevv = JSON.parse(JSON.stringify(data)); 
           // console.log(this.elevevv);
          },
            );
    }
   recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }
  
    recuptype_matiere_classe(classs: String) {
      return this.http
      .get<Eleve[]>(this.url.matierebyclasse+classs)
    }


  

    choixniveau(e: any) {

      if(e.target.value=="Tous les niveaux"){

      }else{
        this.idniveau=e.target.value;
      
        this.recuptype_classe_niveau(e.target.value).subscribe(
          data => {
            this.classevv = JSON.parse(JSON.stringify(data)); 
           // console.log(this.elevevv);
          },
            );
      }


    }
  
  
    choixclasse(e: any) {
     
    this.recuptype_matiere_classe(e.target.value).subscribe(
      data => {
        this.matierclasse = JSON.parse(JSON.stringify(data)); 
       
      },
        );
        this.idclassevv=e.target.value;
    }
    

  

    choixeval(e: any) {
    this.evall=e.target.value;
   /* if(  this.idclassevv=="Toutes les classes" ||this.evall=="Evaluation"){

      this.showError();

    }else{

      this.http.post<any>(this.url.getnoteclassev,
        JSON.stringify({  
          idclasse:this.idclassevv,
          evaluation:e.target.value,
         })).subscribe(data => {
          this.notessmatiere = JSON.parse(JSON.stringify(data)); 
  
  
             console.log(this.notessmatiere);
          });

    }*/



  }
  choixmatiere(e: any) {
     

    this.matierevv=e.target.value;


  }
    choixtypefichier(e: any) {
     

      this.typefichier=e.target.value;

    }

  


    exportexcel(tab: any,titreg:any): void {
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tab);
     
      /* generate workbook and add the worksheet */

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, titreg);
  }















  recuptype_infoe() {
    return this.http
    .get<Infoe[]>(this.url.allinfoe)
  }




  
  export(){

    this.recuptype_infoe().subscribe(
      data => {
        this.infoe = JSON.parse(JSON.stringify(data)); 
        if(this.infoe.length>0){
          this.NomFvv=this.infoe[0]["nomF"];
          this.NomEvv=this.infoe[0]["nomE"];
          this.Telvv=this.infoe[0]["tel"];
          this.Villevv=this.infoe[0]["ville"];
          this.Regionvv=this.infoe[0]["region"];
          this.Departementvv=this.infoe[0]["departement"];
          this.devisevv=this.infoe[0]["devise"];
          this.Nomresponsablevv=this.infoe[0]["nomresponsable"];

        }




    // Col spans and row spans
    var doc= new jsPDF('p', 'pt') ;

    doc.setFontSize(8);
    doc.setTextColor(0);
    doc.setFontStyle('bold');
    doc.text('REPUBLIQUE DU CAMEROUN', 50, 50);
    doc.text('REPUBLIC OF CAMEROON', 370, 50);
    doc.text('PAIX-TRAVAIL-PATRIE', 50, 70);
    doc.text('PEACE-WORK-FATHERLAND', 370, 70);
    doc.text('-----------------', 50, 85);
    doc.text('-----------------', 370, 85);
    doc.text('MINISTERE DES ENSEIGNEMENTS SECONDAIRES', 50, 105);
    doc.text('MINISTRY OF SECONDARY EDUCATION', 370, 105);
    doc.text(this.NomFvv, 50, 125);
    doc.text(this.NomEvv, 370, 125);
    
    doc.setFontSize(14);
    doc.text('BORDEREAU DES NOTES', 240, 160);
    doc.setFontSize(13);
    doc.setFontStyle('italic');




    doc.text('Classe: '+this.idclassevv, 50, 190);
    doc.text('Evaluation: '+ this.evall, 190, 190);
    doc.text('Matière: '+ this.matierevv, 370, 190);

    doc.text(' ', 600, 190);


      var img = new Image();
      img.src = 'assets/etablissement/logo lgl n.png';
      doc.addImage(img, 'png', 250, 30, 80, 80);


    for (var i = 0; i < this.dataRecup.length; i++) {
        var row = this.dataRecup[i];
        if (i % 5 === 0) {
        row['id'] = {rowSpan: 14, content: i / 5 + 1, styles: {valign: 'middle', halign: 'center'}};
        }
    }





    let head1 = [];

    head1.push({content: 'N°', colSpan: 1, styles: {halign: 'center'}}) ;
    head1.push({content: 'Matricule', colSpan: 1, styles: {halign: 'center'}}) ;
    head1.push( {content: 'Nom et Prénom', colSpan: 1, styles: {halign: 'center'}}) ;
 
    if(this.evall=="TRIMESTRE 1"||this.evall=="TRIMESTRE 2"||this.evall=="TRIMESTRE 3"){
     

      if(this.evall=="TRIMESTRE 1"){
        head1.push( {content: "Eval 1", colSpan: 1, styles: {halign: 'center'}}) ;
        head1.push( {content: "Eval 2", colSpan: 1, styles: {halign: 'center'}}) ;
      }else if(this.evall=="TRIMESTRE 2"){
        head1.push( {content: "Eval 3", colSpan: 1, styles: {halign: 'center'}}) ;
        head1.push( {content: "Eval 4", colSpan: 1, styles: {halign: 'center'}}) ;
      }else if(this.evall=="TRIMESTRE 3"){
        head1.push( {content: "Eval 5", colSpan: 1, styles: {halign: 'center'}}) ;
        head1.push( {content: "Eval 6", colSpan: 1, styles: {halign: 'center'}}) ;
      }

    }else{
      head1.push( {content: "Notes", colSpan: 1, styles: {halign: 'center'}}) ;
    }

    let headj = [];
    headj.push(head1) ;
   // console.log(headj);

    doc.autoTable({
      headerStyles: {
        lineWidth: 1,
        lineColor: [110,110,110 ],
        fillColor: [183,183,183 ],
        textColor: "#000000"
    },
    startY: 220,
    head: headj,
    body: this.dataRecup,
    theme: 'grid'
    });




    doc.save('BORDEREAU_DES_NOTES.pdf');








      },
        );














    }







    bodyRowsPRODUI_DETAIL() {

     }

     recuptype_matiere_nom(id: String) {
      return this.http
      .get<Eleve[]>(this.url.matirebyidmatirer+id)
    }
    elevebyclasse(idclasse: String) {
      return this.http
      .get<Eleve[]>(this.url.elevebyclasse+idclasse)
    }
    getValues() {



      this.dataRecup=[];
      let body = [];

      this.elevebyclasse(this.idclassevv).subscribe(
        data => {
          this.alleleve = JSON.parse(JSON.stringify(data)); 
    

   
          for (let i =0; i <this.alleleve.length; i++){
            var gett = []
         //  if(this.alleleve[i]["matricule"]==this.Notes[j].mat){
          gett.push((i+1));
          gett.push(this.alleleve[i].matricule);
          gett.push(this.alleleve[i].nom+" "+this.alleleve[i].prenom);
          gett.push(" ");
          if(this.evall=="TRIMESTRE 1"||this.evall=="TRIMESTRE 2"||this.evall=="TRIMESTRE 3"){
            gett.push(" ");
          }
          body.push(gett);
           }
           this.dataRecup=body;
           this.export(); 



        },
          );








/*
    this.dataRecup=[];
    let body = [];
    var itemNew = []


    this.recuptype_matiere_nom(this.matierevv).subscribe(
      data => {
        this.nommatirecc = JSON.parse(JSON.stringify(data)); 
        localStorage.setItem('nomMatierefvvv', this.nommatirecc[0]["nomMatiere"]);
        this.http.post<any>(this.url.getnoteelevepasliste,
          JSON.stringify({  
            idclasse:this.idclassevv,
            evaluation:  this.evall,
            matiere:  this.nommatirecc[0]["nomMatiere"],
           })).subscribe(data => {
            this.noteselevepasnom = JSON.parse(JSON.stringify(data)); 
            this.http.post<any>(this.url.getnoteclassev,
              JSON.stringify({  
                idclasse:this.idclassevv,
                evaluation:  this.evall,
               })).subscribe(data => {
                this.notessmatiere = JSON.parse(JSON.stringify(data)); 
            console.log(this.notessmatiere);
            for (var i=0;i< this.notessmatiere.length;i++){
              var gett = []
              gett.push((i+1));
              gett.push(this.notessmatiere[i].Matricule);
              gett.push(this.notessmatiere[i].nom+" "+this.notessmatiere[i].pnom);
              var nn="";
              for (var j=0;j< this.notessmatiere[i]["notematiere"].length;j++){
                if(this.matierevv==this.notessmatiere[i]["notematiere"][j].matiere){
               
                nn=this.notessmatiere[i]["notematiere"][j].notel;
              }
              }
              gett.push(nn);
              body.push(gett);
                
              }
              var gett = []
              gett.push(" ");
              gett.push(" ");
              gett.push(this.noteselevepasnom[0].texte);
              gett.push(" ");
              body.push(gett);
                  this.dataRecup=body;
                  this.export(); 
                 
                });
            });
      },
        );*/
     

  }
  }
  