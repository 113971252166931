import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
@Component({
  selector: 'app-publier-message',
  templateUrl: './publier-message.component.html',
  styleUrls: ['./publier-message.component.scss']
})
export class PublierMessageComponent implements OnInit {








  settings = {
   
    columns: {
      nom: {
        title: 'nom',
        type: 'string',
       // width: '200px'
      },
      prenom: {
        title: 'prenom',
        type: 'string',
     //   width: '200px'
      },
      idClasse: {
        title: 'ID classe',
        type: 'string',
      },
      matricule: {
        title: 'matricule',
        type: 'string',
      //  width: '20px'
      },
      id: {
        title: 'ID',
        type: 'string',
      //  width: '20px'
      },

    },

    pager: {
      display: false,
      perPage: 3,
    },
    actions: {
     delete: false,
     add: false,
     edit: false,
     select: false,
     title: 'Détail',
     custom: [
      {
        name: "routeToAPage1",
        title: " <i class='fa fa-plus></i>"
      }
    ],
   },
   attr: {
    class: 'table table-hover'
  }


  };







  cars = [
    { id: "Vue par tout le monde", name: "Vue par tout le monde" },
    { id: "Les Administrateurs", name: "Les Administrateurs" },
    { id: "Le Personnels de l'établissement", name: "Le Personnels de l'établissement" },
    { id: "Les Parents", name: "Les Parents" },
    { id: "Les Eleves", name: "Les Eleves" },
    { id: "Les Invites", name: "Les Invites" },
];
public items1: any;
public elevevv: any;
//public eleveabsv: any;
eleveabs = [];
vuevv: String="Vue par tout le monde";
public niveauvv: any;
public classevv: any;
idclassevv: String="";

compeTitre: String ="";
compeDescription: String ="";
compemessage: String ="";


typeuser: String ="";

  constructor(private http: HttpClient,private url:UrlsService, 
    private router: Router,private toastr: ToastrService
    ) { 


/*
      this.typeuser=localStorage.getItem('typeuser');
         if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

         
      
    
    
      }else{
        localStorage.removeItem("token");
        localStorage.removeItem("login");
        localStorage.removeItem('id');
        localStorage.removeItem('nomuser');
         localStorage.removeItem('prenomuser');
         localStorage.removeItem('mail');
         localStorage.removeItem('typeuser');
        this.router.navigate(['']);
      }*/






  }
  showSuccess() {
    this.toastr.success('Success', 'Message publié avec succès!', {timeOut: 3000,});
  }
  ngOnInit(): void {
    
   if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG"||localStorage.getItem('typeuser')=="Personnels de l'établissement"  ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }


      this.recuptype_niveau().subscribe(
        data => {
          this.niveauvv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );
    
         this.recuptype_all_classe().subscribe(
            data => {
              this.classevv = JSON.parse(JSON.stringify(data)); 
             // console.log(this.elevevv);
            },
              );
  }

  recuptype_niveau() {
    return this.http
    .get<Eleve[]>(this.url.all_niveaux)
  }
  recuptype_all_classe() {
    return this.http
    .get<Eleve[]>(this.url.all_classse)
  }
  recuptype_classe_niveau(niv: String) {
    return this.http
    .get<Eleve[]>(this.url.classebyniveau+niv)
  }


  elevebyclasse(idclasse: String) {
    return this.http
    .get<Eleve[]>(this.url.elevebyclasse+idclasse)
  }


  choixniveau(e: any) {
    
    
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  }


  choixclasse(e: any) {
   
 this.idclassevv=e.target.value;
 this.elevebyclasse(this.idclassevv).subscribe(
  data => {
    this.elevevv  = JSON.parse(JSON.stringify(data)); 

  },
    );

  }
  Custom(event){

   
    //console.log('id_commande--'+event.data.idville);
 
    this.eleveabs.push({ 
      "nom": event.data.nom,
      "prenom": event.data.prenom,
      "idClasse": event.data.idClasse,
      "matricule": event.data.matricule,
      "id": event.data.id,
    });
    //this.eleveabsv = JSON.parse(JSON.stringify( this.eleveabs)); 
   }
   delete(inCartProductId: String){

    console.log("----------------------"+inCartProductId);
    const product = this.eleveabs.find(p => {
      return p.matricule === inCartProductId;
    });
    console.log(product);
    if(product){
      this.eleveabs.splice(this.eleveabs.map(p => p.matricule).indexOf(inCartProductId), 1);
    }
    console.log(this.eleveabs);
  }






  Vue(e: any) {
    // console.log((e.target as HTMLSelectElement)?.value); // also work
    console.log("----------------------"+e.target.value);
    this.vuevv=e.target.value;
  }

  valChangeTitre(e:Event):void{
    this.compeTitre= (e.target as HTMLInputElement).value


  }
  valChangeDescription(e:Event):void{
    this.compeDescription = (e.target as HTMLInputElement).value


  }
  valChangemessage(e:Event):void{
    this.compemessage = (e.target as HTMLInputElement).value


  }
  
  getValues() {

    this.http.post<any>(this.url.add_message,
      JSON.stringify({  
        code: "--",
        date: "15/12/2022 15h00",
        massagev: this.compemessage,
        auteur: "toto",
        titre:this.compeTitre,
        description: this.compeDescription,
        vue:this.vuevv
       })).subscribe(data => {
      
        if(this.eleveabs.length==0){
        
          window.location.reload();
          this.showSuccess();
        }else{

     
        let eleved="";

    

        for(var i=0;i<this.eleveabs.length;i++) {

          eleved=eleved+"="+this.eleveabs[i]["id"]+";"+data["id"];

        }
        console.log(eleved);
        this.http.post<any>(this.url.add_to_liste,
          JSON.stringify({  
            eleve: eleved.substring(1,eleved.length),
           })).subscribe(data => {
           
            window.location.reload();
            this.showSuccess();
            });

          }
   



        // this.showToast('success');
        // window.location.reload();
        }); 


}
}
