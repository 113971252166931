<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">SUPPRIMER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>SUPPRIMER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->


<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">




                <br /><br /><br />

                <div class="row">

                    <div class="col-lg-12">
                        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">
                            <select selected="" class="form-control"  (change)="choixniveau($event)" >
                                <option     >Tous les niveaux</option>
                                <option *ngFor="let u of niveauvv" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                              </select> 
                        </div>
                    </div>

                    <div class="col-lg-12" >
                        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant' ">
                            <select selected="" class="form-control"  (change)="choixclasse($event)" >
                                <option     >Toutes les classe</option>
                                <option *ngFor="let u of classevv" [value]="u.idClasse"     >{{u.idClasse}}</option>
                              </select> 
                        </div>
                    </div>
                
                    <div class="col-lg-12">
                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="choixeval($event)" >
                                <option    >Evaluation</option>
                                <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
                              </select> 
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="choixmatire($event)" >
                                <option     >Matières</option>
                                <option *ngFor="let u of matierclasse" [value]="u.nom_matiere"     >{{u.nom_matiere}}</option>
                              </select> 
                        </div> 
                    </div>
                

                </div>

                <div class="project-head">
                    <div class="action">
                        <button (click)="Supprimer()" class="theme-btn btn-orange">Supprimer</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
























<!-- End Skillset -->
<app-footer-one></app-footer-one>