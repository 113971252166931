<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">IMPORTER LES DONNEES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>PRAMETRE</a></li>
                        <li>IMPORTER LES DONNEES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >

            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">


                    <div class="transportation-form ptb-100">
                

                        <div class="input_container">
                            <label style="background-color: transparent;font-weight: bold;  color: #34035c; border-radius: 30px !important; border: 1px solid #34035c;" for="files" class="btn">Parcourir  <i class="fa fa-paperclip" aria-hidden="true"></i></label>
                            <input  id="files" style="display:none;" type="file" (change)="importerNote($event)">
                          </div>
    
    
    
                    </div>


                </div>
            </div>


    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>