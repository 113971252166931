<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">ENREGISTRER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>ENREGISTRER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




 

<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">


                <div class="project-head">

                    <ul class="custom-flex details">
                        <li>
                            <h5 class="fw-700">Nom de la matière:</h5>
                            <a  class="fw-500 fs-18">{{nommatire}}</a>
                        </li>
                        <li>
                            <h5 class="fw-700">ID classe:</h5>
                            <a  class="fw-500 fs-18">{{idclasse}}</a>
                        </li>
                        <li>
                            <h5 class="fw-700">Evaluation:</h5>
                            <a  class="fw-500 fs-18">{{eval}}</a>
                        </li>
                    </ul>


                    <div class="action">
                       
                        <button (click)="Enregistrervv()" class="theme-btn btn-orange">Enregistrer</button>
                    
                    </div>





                </div>

                <br /><br /><br />

                <div class="row">

                    <div class="col-lg-2">
                        <h3>Compétence</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group">
                            <input  type="text" (change)="valChangecompe($event)" class="form-control" placeholder="Compétence">
                        </div>
                    </div>
            
                </div>


                <div class="table-responsive">
                    <table class="table check-tbl">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Matricule</th>
                                <th>Noms Prenoms</th>
                                <th>{{evall1}}</th>
                                <th *ngIf="booleval == false">{{evall2}}</th>
                            </tr>
                        </thead>
                        <tbody>
        
        
                            <tr *ngFor="let ur of alleleve" class="alert">
                                <td class="product-item-name">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>
                           
                           
                                <td> <input class="form-control"  type="text"  (change)="valChange($event, ur?.matricule)" placeholder="00.00"></td>
                          
            
                                    <td *ngIf="booleval == false"> <input class="form-control"  type="text"  (change)="valChange2($event, ur?.matricule)" placeholder="00.00"></td>
                                 
                             
                            </tr>
        
                        </tbody>
                    </table>
                </div>
                



 <!-- 
                <div class="row">
                    <div class="col-lg-1">
                    </div>
                    <div  class="col-lg-9">
                        <textarea rows="8" class="form-control"   (change)="valChangeautrenom($event)" ngModel type="text"  name="message" id="message" class="form-control" placeholder="Entrer les Noms et notes des élèves qui n'existent pas dans les listes:"></textarea>
                    </div>
                    <div class="col-lg-2">
                    </div>
                </div>-->


                <br /><br />




                <div class="project-head">
                    <ul class="custom-flex details">
                        <li>
                            <h5 class="fw-700">Ajouter les Noms et notes des élèves qui n'existent pas dans les listes</h5>
                           
                        </li>
                    </ul>

                </div>



                <br />






                <div class="row">
                    <div class="col-lg-6">
                        <div class="row">
                            <div class="col-lg-5">
                        <h3>Nom et prénom</h3>
                          </div>
                        <div class="col-lg-7">
                        <div class="form-group">
                            <input  type="text" (change)="valChangenomprenom($event)" class="form-control" placeholder="">
                        </div>
                        </div>
                        </div>



                        <div class="row">
                            <div class="col-lg-5">
                        <h3>Note {{evall1}}</h3>
                          </div>
                        <div class="col-lg-7">
                        <div class="form-group">
                            <input  type="text" (change)="valChangeeval1($event)" class="form-control" placeholder="">
                        </div>
                        </div>
                        </div>




                        <div class="row" *ngIf="booleval == false">
                            <div class="col-lg-5">
                        <h3>Note {{evall2}}</h3>
                          </div>
                        <div class="col-lg-7">
                        <div class="form-group">
                            <input  type="text" (change)="valChangeeval2($event)" class="form-control" placeholder="">
                        </div>
                        </div>
                        </div>
                     

                    
                <br />
                    <div class="action">
                       
                        <button (click)="nouvelleligne()" class="theme-btn btn-orange">créer une ligne</button>
                    
                    </div>
                    </div>
                
         




               <div class="col-lg-6">
                    <div class="table-responsive">
                        <table class="table check-tbl">
                            <thead>
                                <tr>
                                   
                                 
                                    <th>Nom et Prénom</th>
                                    <th>{{evall1}}</th>
                                    <th *ngIf="booleval == false">{{evall2}}</th>
                                    <th >Action</th>
                                </tr>
                            </thead>
                            <tbody>
        
        
                                <tr *ngFor="let ur of ligneelevepasnom" class="alert">
                                    <td> {{ur?.nom}}</td>
                                    <td> {{ur?.note1}}</td>
                                    <td *ngIf="booleval == false"> {{ur?.note2}}</td>
                                    <td> <button  (click)="deleteligne(ur.nom)" class='btn btn-danger' ><i class="fa fa-trash" aria-hidden="true"></i></button> </td>
                                  
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>


            </div>







               
            </div>
        </div>
    </div>
</section>




























































<!-- End Skillset -->
<app-footer-one></app-footer-one>