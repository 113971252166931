<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">ENREGISTRER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>ENREGISTRER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Notes</span></h6>
                <h3 class="text-blue fw-700 title">Choisir le niveau la classe et l'évaluation<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">


                    <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">
                        <select selected="" class="form-control"  (change)="choixniveau($event)" >
                            <option    >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv" [value]="u.nomniveau"    >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant' ">
                        <select selected="" class="form-control"  (change)="choixclasse($event)" >
                            <option    >Toutes les classe</option>
                            <option *ngFor="let u of classevv" [value]="u.idClasse"    >{{u.idClasse}}</option>
                          </select> 
                    </div>

                    <div class="form-group">
                        <select selected="" class="form-control"  (change)="choixeval($event)" >
                            <option    >Evaluation</option>
                            <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>


                          </select> 
                    </div>


                </div>
            </div>
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="table-responsive">
                        <table class="table check-tbl">
                            <thead>
                                <tr>
                                    <th>Code Matière</th>
                                    <th>Nom Matière</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
            
            
                                <tr *ngFor="let ur of matierclasse" class="alert">
                                    <td class="product-item-name">{{ur.id_matiere}}</td>
                                    <td class="product-item-price">{{ur.nom_matiere}}</td>
                                    <td> <button (click)="selectmatiere(ur.nom_matiere)" class='btn btn-primary' ><i class="fa fa-pencil"></i></button> </td>
                                    <!--<td mat-cell *matCellDef="let cartItem"> <button mat-fab color="warn" (click)=onDeleteProduct(cartItem.inCartProductId)><mat-icon>delete</mat-icon></button> </td>
                                   
                                     <td class="product-item-close">
                                        <a href="javascript:void(0);" data-dismiss="alert" aria-label="close" class="ti-close"></a>
                                    </td> -->
                                </tr>
            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>