<app-header-one></app-header-one>
<!-- Start Slider -->
<div class="main-slider-section p-relative">
    <div class="main-slider">
        <div class="slide-item">
            <img src="assets/etablissement/FONf1.png" class="image-fit">
            <div class="transform-center">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-6 col-lg-7">
                            <div class="banner-content">
                                <a href="#" class="video-btn">
                                    <i class="fas fa-play"></i>
                                </a>
                                <h3 class="text-custom-white fw-600 ">INTSCHOOL Plateforme de gestion scolaire informatisée</h3>
                                <p class="no-margin text-custom-white">//Déjà plusieurs années dans les grands établissements.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!-- slider nav -->

</div>
<!-- End Slider -->
<!-- Start About -->















<section class="section-padding about-sec count-section">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-images p-relative mb-md-80">
                    <img src="assets/etablissement/images.jpg" class="img-fluid image-one" alt="">
                    <img src="assets/images/about-icon-1.png" class="img-fluid icon-one" alt="">
                    <img src="assets/etablissement/fon12.png" class="img-fluid image-two" alt="">
                    <img src="assets/images/about-icon-2.png" class="img-fluid icon-two" alt="">
                </div>
            </div>
            <div class="col-lg-6 col-md-10">
                <div class="section-header left-heading pb-0">
                    <div class="section-heading">
                        <h6 class="text-orange mb-xl-10 sub-heading"><span>Votre Enfant Mérite La Meilleure Éducation</span></h6>
                        <h3 class="text-blue fw-700 title"> L'excellence académique<span class="text-orange">.</span></h3>
                        <p class="desc">Au moment où le système éducatif arrive au bout d'un cycle au regard des nombreuses évolutions de notre société, où les établissements en particulier, les lycées sont obnubilés par les statistiques de résultats  LA PERSEVERANCE a pris résolument le pari du jeune pour le regarder comme un être en devenir, un être relié.</p>
                    </div>
                </div>
                <div class="row align-items-center">
                    <div class="col-4 text-center">
                        <span class="count">82</span><span class="text-orange fs-16">%</span>
                    </div>
                    <div class="col-8">
                        <h4 class="mb-xl-10 fw-600">Notre mission et notre vision<span class="text-orange">.</span></h4>
                        <p>Discipline Travail Succès</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End About -->
<!-- Start Profit Share -->

<!-- End Profit Share -->
<!-- Start Process -->

<!-- End Process -->
<!-- Start Skillset -->





<!-- End brand slider -->
<!-- Start Testimonials -->
<section class="normal-bg section-padding testimonials-sec" style="background-image: url('assets/images/map-bg.jpg');">
    <div class="container">
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Message les plus récents</span></h6>
                <h3 class="fw-700 title">Ci-dessous la liste des messages les plus récents<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="testimonial-slider">
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/about-icon-1.png" class="img-fluid icon-one" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/about-icon-1.png" class="img-fluid icon-one" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/about-icon-1.png" class="img-fluid icon-one" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>
                    <div class="col-12 slide-item">
                        <div class="testimonial-box">
                            <div class="user-img">
                                <img src="assets/images/about-icon-1.png" class="img-fluid icon-one" alt="">
                            </div>
                            <p class="fw-500">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore ma gna aliqua. Ut enim ad minim ve niam, quis nostrud exercitation ullamco lab oris nisi ut aliquip ex ea commodo cons equat. Duis aute irure dolor in reprehe nderit in voluptate velit esse.</p>
                            <h4 class="no-margin fw-600 mb-1"><a href="#">Miranda H.Halim</a></h4>
                            <p class="no-margin fw-600 text-orange double-lines"><span>Founder, Miranda Co.</span></p>
                        </div>
                    </div>



                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->
<app-footer-one></app-footer-one>