<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">EXPORTER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>EXPORTER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




 

<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <div class="col-lg-12 ">
                <div class="project-head">
                    <div class="row">
                        <div class="col-lg-4">
                            <button (click)="Nouvelle_vague()" class="theme-btn btn-orange">Nouvelle vague</button>
                        </div>
                        <div class="col-lg-4">
                            <button (click)="Ancienne_vagueL()"  class="theme-btn btn-orange">Ancienne vague</button>
                        </div>
                            <div class="col-lg-4">
                                <button (click)="toutexporter()"  class="theme-btn btn-orange">Tout exporter</button>
                            </div>


                            
                    </div>
                </div>
            </div>






            <div *ngIf="isnouvelvague" class="col-lg-12 ">
                <div class="contact-info-box mb-md-30">


                    <div  class="form-group">
                        <select selected="" class="form-control"  (change)="choixeval($event)" >
                            <option    >Evaluation</option>
                            <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
                          </select> 
                    </div>
                 
                    <div class="form-btn">
                        <button (click)="exporter_nouvel_vagueL()"  class="theme-btn btn-orange">exporter</button>
                    </div>


                </div>
               </div>




                
            <div *ngIf="isancienvague"  class="col-lg-12 ">
                <div class="contact-info-box mb-md-30">


                    <div  class="form-group">
                
                        <select selected="" class="form-control"  (change)="choixevalAncienne($event)" >
                            <option    >Evaluation</option>
                            <option *ngFor="let u of evaltabvv" [value]="u"    >{{u}}</option>
                          </select> 
                          <br />
                        <select selected="" class="form-control"  (change)="choixvagueAncienne($event)" >
                            <option    >Vague</option>
                            <option *ngFor="let u of vaguevvv" [value]="u.nomvague"    >{{u.nomvague}}</option>
                          </select> 
                    </div>
                 
                    <div class="form-btn">
                        <button (click)="exporter_encienne_vagueL()" class="theme-btn btn-orange">exporter</button>
                    </div>


                </div>
               </div>



















               <div *ngIf="istout"  class="col-lg-12 ">
                <div class="contact-info-box mb-md-30">


                    <div  class="form-group">

                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="choixniveau($event)" >
                                <option    >Tous les niveaux</option>
                                <option *ngFor="let u of niveauvv" [value]="u.nomniveau"    >{{u.nomniveau}}</option>
                              </select> 
                        </div>
                        <br />
                        <div class="form-group" >
                            <select selected="" class="form-control"  (change)="choixclasse($event)" >
                                <option    >Toutes les classe</option>
                                <option *ngFor="let u of classevv" [value]="u.idClasse"    >{{u.idClasse}}</option>
                              </select> 
                        </div>
                        <br />
                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="choixevaltout($event)" >
                                <option    >Evaluation</option>
                                <option *ngFor="let u of evaltabvv" [value]="u"    >{{u}}</option>
    
    
                              </select> 
                        </div>

                
                
                  
                  
                  
                        </div>
                 
                    <div class="form-btn">
                        <button (click)="exporter_tout_niveau_classe()" class="theme-btn btn-orange">exporter</button>
                    </div>


                </div>
               </div>
 
               
















            </div>
        </div>
    </div>
</section>




























































<!-- End Skillset -->
<app-footer-one></app-footer-one>