






<div  [hidden]="!state()" >
                    
    <app-my-login></app-my-login>
 
</div> 
<div  [hidden]="state()" class="wrapper">
    <router-outlet></router-outlet>
</div> 