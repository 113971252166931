<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">CONSULTER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>CONSULTER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Notes</span></h6>
                <h3 class="text-blue fw-700 title">Choisir le niveau, la classe, l'évaluation et la Matières<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">
                        <select selected="" class="form-control"  (change)="choixniveau($event)" >
                            <option     >Tous les niveaux</option>
                            <option *ngFor="let u of niveauvv" [value]="u.nomniveau"     >{{u.nomniveau}}</option>
                          </select> 
                    </div>
                    <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant' || typeuser==='Censeurs'">
                        <select selected="" class="form-control"  (change)="choixclasse($event)" >
                            <option     >Toutes les classe</option>
                            <option *ngFor="let u of classevv" [value]="u.idClasse"     >{{u.idClasse}}</option>
                          </select> 
                    </div>

                    <div class="form-group">
                        <select selected="" class="form-control"  (change)="choixeval($event)" >
                            <option    >Evaluation</option>
                            <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
    
    
                          </select> 
                    </div>





                    <div class="form-group">
                        <select selected="" class="form-control"  (change)="choixmatire($event)" >
                            <option     >Matières</option>
                            <option *ngFor="let u of matierclasse" [value]="u.nom_matiere"     >{{u.nom_matiere}}</option>
                          </select> 
                    </div> 
                </div>
            </div>











            <div class="col-lg-6 " *ngIf="booleval == false">
                <div class="contact-info-box mb-md-30">
                    <div class="table-responsive">
                        <table class="table check-tbl">
                            <thead>
                                <tr>
                                    <th>Num</th>
                                    <th>Matricule</th>
                                    <th>Noms Prenoms</th>
                                    <th>Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                
                
                               <tr *ngFor="let ur of notess" class="alert">
                                    <td class="product-item-name">{{ur?.matricule}}</td>
                                    <td class="product-item-price">{{ur?.matricule}}</td>
                                    <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>
                                    <td class="product-item-price">{{ur?.note}} </td>
                               
                                  
                                </tr>
                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    











            <div class="col-lg-6 " *ngIf="booleval == true">
                <div class="contact-info-box mb-md-30">
                    <div class="table-responsive">
                        <table class="table check-tbl">
                            <thead>
                                <tr>
                                    <th>N°</th>
                                    <th>Matricule</th>
                                    <th>Noms Prenoms</th>
                                    <th>Eval {{eval1}}</th>
                                    <th>Eval {{eval2}}</th>
                                </tr>
                            </thead>
                            <tbody>
                
                
                               <tr *ngFor="let ur of elevebynote" class="alert">
                                    <td class="product-item-name">{{ur?.index}}</td>
                                    <td class="product-item-price">{{ur?.mat}}</td>
                                    <td class="product-item-price">{{ur?.nom}}</td>
                                    <td class="product-item-price">{{ur?.note1}} </td>
                                    <td class="product-item-price">{{ur?.note2}} </td>
                                  
                                </tr>
                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>




        </div>
    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>