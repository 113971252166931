import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UrlsService {
  
 public  getclassebyenseignant= "https://intschoolsarl.com/lbmbackend/public/api/getclassebyenseignant";
  public  login= "https://intschoolsarl.com/lbmbackend/public/api/loginrestAction";
public  all_message= "https://intschoolsarl.com/lbmbackend/public/api/messages";
public  add_message= "https://intschoolsarl.com/lbmbackend/public/api/addmessages";
public  add_eleve= "https://intschoolsarl.com/lbmbackend/public/api/addelevesgg";
public  all_eleve= "https://intschoolsarl.com/lbmbackend/public/api/eleves";
public  add_to_liste= "https://intschoolsarl.com/lbmbackend/public/api/addlisteelevemessage";
public  all_niveaux= "https://intschoolsarl.com/lbmbackend/public/api/niveaux";
public  all_classse= "https://intschoolsarl.com/lbmbackend/public/api/classes";
public  classebyniveau= "https://intschoolsarl.com/lbmbackend/public/api/classebyniveau/";
public  matierebyclasse= "https://intschoolsarl.com/lbmbackend/public/api/matierebyclasse/";
public  matirebyidmatirer= "https://intschoolsarl.com/lbmbackend/public/api/matirebyidmatirer/";
public  elevebyclasse= "https://intschoolsarl.com/lbmbackend/public/api/elevebyclasse/";
public  add_addnotes= "https://intschoolsarl.com/lbmbackend/public/api/addnotes";
public  getnote= "https://intschoolsarl.com/lbmbackend/public/api/getnote";
public  getnoteall= "https://intschoolsarl.com/lbmbackend/public/api/getnoteall";
public  updatenote= "https://intschoolsarl.com/lbmbackend/public/api/updatenote";
public  verifnotes= "https://intschoolsarl.com/lbmbackend/public/api/verifnotes";
public  detailmessage= "https://intschoolsarl.com/lbmbackend/public/api/messages/";
public  listeelevemessage= "https://intschoolsarl.com/lbmbackend/public/api/listeelevemessage";
public  adduser= "https://intschoolsarl.com/lbmbackend/public/api/adduser";
public  users= "https://intschoolsarl.com/lbmbackend/public/api/users";
public  detailuser= "https://intschoolsarl.com/lbmbackend/public/api/users/";
public  updateuser= "https://intschoolsarl.com/lbmbackend/public/api/updateuser";
public  getnotealls= "https://intschoolsarl.com/lbmbackend/public/api/getnotealls";
public  getnoteclassev= "https://intschoolsarl.com/lbmbackend/public/api/getnoteclassev";
public  eleveclasseordre= "https://intschoolsarl.com/lbmbackend/public/api/eleveclasseordre";
public  allvague= "https://intschoolsarl.com/lbmbackend/public/api/getvagueall";
public  getvaguealleval= "https://intschoolsarl.com/lbmbackend/public/api/getvaguealleval";
public  getnoteallunevague= "https://intschoolsarl.com/lbmbackend/public/api/getnoteallunevague";
public  getnoteelevepasliste= "https://intschoolsarl.com/lbmbackend/public/api/getnoteelevepasliste";
public  adduserenseignant= "https://intschoolsarl.com/lbmbackend/public/api/adduserenseignant";
public  enseignantall= "https://intschoolsarl.com/lbmbackend/public/api/enseignantall";
public  allusers= "https://intschoolsarl.com/lbmbackend/public/api/users";
public  adduseren= "https://intschoolsarl.com/lbmbackend/public/api/adduseren";
public  add_discipline= "https://intschoolsarl.com/lbmbackend/public/api/addabs";
public  allabsence= "https://intschoolsarl.com/lbmbackend/public/api/allabsenceclasse";
public  deletedata= "https://intschoolsarl.com/lbmbackend/public/api/deletedata";
public  deleteclassesanseleve= "https://intschoolsarl.com/lbmbackend/public/api/deleteclassesanseleve";
public  updateabs= "https://intschoolsarl.com/lbmbackend/public/api/updateabs";
public  addcd= "https://intschoolsarl.com/lbmbackend/public/api/addcd";
public  cdeleve= "https://intschoolsarl.com/lbmbackend/public/api/cdeleve";
public  updatecd= "https://intschoolsarl.com/lbmbackend/public/api/updatecd";
public  allinfoe= "https://intschoolsarl.com/lbmbackend/public/api/allinfoetablissement";
public  addinfoetablissemen= "https://intschoolsarl.com/lbmbackend/public/api/addinfoetablissemen";
public  getnotetrimestre= "https://intschoolsarl.com/lbmbackend/public/api/getnotetrimestre";
public  updatenotetri= "https://intschoolsarl.com/lbmbackend/public/api/updatenotetri";
public  allabsencevv= "https://intschoolsarl.com/lbmbackend/public/api/allabsencevv";
public  allcdvv= "https://intschoolsarl.com/lbmbackend/public/api/allcdvv";
public  allbloquer= "https://intschoolsarl.com/lbmbackend/public/api/allbloquertrimestre";
public  bloquerD= "https://intschoolsarl.com/lbmbackend/public/api/bloquerD";
public  etatremplisagenote= "https://intschoolsarl.com/lbmbackend/public/api/etatremplisagenote";
public  etatremplisagenoteall= "https://intschoolsarl.com/lbmbackend/public/api/etatremplisagenoteall";
public  adduserclasse= "https://intschoolsarl.com/lbmbackend/public/api/adduserclasse";
public  updateusertype= "https://intschoolsarl.com/lbmbackend/public/api/updateusertype";
public  classebyuser= "https://intschoolsarl.com/lbmbackend/public/api/classebyuser";
public  getnoteallss= "https://intschoolsarl.com/lbmbackend/public/api/getnoteallss";
public  deletenotes= "https://intschoolsarl.com/lbmbackend/public/api/deletenotes";
public  getnoteallniveau= "https://intschoolsarl.com/lbmbackend/public/api/getnoteallniveau";
public  getnoteallclasse= "https://intschoolsarl.com/lbmbackend/public/api/getnoteallclasse";
public  getnoteallniveautoutles= "https://intschoolsarl.com/lbmbackend/public/api/getnoteallniveautoutles";
public  remplissagetableetatnote= "https://intschoolsarl.com/lbmbackend/public/api/remplissagetableetatnote";
public  etatremplisagenoteallget= "https://intschoolsarl.com/lbmbackend/public/api/etatremplisagenoteallget/";









/*
public  deleteclassesanseleve= "http://127.0.0.1:8000/api/deleteclassesanseleve";
public  deletedata= "http://127.0.0.1:8000/api/deletedata";
public  allusers= "http://127.0.0.1:8000/api/users";
public  adduserenseignant= "http://127.0.0.1:8000/api/adduserenseignant";
public  getclassebyenseignant= "http://127.0.0.1:8000/api/getclassebyenseignant";
public  login= "http://127.0.0.1:8000/api/loginrestAction";
public  all_message= "http://127.0.0.1:8000/api/messages";
public  add_message= "http://127.0.0.1:8000/api/addmessages";
public  add_eleve= "http://127.0.0.1:8000/api/addelevesgg";
public  all_eleve= "http://127.0.0.1:8000/api/eleves";
public  add_to_liste= "http://127.0.0.1:8000/api/addlisteelevemessage";
public  all_niveaux= "http://127.0.0.1:8000/api/niveaux";
public  all_classse= "http://127.0.0.1:8000/api/classes";
public  classebyniveau= "http://127.0.0.1:8000/api/classebyniveau/";
public  matierebyclasse= "http://127.0.0.1:8000/api/matierebyclasse/";
public  matirebyidmatirer= "http://127.0.0.1:8000/api/matirebyidmatirer/";
public  elevebyclasse= "http://127.0.0.1:8000/api/elevebyclasse/";
public  add_addnotes= "http://127.0.0.1:8000/api/addnotes";
public  getnote= "http://127.0.0.1:8000/api/getnote";
public  getnoteall= "http://127.0.0.1:8000/api/getnoteall";
public  enseignantall= "http://127.0.0.1:8000/api/enseignantall";
public  updatenote= "http://127.0.0.1:8000/api/updatenote";
public  verifnotes= "http://127.0.0.1:8000/api/verifnotes";
public  detailmessage= "http://127.0.0.1:8000/api/messages/";
public  listeelevemessage= "http://127.0.0.1:8000/api/listeelevemessage";
public  adduser= "http://127.0.0.1:8000/api/adduser";
public  users= "http://127.0.0.1:8000/api/users";
public  detailuser= "http://127.0.0.1:8000/api/users/";
public  updateuser= "http://127.0.0.1:8000/api/updateuser";
public  getnotealls= "http://127.0.0.1:8000/api/getnotealls";
public  getnoteclassev= "http://127.0.0.1:8000/api/getnoteclassev";
public  eleveclasseordre= "http://127.0.0.1:8000/api/eleveclasseordre";
public  allvague= "http://127.0.0.1:8000/api/getvagueall";
public  getvaguealleval= "http://127.0.0.1:8000/api/getvaguealleval";
public  getnoteallunevague= "http://127.0.0.1:8000/api/getnoteallunevague";
public  getnoteelevepasliste= "http://127.0.0.1:8000/api/getnoteelevepasliste";
public  adduseren= "http://127.0.0.1:8000/api/adduseren";
public  add_discipline= "http://127.0.0.1:8000/api/addabs";
public  allabsence= "http://127.0.0.1:8000/api/allabsenceclasse";
public  updateabs= "http://127.0.0.1:8000/api/updateabs";
public  addcd= "http://127.0.0.1:8000/api/addcd";
public  cdeleve= "http://127.0.0.1:8000/api/cdeleve";
public  updatecd= "http://127.0.0.1:8000/api/updatecd";
public  allinfoe= "http://127.0.0.1:8000/api/allinfoetablissement";
public  addinfoetablissemen= "http://127.0.0.1:8000/api/addinfoetablissemen";
public  getnotetrimestre= "http://127.0.0.1:8000/api/getnotetrimestre";
public  updatenotetri= "http://127.0.0.1:8000/api/updatenotetri";
public  allabsencevv= "http://127.0.0.1:8000/api/allabsencevv";
public  allcdvv= "http://127.0.0.1:8000/api/allcdvv";
public  allbloquer= "http://127.0.0.1:8000/api/allbloquertrimestre";
public  bloquerD= "http://127.0.0.1:8000/api/bloquerD";
public  etatremplisagenote= "http://127.0.0.1:8000/api/etatremplisagenote";
public  etatremplisagenoteall= "http://127.0.0.1:8000/api/etatremplisagenoteall";
public  adduserclasse= "http://127.0.0.1:8000/api/adduserclasse";
public  classebyuser= "http://127.0.0.1:8000/api/classebyuser";
public  updateusertype= "http://127.0.0.1:8000/api/updateusertype";
public  getnoteallss= "http://127.0.0.1:8000/api/getnoteallss";
public  deletenotes= "http://127.0.0.1:8000/api/deletenotes";

public  getnoteallniveau= "http://127.0.0.1:8000/api/getnoteallniveau";
public  getnoteallclasse= "http://127.0.0.1:8000/api/getnoteallclasse";
public  getnoteallniveautoutles= "http://127.0.0.1:8000/api/getnoteallniveautoutles";
public  remplissagetableetatnote= "http://127.0.0.1:8000/api/remplissagetableetatnote";
public  etatremplisagenoteallget= "http://127.0.0.1:8000/api/etatremplisagenoteallget/";*/


  constructor() { }
}
