import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-deletedata',
  templateUrl: './deletedata.component.html',
  styleUrls: ['./deletedata.component.css']
})
export class DeletedataComponent implements OnInit {
  classevvd : any = [];
  classevvdf : any = [];
  typeuser: String="";
  evall: String="";
  idclassevv: String="";
public items1: any;
public niveauvv: any;
public classevv: any;
public matierclasse: any;
public matierclasseF: any;
//public eleveabsv: any;
eleveabs = [];
vuevv: String="Vue par tout le monde";
data = ["Classe","Niveau","Matière","Matière classe","Elève","Enseignant","Département"];

  constructor(private http: HttpClient,private url:UrlsService, private router: Router ,   private toastr: ToastrService) { 

  }
  showSuccessnote() {
    this.toastr.error('Error', "Les notes de cette matière existent déjà", {timeOut: 5000,});
  }
  showSuccess(inCartProductId: String) {
    this.toastr.success('Success', inCartProductId+" supprimé(e) avec succès!", {timeOut: 5000,});
  }
  ngOnInit(): void {

        if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

   // localStorage.setItem('typeuser',"Super-administrateur");
    this.typeuser=localStorage.getItem('typeuser');
    




        
  }





  selectmatiere(inCartProductId: String){

    console.log(inCartProductId);


if(inCartProductId=="Classe"){
  console.log("*************************************");
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Classe: "Classe",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}

if(inCartProductId=="Niveau"){
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Niveau: "Niveau",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}
if(inCartProductId=="Matière"){
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Matiere: "Matière",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}
if(inCartProductId=="Matière classe"){
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Matiereclass: "Matière classe",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}
if(inCartProductId=="Elève"){
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Eleve: "Elève",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}
if(inCartProductId=="Enseignant"){
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Enseignant: "Enseignant",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}
if(inCartProductId=="Département"){
  this.http.post<any>(this.url.deletedata,
    JSON.stringify({  
      Departement: "Département",
     })).subscribe(data => {
     this.showSuccess(inCartProductId) ;
      });
}

  }

  







}

