import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import { VERSION } from '@angular/core';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-expoerterabs',
  templateUrl: './expoerterabs.component.html',
  styleUrls: ['./expoerterabs.component.css']
})
export class ExpoerterabsComponent implements OnInit {

  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notess: any;
  //public eleveabsv: any;
  eleveabs = [];
  isnouvelvague = false;
  isancienvague = false;
  eval1="";
  eval2="";
  vuevv: String="Vue par tout le monde";
  vague: String="0";
  evall: String="";
  vagueAncienne: String="";
  evalAncienne: String="";
  matiere: String="";
  idclassevv: String="Toutes les classes";
  idniveau: String="Tous les niveaux";
  notevv = [];
  name = 'ExcelSheet.xlsx';
  evaltab = ["1","2","3","4","5","6","Trimestre 1","Trimestre 2","Trimestre 3"];

  vaguevvv = [];
    constructor(private http: HttpClient,private url:UrlsService, private router: Router ) { 
  
    }
   /* showSuccess() {
      this.toastr.success('Success', 'Note exportée avec succès!', {timeOut: 3000,});
    }*/
    ngOnInit(): void {

      if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
        ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
        ||localStorage.getItem('typeuser')=="SG" ){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }




    }
  


  
  

    exportexcel(tab: any,titreg:any): void {
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tab);
     
      /* generate workbook and add the worksheet */

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, titreg);
  }


  choixeval(e: any) {
    
    this.evall=e.target.value;

  }




  


  exporter_nouvel_vagueL() {



    if(this.evall=="Trimestre 1"){
      this.eval1="1";
      this.eval2="2";
      this.http.post<any>(this.url.allabsencevv,
        JSON.stringify({  
          eval:this.eval1,
          eval2:this.eval2,
         })).subscribe(data => {
    
          if(data.length==0){
    
          }else{
            this. notevv = [];
    
            this.notess = JSON.parse(JSON.stringify(data)); 
            for (let i =0; i <this.notess.length; i++){
              this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                              "Eval":this.notess[i].eval,
                                              "ANJ":this.notess[i].anj,
                                              "AJ":this.notess[i].aj,
                                              "Reds":this.notess[i].reds,
                                              "Cons":this.notess[i].cons}));     
                                              
    
            }
              
            this.exportexcel(this.notevv,"ConduiteTrimestre1"+".xlsx");
          //  this.showSuccess();
          }
          });
    }else if(this.evall=="Trimestre 2"){
      this.eval1="3";
      this.eval2="4";
      this.http.post<any>(this.url.allabsencevv,
        JSON.stringify({  
          eval:this.eval1,
          eval2:this.eval2,
         })).subscribe(data => {
    
          if(data.length==0){
    
          }else{
            this. notevv = [];
    
            this.notess = JSON.parse(JSON.stringify(data)); 
            for (let i =0; i <this.notess.length; i++){
              this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                              "Eval":this.notess[i].eval,
                                              "ANJ":this.notess[i].anj,
                                              "AJ":this.notess[i].aj,
                                              "Reds":this.notess[i].reds,
                                              "Cons":this.notess[i].cons}));     
                                              
    
            }
              
            this.exportexcel(this.notevv,"ConduiteTrimestre2"+".xlsx");
          //  this.showSuccess();
          }
          });
    }else if(this.evall=="Trimestre 3"){
      this.eval1="5";
      this.eval2="6";
      this.http.post<any>(this.url.allabsencevv,
        JSON.stringify({  
          eval:this.eval1,
          eval2:this.eval2,
         })).subscribe(data => {
    
          if(data.length==0){
    
          }else{
            this. notevv = [];
    
            this.notess = JSON.parse(JSON.stringify(data)); 
            for (let i =0; i <this.notess.length; i++){
              this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                              "Eval":this.notess[i].eval,
                                              "ANJ":this.notess[i].anj,
                                              "AJ":this.notess[i].aj,
                                              "Reds":this.notess[i].reds,
                                              "Cons":this.notess[i].cons}));     
                                              
    
            }
              
            this.exportexcel(this.notevv,"ConduiteTrimestre3"+".xlsx");
          //  this.showSuccess();
          }
          });
    }else{
      this.http.post<any>(this.url.allabsencevv,
        JSON.stringify({  
          eval:this.evall,
         })).subscribe(data => {
    
          if(data.length==0){
    
          }else{
            this. notevv = [];
    
            this.notess = JSON.parse(JSON.stringify(data)); 
            for (let i =0; i <this.notess.length; i++){
              this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                              "Eval":this.notess[i].eval,
                                              "ANJ":this.notess[i].anj,
                                              "AJ":this.notess[i].aj,
                                              "Reds":this.notess[i].reds,
                                              "Cons":this.notess[i].cons}));     
                                              
    
            }
              
            this.exportexcel(this.notevv,"ConduiteEval"+this.evall+".xlsx");
          //  this.showSuccess();
          }
          });
     }


    

  
    
    
  
  }

  }
  