<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">MODIFICATION D'UN UTILISATEUR</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>UTILISATEUR</a></li>
                        <li>MODIFICATION D'UN UTILISATEUR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->



<br />    <br />
<div class="row">
<div  class="col-lg-3"></div>
<div  class="col-lg-6 ">
    <div class="contact-info-box mb-md-30">

        <div class="form-group">
            <select selected="" class="form-control"  (change)="Vue($event)" >
                <option    style="background-color: transparent;  color: #07095f; ">Type utilisateur</option>
                <option *ngFor="let u of cars" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
              </select> 
        </div>

        <div class="form-group">
            <input (change)="valChangeNom($event)" [value]="nom" type="text" name="Nom" id="Nom" class="form-control" placeholder="Nom:">
        </div>

        <div class="form-group">
            <input (change)="valChangePrenom($event)" [value]="prenom"  type="text" name="Prenom" id="Prenom" class="form-control" placeholder="Prénom:">
        </div>


        <div class="form-group">
            <select selected="" class="form-control"  (change)="sexeget($event)" >
                <option    style="background-color: transparent;  color: #07095f; ">Sexe</option>
                <option *ngFor="let u of sexe" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
              </select> 
        </div>
        <div class="form-group">
            <input (change)="valChangeTel($event)" [value]="tel"  type="text"  name="Tel" id="Tel" class="form-control" placeholder="Tel:">
         
        </div>
        <div class="form-group">
            <input (change)="valChangeLogin($event)" [value]="loginuser"   type="text"  name="Login" id="Login" class="form-control" placeholder="Login:">
         
        </div>
        <div class="form-group">
            <input (change)="valChangePassword($event)" [value]="pass"  type="password" name="Password" id="Password" class="form-control" placeholder="Password">
         
        </div>
        <div class="form-group">
            <input (change)="valChangePasswordc($event)"  [value]="pass"  type="password" name="Passwordc" id="Passwordc" class="form-control" placeholder="Confirmer Password">
         
        </div>
        

        <div class="form-btn">
            
            <button (click)="Enregistrer()" class="theme-btn btn-orange">Enregistrer</button>
            <!--<button type="submit" class="default-btn-two">Get A Full Quote</button>--> 
        </div>


    </div>
   </div>
   <div  class="col-lg-3"></div>
</div>






<!-- End Skillset -->
<app-footer-one></app-footer-one>







































<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>ENREGISTRER UN UTILISATEUR</h2>
                    <ul>
                        <li><a routerLink="/">UTILISATEUR</a></li>
                        <li>ENREGISTRER UN UTILISATEUR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->



<!-- Transportation Area -->

<div class="row">

    <div class="col-lg-3 col-md-12">
    </div>

<div class="col-lg-6 col-md-12">

<div class="transportation-area">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
          
                <div class="transportation-form ptb-100">
                    <div class="section-title">
                   
                        <h2>Formulaire d'enregistrement d'un utilisateur</h2>
                    </div>

 
                  

                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="Vue($event)" >
                                <option    style="background-color: transparent;  color: #07095f; ">Type utilisateur</option>
                                <option *ngFor="let u of cars" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
                              </select> 
                        </div>

                        <div class="form-group">
                            <input ngModel type="text" name="Nom" id="Nom" class="form-control" placeholder="Nom:">
                        </div>

                        <div class="form-group">
                            <input ngModel type="text" name="Prenom" id="Prenom" class="form-control" placeholder="Prénom:">
                        </div>


                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="sexeget($event)" >
                                <option    style="background-color: transparent;  color: #07095f; ">Sexe</option>
                                <option *ngFor="let u of sexe" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
                              </select> 
                        </div>
                        <div class="form-group">
                            <input ngModel type="text"  name="Tel" id="Tel" class="form-control" placeholder="Tel:">
                         
                        </div>
                        <div class="form-group">
                            <input ngModel type="text"  name="Login" id="Login" class="form-control" placeholder="Login:">
                         
                        </div>
                        <div class="form-group">
                            <input ngModel type="password" name="Password" id="Password" class="form-control" placeholder="Password">
                         
                        </div>
                        <div class="form-group">
                            <input ngModel type="password" name="Password" id="Password" class="form-control" placeholder="Confirmer Password">
                         
                        </div>
                        

                        <div class="form-btn">
                            <button type="submit" class="default-btn-one mr-4">Soumettre</button>
                            <!--<button type="submit" class="default-btn-two">Get A Full Quote</button>--> 
                        </div>
                 
                </div>
        


        </div>
    </div>
</div>
</div>



<div class="col-lg-3 col-md-12">
</div>




</div>























































<!-- Page banner Area -->
<div class="page-banner bg-2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>MODIFICATION D'UN UTILISATEUR</h2>
                    <ul>
                        <li><a routerLink="/">UTILISATEUR</a></li>
                        <li>MODIFICATION D'UN UTILISATEUR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->



<!-- Transportation Area -->

<div class="row">

    <div class="col-lg-3 col-md-12">
    </div>

<div class="col-lg-6 col-md-12">

<div class="transportation-area">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
          
                <div class="transportation-form ptb-100">
                    <div class="section-title">
                   
                        <h2>Formulaire d'modification d'un utilisateur</h2>
                    </div>



                 
                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="Vue($event)" >
                                <option   [value]="typeuserv"  style="background-color: transparent;  color: #07095f; ">{{typeuserv}}</option>
                                <option *ngFor="let u of cars" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
                              </select> 
                        </div>

                        <div class="form-group">
                            <input  [(ngModel)]="nom" type="text" name="Nom" id="Nom" class="form-control" placeholder="Nom:">
                        </div>

                        <div class="form-group">
                            <input [(ngModel)]="prenom" type="text" name="Prenom" id="Prenom" class="form-control" placeholder="Prénom:">
                        </div>


                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="sexeget($event)" >
                                <option   [value]="sexeuser"  style="background-color: transparent;  color: #07095f; ">{{sexeuser}}</option>
                                <option *ngFor="let u of sexe" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
                              </select> 
                        </div>
                        <div class="form-group">
                            <input [(ngModel)]="tel" type="text"  name="Tel" id="Tel" class="form-control" placeholder="Tel:">
                         
                        </div>
                        <div class="form-group">
                            <input [(ngModel)]="loginuser" type="text"  name="Login" id="Login" class="form-control" placeholder="Login:">
                         
                        </div>
                        <div class="form-group">
                            <input [(ngModel)]="pass" type="password" name="Password" id="Password" class="form-control" placeholder="Password">
                         
                        </div>
                        <div class="form-group">
                            <input [(ngModel)]="pass" type="password" name="Password" id="Password" class="form-control" placeholder="Confirmer Password">
                         
                        </div>
                        

                        <div class="form-btn">
                            <button type="submit" class="default-btn-one mr-4">Modifier</button>
                            <!--<button type="submit" class="default-btn-two">Get A Full Quote</button>--> 
                        </div>
                   
                </div>
        


        </div>
    </div>
</div>
</div>



<div class="col-lg-3 col-md-12">
</div>




</div>
