import { Component, OnInit } from '@angular/core';
import { Eleve } from '../../../service/eleve';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UrlsService } from '../../../services/urls.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-importerdonnee',
  templateUrl: './importerdonnee.component.html',
  styleUrls: ['./importerdonnee.component.scss']
})
export class ImporterdonneeComponent implements OnInit {










  selectedFile: File
  chemainfile='';

  elevevv = [];
  classenn = [];
  niveaunn = [];
  public items_eleve= [];
  public items1: any;
  banner : any = {
		
		pagetitle: "Importation des éléments de configuration",
		bg_image: "assets/images/etablissement/fon111.png",
		title: "Importation des éléments de configuration",
	}
  public allNote;
  constructor(private http: HttpClient,private url:UrlsService,
    private router: Router,private toastr: ToastrService
    ) { }
    ngOnInit(): void {
      if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }


      this.recuptype_all_eleve().subscribe(
        data => {
          this.elevevv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
          if (this.elevevv.length>0){
           localStorage.setItem("elevevv","1");
          }else{
            localStorage.setItem("elevevv","0");
           }
        },
          );
          this.recuptype_niveau().subscribe(
            data => {
              this.classenn = JSON.parse(JSON.stringify(data)); 

              if (this.classenn.length>0){
                localStorage.setItem("classenn","1");
               }else{
                 localStorage.setItem("classenn","0");
                }
            },
              );
              this.recuptype_all_classe().subscribe(
                data => {
                  this.niveaunn = JSON.parse(JSON.stringify(data)); 
                  if (this.niveaunn.length>0){
                    localStorage.setItem("niveaunn","1");
                   }else{
                     localStorage.setItem("niveaunn","0");
                    }
                },
                  );


    }
  showSuccess() {
    this.toastr.success('Success', 'fichier importé avec succès!', {timeOut: 3000,});
  }
  showSuccessnotes() {
    this.toastr.error('error', "Les données ont déjà été importer allez vider puis reésayer !", {timeOut: 10000,});
  }
  recuptype_all_eleve() {
    return this.http
    .get<Eleve[]>(this.url.all_eleve)
  }
  recuptype_niveau() {
    return this.http
    .get<Eleve[]>(this.url.all_niveaux)
  }
  recuptype_all_classe() {
    return this.http
    .get<Eleve[]>(this.url.all_classse)
  }
  public importerNote(event:any){



  let eleveeee= localStorage.getItem("elevevv");
  let classenn= localStorage.getItem("classenn");
  let niveaunn= localStorage.getItem("niveaunn");

  console.log("*****************"+niveaunn);
  console.log("//////////////////"+classenn);
  console.log("-----------------"+eleveeee);
  
/*if(eleveeee=="1"||classenn=="1"||niveaunn=="1"){

  this.showSuccessnotes();
 }else{*/

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      this.selectedFile = event.target.files[0]
      reader.onload = (event:any) => {
          this.chemainfile= event.target.result;
          console.log("--------------------"+this.chemainfile);
      }

      reader.readAsDataURL(event.target.files[0]);





      const uploadData = new FormData();
      uploadData.append('avatar', this.selectedFile);
    
    
    this.http.post(this.url.add_eleve,
    uploadData).subscribe(data => {
      this.showSuccess();
              console.log(data);
           }); 
    

  }
//}








  }



}
