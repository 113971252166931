
<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">ENREGISTRER INFO ETABLISSEMENT</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>PARAMETRE</a></li>
                        <li>ENREGISTRER INFO ETABLISSEMENT</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




<section class="section-padding project-details">
    <div class="container">
        <div class="row">

            <br /><br /><br />
        
                <div class="row">

                    <div class="col-lg-2">
                    </div>
                
                    <div class="col-lg-10">
                        <div class="transportation-form ptb-100">
                            <div class="section-title">
                           
                                <h2>Formulaire d'enregistrement des information de l'établissement</h2>
                            </div>
        
                  
        
        
                                <div class="form-group">
                                    <input [value]="NomFvv" type="text" (change)="NomF($event)"  class="form-control" placeholder="Nom de l'établissement en Fr">
                                </div>
        
                                <div class="form-group">
                                    <input [value]="NomEvv" type="text" (change)="NomE($event)"   class="form-control" placeholder="Nom de l'établissement en Ang">
                                </div>
        
                                <div class="form-group">
                                    <input [value]="Telvv" type="text"  (change)="Tel($event)"   class="form-control" placeholder="Tel">
                                 
                                </div>
                                <div class="form-group">
                                    <input [value]="Villevv" type="text"  (change)="Ville($event)"   class="form-control" placeholder="Ville">
                                 
                                </div>
                            
                                <div class="form-group">
                                    <input [value]="Nomresponsablevv" type="text"  (change)="Nomresponsable($event)"  class="form-control" placeholder="Nom responsable">
                                 
                                </div>
                                <div class="form-group">
                                    <input [value]="Regionvv" type="text"  (change)="Region($event)"   class="form-control" placeholder="Region">
                                 
                                </div>
                                <div class="form-group">
                                    <input [value]="Departementvv" type="text"  (change)="Departement($event)"  class="form-control" placeholder="Departement">
                                 
                                </div>
                                <div class="form-group">
                                    <input [value]="devisevv" type="text"  (change)="devise($event)"   class="form-control" placeholder="Devise">
                                 
                                </div>
        
        
                                <div class="action">
                       
                                    <button (click)="Enregistrer()" class="theme-btn btn-orange">Enregistrer</button>
                                
                                </div>
        
        
        
                           
                        </div>
                    </div>
                    <div class="col-lg-2">
                    </div>
                </div>


            
        </div>
    </div>
</section>



<!-- End Skillset -->
<app-footer-one></app-footer-one>


