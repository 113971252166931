
<br />    <br />
<div class="row">
<div  class="col-lg-3"></div>
<div  class="col-lg-6 ">
    <div class="contact-info-box mb-md-30">

  
	

        <div class="form-group">
            <input  type="text" #username id="username" class="form-control" placeholder="user name">
        </div>

        <div class="form-group">
            <input type="password" #password  id="password" class="form-control" placeholder="password">
        </div>
	




        <div class="form-btn">
            
            <button (click)="onSubmit(username.value, password.value)" class="theme-btn btn-orange">Connexion</button>
            <!--<button type="submit" class="default-btn-two">Get A Full Quote</button>--> 
        </div>


    </div>
   </div>
   <div  class="col-lg-3"></div>
</div>







































