<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">BLOQUER OU DEBLOQUER UN TRIMESTRE</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>PARAMETRE</a></li>
                        <li>BLOQUER OU DEBLOQUER UN TRIMESTRE</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >
        <div class="section-header">
            <div class="section-heading">
                <h6 class="text-orange mb-xl-10 sub-heading"><span>Paramètre</span></h6>
                <h3 class="text-blue fw-700 title">BLOQUER OU DEBLOQUER UN TRIMESTRE<span class="text-orange">.</span></h3>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">
                    <div class="table-responsive">
                        <table class="table check-tbl">
                            <thead>
                                <tr>
                                    <th>Trimestre</th>
                                    <th>Statut</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
            
            
                                <tr *ngFor="let ur of evaltabbloqueretnon" class="alert">
                                    <td class="product-item-name">{{ur.trim}}</td>
                                    <td class="product-item-name">{{ur.statu}}</td>
                                    <td> <button (click)="selectmatiere(ur.trim)" class='btn btn-primary' ><i class="fa fa-sort" aria-hidden="true"></i></button> </td>
                                    
                                </tr>
            
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
    
        </div>
    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>