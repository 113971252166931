<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">REMPLISSAGE TABLE ETAT REMPLISSAGE NOTE</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>PARAMETRE</a></li>
                        <li>REMPLISSAGE TABLE ETAT REMPLISSAGE NOTE</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >

            <div class="col-lg-6 ">
                <div class="contact-info-box mb-md-30">


                    <div class="transportation-form ptb-100">
                

                        <div class="input_container">
                            <div class="action">
                       
                                <button (click)="supprimer()" class="theme-btn btn-orange">Remplir</button>
                            
                            </div>
                          </div>
    
    
    
                    </div>


                </div>
            </div>


    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>