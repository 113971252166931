import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';


@Component({
  selector: 'app-bloquertrimestre',
  templateUrl: './bloquertrimestre.component.html',
  styleUrls: ['./bloquertrimestre.component.css']
})
export class BloquertrimestreComponent implements OnInit {

  classevvd : any = [];
  evaltabbloqueretnon : any = [];
  typeuser: String="";
  evall: String="";
  idclassevv: String="";
public items1: any;
public niveauvv: any;
public classevv: any;
allbloquer : any = [];
public matierclasse: any;
public matierclasseF: any;
//public eleveabsv: any;
eleveabs = [];
vuevv: String="Vue par tout le monde";
evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3"];

  constructor(private http: HttpClient,private url:UrlsService, private router: Router ,   private toastr: ToastrService) { 

  }
  showSuccessnote() {
    this.toastr.error('Error', "Les notes de cette matière existent déjà", {timeOut: 5000,});
  }
  showSuccess() {
    this.toastr.success('Success', 'Opération effectuée avec succès!', {timeOut: 5000,});
  }
  ngOnInit(): void {

        if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }
    
    this.typeuser=localStorage.getItem('typeuser');





    this.allbloquer = [];
    this.evaltabbloqueretnon = [];
    this.recuptype_alltrimestre().subscribe(
      data => {
        this.allbloquer = JSON.parse(JSON.stringify(data)); 
        for (let ig =0; ig <this.evaltab.length; ig++){
          let tu="Débloquer";
          for (let i =0; i <this.allbloquer.length; i++){
            if(this.evaltab[ig ]==this.allbloquer[i].trimestre){
              tu="Bloquer";
              break;
            }
          }
          this.evaltabbloqueretnon.push(Object.assign({"trim":this.evaltab[ig ],
          "statu":tu})); 
        }  
      },
        );



   
  }


  recuptype_alltrimestre() {
    return this.http
    .get<Eleve[]>(this.url.allbloquer)
  }

  selectmatiere(inCartProductId: String){
console.log("---------------"+inCartProductId);
    this.http.post<any>(this.url.bloquerD,
      JSON.stringify({  
        trimestre:  inCartProductId,
       })).subscribe(data => {
        this.allbloquer = [];
        this.evaltabbloqueretnon = [];
        this.recuptype_alltrimestre().subscribe(
          data => {
            this.allbloquer = JSON.parse(JSON.stringify(data)); 
            for (let ig =0; ig <this.evaltab.length; ig++){
              let tu="Débloquer";
              for (let i =0; i <this.allbloquer.length; i++){
                if(this.evaltab[ig ]==this.allbloquer[i].trimestre){
                  tu="Bloquer";
                  break;
                }
              }
              this.evaltabbloqueretnon.push(Object.assign({"trim":this.evaltab[ig ],
              "statu":tu})); 
            }  
          },
            );
        this.showSuccess();
        
        });


  


  }







}

