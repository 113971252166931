import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from '@angular/router';
import { VERSION } from '@angular/core';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-exporternotes',
  templateUrl: './exporternotes.component.html',
  styleUrls: ['./exporternotes.component.css']
})
export class ExporternotesComponent implements OnInit {

  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notess: any;
  //public eleveabsv: any;
  eleveabs = [];
  isnouvelvague = false;
  isancienvague = false;
  istout = false;
  
  vuevv: String="Vue par tout le monde";
  vague: String="0";
  evall: String="";
  choixevaltoutt: String="";
  vagueAncienne: String="";
  evalAncienne: String="";
  matiere: String="";
  idclassevv: String="Toutes les classes";
  idniveau: String="Tous les niveaux";
  notevv = [];
  name = 'ExcelSheet.xlsx';
  evaltab = ["1","2","3","4","5","6","Trimestre 1","Trimestre 2","Trimestre 3"];
  evaltabvv = ["1","2","3","4","5","6"];
  vaguevvv = [];
    constructor(private http: HttpClient,private url:UrlsService, private router: Router ,   private toastr: ToastrService) { 
  
    }
    showSuccessnotecch() {
      this.toastr.error('Error', "Vous n'avez pas choisi le niveau", {timeOut: 5000,});
    }
    ngOnInit(): void {

      if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
        ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
        ||localStorage.getItem('typeuser')=="SG" ){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }


      this.recuptype_allvague().subscribe(
        data => {
          this.vaguevvv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );

          this.recuptype_niveau().subscribe(
            data => {
              this.niveauvv = JSON.parse(JSON.stringify(data)); 
             // console.log(this.elevevv);
            },
              );
              this.recuptype_all_classe().subscribe(
                data => {
                  this.classevv = JSON.parse(JSON.stringify(data)); 
                 // console.log(this.elevevv);
                },
                  );
      
   /* this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  
        this.recuptype_all_classe().subscribe(
          data => {
            this.classevv = JSON.parse(JSON.stringify(data)); 
           // console.log(this.elevevv);
          },
            );*/
    }
    recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }
  



    choixniveau(e: any) {

      if(e.target.value=="Tous les niveaux"){

      }else{
        this.idniveau=e.target.value;
      
        this.recuptype_classe_niveau(e.target.value).subscribe(
          data => {
            this.classevv = JSON.parse(JSON.stringify(data)); 
           // console.log(this.elevevv);
          },
            );
      }


    }
    choixclasse(e: any) {
      if(e.target.value=="Toutes les classes"){

      }else{
        this.idclassevv=e.target.value;
      }
    }
    
    choixevaltout(e: any) {
    
      this.choixevaltoutt=e.target.value;
  
    }
    


    choixVague(e: any) {
     

      this.vague=e.target.value;
      if(this.vague=="200"){

        this.vague="0"
      }else if(this.vague=="201"){

        this.vague="-"
      }
    }
  

  
  


















    exportexcel(tab: any,titreg:any): void {
      /* pass here the table id */
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(tab);
     
      /* generate workbook and add the worksheet */

      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      /* save to file */
      XLSX.writeFile(wb, titreg);
  }

  recuptype_allnote() {
    return this.http
    .get<Eleve[]>(this.url.getnotealls)
  }
  choixeval(e: any) {
    
    this.evall=e.target.value;

  }
  choixevalAncienne(e: any) {
    
    this.evalAncienne=e.target.value;
    this.http.post<any>(this.url.getvaguealleval,
      JSON.stringify({  
        eval:  this.evalAncienne,
  
       })).subscribe(data => {
  
        this.vaguevvv = JSON.parse(JSON.stringify(data)); 
        });
  }
  choixvagueAncienne(e: any) {
    
    this.vagueAncienne=e.target.value;

  }
  Nouvelle_vague() {
    this.isnouvelvague=true;
    this.isancienvague=false;
    this.istout=false;
  }
  Ancienne_vagueL() {
    this.isancienvague=true;
    this.isnouvelvague=false;
    this.istout=false;
  }
  toutexporter() {
    this.isancienvague=false;
    this.isnouvelvague=false;
    this.istout=true;
  }

  

  recuptype_allvague() {
    return this.http
    .get<Eleve[]>(this.url.allvague)
  }


  recuptype_allgetnotealls() {
    return this.http
    .get<Eleve[]>(this.url.getnotealls)
  }



  


  exporter_tout_niveau_classe() {

   
    

console.log("-------eval----------"+ this.choixevaltoutt);
console.log("--------clas---------"+ this.idclassevv);
console.log("--------nic---------"+ this.idniveau);
if(this.choixevaltoutt==""){

  this.showSuccessnotecch() 
}else{


if(this.idniveau=="Tous les niveaux"){
  if(this.idclassevv=="Toutes les classes"){



    this.http.post<any>(this.url.getnoteallniveautoutles,
      JSON.stringify({  
        eval:this.choixevaltoutt,
       })).subscribe(data => {
        this. notevv = [];
    
        this.notess = JSON.parse(JSON.stringify(data)); 
        for (let i =0; i <this.notess.length; i++){
          this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                          "Noms & Prénoms":"------------",
                                          "Notes":this.notess[i].note,
                                          "matière":this.notess[i].nom_matiere,
                                          "classe":this.notess[i].id_classe,
                                          "Compétence":this.notess[i].competence,
                                          "Eval":this.notess[i].num_sq,}));     
                                        
                                          
  
        }
        this.exportexcel(this.notevv,"toute_les_notes"+".xlsx");
        });

  }else {

   this.http.post<any>(this.url.getnoteallclasse,
    JSON.stringify({  
      classe:  this.idclassevv ,
      eval:this.choixevaltoutt,
     })).subscribe(data => {
      this. notevv = [];
  
      this.notess = JSON.parse(JSON.stringify(data)); 
      for (let i =0; i <this.notess.length; i++){
        this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                        "Noms & Prénoms":"------------",
                                        "Notes":this.notess[i].note,
                                        "matière":this.notess[i].nom_matiere,
                                        "classe":this.notess[i].id_classe,
                                        "Compétence":this.notess[i].competence,
                                        "Eval":this.notess[i].num_sq,}));     
                                      
                                        

      }
      this.exportexcel(this.notevv,"toute_les_notes"+".xlsx");
      });

  }
}else {
  if(this.idclassevv=="Toutes les classes"){
    this.http.post<any>(this.url.getnoteallniveau,
      JSON.stringify({  
        niveau:  this.idniveau,
        eval:this.choixevaltoutt,
       })).subscribe(data => {
        this. notevv = [];
    
        this.notess = JSON.parse(JSON.stringify(data)); 
        for (let i =0; i <this.notess.length; i++){
          this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                          "Noms & Prénoms":"------------",
                                          "Notes":this.notess[i].note,
                                          "matière":this.notess[i].nom_matiere,
                                          "classe":this.notess[i].id_classe,
                                          "Compétence":this.notess[i].competence,
                                          "Eval":this.notess[i].num_sq,}));     
                                        
                                          
  
        }
        this.exportexcel(this.notevv,"toute_les_notes"+".xlsx");
        });
  }else {
    this.http.post<any>(this.url.getnoteallclasse,
      JSON.stringify({  
        classe:  this.idclassevv ,
        eval:this.choixevaltoutt,
       })).subscribe(data => {
        this. notevv = [];
    
        this.notess = JSON.parse(JSON.stringify(data)); 
        for (let i =0; i <this.notess.length; i++){
          this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                          "Noms & Prénoms":"------------",
                                          "Notes":this.notess[i].note,
                                          "matière":this.notess[i].nom_matiere,
                                          "classe":this.notess[i].id_classe,
                                          "Compétence":this.notess[i].competence,
                                          "Eval":this.notess[i].num_sq,}));     
                                        
                                          
  
        }
        this.exportexcel(this.notevv,"toute_les_notes"+".xlsx");
        });
  }
}


}





  }
  exporter_nouvel_vagueL() {

    
    this.http.post<any>(this.url.getnoteall,
      JSON.stringify({  
        vague:"0",
        eval:this.evall,
       })).subscribe(data => {
  
        if(data.length==0){
  
        }else{
          this. notevv = [];
  
          this.notess = JSON.parse(JSON.stringify(data)); 
          for (let i =0; i <this.notess.length; i++){
            this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                            "Noms & Prénoms":this.notess[i].nom+" "+this.notess[i].prenom,
                                            "Notes":this.notess[i].note,
                                            "matière":this.notess[i].nom_matiere,
                                            "classe":this.notess[i].id_classe,
                                            "Compétence":this.notess[i].competence,
                                            "Eval":this.notess[i].num_sq,}));     
                                            
  
          }
            
          this.exportexcel(this.notevv,"Eval"+this.evall+"_"+"Vague_nomexporte"+".xlsx");

        }
        });
  
    
    
  
  }
  exporter_encienne_vagueL() {



    console.log("--------------------"+this.vagueAncienne);

    this.http.post<any>(this.url.getnoteallunevague,
      JSON.stringify({  
        vague:this.vagueAncienne,
       })).subscribe(data => {
  
        if(data.length==0){
  
        }else{
          this. notevv = [];
  
          this.notess = JSON.parse(JSON.stringify(data)); 
          for (let i =0; i <this.notess.length; i++){
            this.notevv.push(Object.assign({"Matricule":this.notess[i].matricule,
                                            "Noms & Prénoms":this.notess[i].nom+" "+this.notess[i].prenom,
                                            "Notes":this.notess[i].note,
                                            "matière":this.notess[i].nom_matiere,
                                            "classe":this.notess[i].id_classe,
                                            "Compétence":this.notess[i].competence,
                                            "Eval":this.notess[i].num_sq,}));     
                                            
  
          }
            
          this.exportexcel(this.notevv,""+this.vagueAncienne+".xlsx");
       //   this.showSuccess();
        }
        });
  
    
    
  
  }

  }
  