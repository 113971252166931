import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-enregistrernotespropre',
  templateUrl: './enregistrernotespropre.component.html',
  styleUrls: ['./enregistrernotespropre.component.css']
})
export class EnregistrernotespropreComponent implements OnInit {

  Notes : any = [];
  Notes2 : any = [];
  idclasse: String ="";
  //eval: String ="";
  //idmatire: String ="";
 nommatire: String ="";
 competencevv: String ="";
  public matierenom: any;
  public alleleve: any;

  ligneelevepasnom : any = [];
  eval: String ="";
  evall1: String ="";
  evall2: String ="";
  noteligne1: String ="";
  noteligne2: String ="";
  nomprenomligne: String ="";
  //texteelevenotepasliste: String ="";
  evalls1: String ="";
  evalls2: String ="";
   booleval=false;
  constructor(private _Activatedroute: ActivatedRoute,
    private http: HttpClient,private url:UrlsService, private router: Router,
    private toastr: ToastrService ) { 


    }

    showSuccess() {
      this.toastr.success('Success', 'Notes enregistrées avec succès!', {timeOut: 5000,});
    }
    showSuccessnote() {
      this.toastr.error('error', "La Notes saisie doit être compris entre 0 et 20 et doit être un nombre !", {timeOut: 5000,});
    }
    showSuccessnotes() {
      this.toastr.error('error', "Notes non enregistrée toutes les notes ne sont pas des nombres !", {timeOut: 5000,});
    }
  ngOnInit(): void {

    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

    

   this.idclasse= localStorage.getItem('idclasse');
   this.eval= localStorage.getItem('eval');
   if(localStorage.getItem('eval')=="Trimestre 1"){
    this.evall1="Eval 1"
    this.evall2="Eval 2"
    this.evalls1="1"
    this.evalls2="2"
    this.booleval=false;
   }else if(localStorage.getItem('eval')=="Trimestre 2"){
    this.evall1="Eval 3"
    this.evall2="Eval 4"
    this.evalls1="3"
    this.evalls2="4"
    this.booleval=false;
   }else if(localStorage.getItem('eval')=="Trimestre 3"){
    this.evall1="Eval 5"
    this.evall2="Eval 6"
    this.evalls1="5"
    this.evalls2="6"
    this.booleval=false;
   }else if(localStorage.getItem('eval')=="1"){
    this.evall1="Eval 1";
    this.evalls1="1"
    this.booleval=true;
   }else if(localStorage.getItem('eval')=="2"){
    this.evall1="Eval 2";
    this.evalls1="2"
    this.booleval=true;
   }else if(localStorage.getItem('eval')=="3"){
    this.evall1="Eval 3";
    this.evalls1="3"
    this.booleval=true;
   }else if(localStorage.getItem('eval')=="4"){
    this.evall1="Eval 4";
    this.evalls1="4"
    this.booleval=true;
   }else if(localStorage.getItem('eval')=="5"){
    this.evall1="Eval 5";
    this.evalls1="5"
    this.booleval=true;
   }else if(localStorage.getItem('eval')=="6"){
    this.evall1="Eval 6";
    this.evalls1="6"
    this.booleval=true;
   }

   this.elevebyclasse(this.idclasse).subscribe(
    data => {
      this.alleleve = JSON.parse(JSON.stringify(data)); 

    },
      );


    this._Activatedroute.paramMap.subscribe(params => { 
     // this.idmatire = (params.get('id')); 
      this.nommatire=params.get('id');

    /*  this.recuptype_matiere_nom(this.idmatire).subscribe(
        data => {
          this.matierenom = JSON.parse(JSON.stringify(data)); 
          console.log(this.matierenom[0]["nomMatiere"]);
          this.nommatire=this.matierenom[0]["nomMatiere"];
        },
          );
   */

     });
  }
  recuptype_matiere_nom(id: String) {
    return this.http
    .get<Eleve[]>(this.url.matirebyidmatirer+id)
  }
  elevebyclasse(idclasse: String) {
    return this.http
    .get<Eleve[]>(this.url.elevebyclasse+idclasse)
  }








  getclosev():void{

  }



  Enregistrervv():void{
    // for(let n in this.values){
      console.log(this.Notes);



     let non=0;
    for (let j =0; j <this.Notes.length; j++){
      
    if(this.verif_note_isnumber(this.Notes[j].note)){
      
     }else{
      non=1;
      break;
     }
   }
   for (let j =0; j <this.Notes2.length; j++){
      
    if(this.verif_note_isnumber(this.Notes2[j].note)){
      
     }else{
      non=1;
      break;
     }
   }

   

if(non==1){
  this.showSuccessnotes() ;

}else{



    let chaineNotes="";
 
       for (let i =0; i <this.alleleve.length; i++){
         let h =0;
         var notenote1 ="0";
         for (let j =0; j <this.Notes.length; j++){
 
        if(this.alleleve[i]["matricule"]==this.Notes[j].mat){
         h =1;
         notenote1 =this.Notes[j].note;
         break;
            }
            }
     if( h ==1){











   let bonnenote="";
      let rr=0;
      let pos=0;
      for (let iff = 0; iff <notenote1.toString().length; iff++)
  {
        
        if(notenote1.toString().charAt(iff)=='.'){
           rr=1;
           pos=iff;
           break;
        }
  }
      if(rr==1){
        //il y a le point
        
        if(notenote1.toString().substring(0, pos).length==2){

          
          if(notenote1.toString().substring(pos+1, notenote1.toString().length ).length==2){
            bonnenote=notenote1;
          }else if(notenote1.toString().substring(pos+1, notenote1.toString().length ).length==1){
            bonnenote=notenote1+"0";
          }else if(notenote1.toString().substring(pos+1, notenote1.toString().length ).length==0){
            bonnenote=notenote1+"00";
          }else {
            bonnenote=notenote1.toString().substring(0, 5);
          }
          
          
          
          
          
        }else {
          if(notenote1.toString().substring(pos+1, notenote1.toString().length ).length==2){
            bonnenote="0"+notenote1;
          }else if(notenote1.toString().substring(pos+1, notenote1.toString().length ).length==1){
            bonnenote="0"+notenote1+"0";
          }else if(notenote1.toString().substring(pos+1, notenote1.toString().length ).length==0){
            bonnenote="0"+notenote1+"00";
          }else {
            bonnenote="0"+notenote1.toString().substring(0, 5);
          }
          
        }
        
        
        
        
      }else{
        //il n'y a pas le point
        if(Number(notenote1)<10){
          if(notenote1.toString().charAt(0)=='0'){
            bonnenote=notenote1+".00";
          }else{
            bonnenote="0"+notenote1+".00";
          }
            
          }else{
            
            if(notenote1=="-"){
              bonnenote="-";
            }else{
            bonnenote=notenote1+".00";
          }
          }
      }
      




       chaineNotes=chaineNotes+this.alleleve[i]["matricule"]+":"+bonnenote+";";
      }else{
       chaineNotes=chaineNotes+this.alleleve[i]["matricule"]+":"+"00.00"+";";
     }
 
 
   }






















   let ch=chaineNotes.substring(0,chaineNotes.length-1);

   let chaineNotes2="";
 
   for (let i =0; i <this.alleleve.length; i++){
     let h =0;
     var notenote2 ="0";
     for (let j =0; j <this.Notes2.length; j++){

    if(this.alleleve[i]["matricule"]==this.Notes2[j].mat){
     h =1;
     notenote2 =this.Notes2[j].note;
     break;
   }
 }
 if( h ==1){

  let bonnenote="";
  let rr=0;
  let pos=0;
  for (let iff = 0; iff <notenote2.toString().length; iff++)
{
    
    if(notenote2.toString().charAt(iff)=='.'){
       rr=1;
       pos=iff;
       break;
    }
}
  if(rr==1){
    //il y a le point
    
    if(notenote2.toString().substring(0, pos).length==2){

      
      if(notenote2.toString().substring(pos+1, notenote2.toString().length ).length==2){
        bonnenote=notenote2;
      }else if(notenote2.toString().substring(pos+1, notenote2.toString().length ).length==1){
        bonnenote=notenote2+"0";
      }else if(notenote2.toString().substring(pos+1, notenote2.toString().length ).length==0){
        bonnenote=notenote2+"00";
      }else {
        bonnenote=notenote2.toString().substring(0, 5);
      }
      
      
      
      
      
    }else {
      if(notenote2.toString().substring(pos+1, notenote2.toString().length ).length==2){
        bonnenote="0"+notenote2;
      }else if(notenote2.toString().substring(pos+1, notenote2.toString().length ).length==1){
        bonnenote="0"+notenote2+"0";
      }else if(notenote2.toString().substring(pos+1, notenote2.toString().length ).length==0){
        bonnenote="0"+notenote2+"00";
      }else {
        bonnenote="0"+notenote2.toString().substring(0, 5);
      }
      
    }
    
    
    
    
  }else{
    //il n'y a pas le point
    if(Number(notenote2)<10){
      if(notenote2.toString().charAt(0)=='0'){
        bonnenote=notenote2+".00";
      }else{
        bonnenote="0"+notenote2+".00";
      }
        
      }else{
        if(notenote2=="-"){
          bonnenote="-";
        }else{
        bonnenote=notenote2+".00";
      }
      }
  }
  
   chaineNotes2=chaineNotes2+this.alleleve[i]["matricule"]+":"+bonnenote+";";
  }else{
   chaineNotes2=chaineNotes2+this.alleleve[i]["matricule"]+":"+"00.00"+";";
 }


}






















let ch2=chaineNotes2.substring(0,chaineNotes2.length-1);
console.log(ch);
console.log(ch2);
console.log(this.evalls1);
console.log(this.evalls2);

if(localStorage.getItem('eval')=="Trimestre 1"||localStorage.getItem('eval')=="Trimestre 2"||localStorage.getItem('eval')=="Trimestre 3"){



  let chaineNotes2uuu="";
  for (let iff = 0; iff <this.ligneelevepasnom.length; iff++)
  {
      
    chaineNotes2uuu=chaineNotes2uuu+this.ligneelevepasnom[iff]["nom"]+":"+this.ligneelevepasnom[iff]["note1"]+":"+this.ligneelevepasnom[iff]["note2"]+";";
  }
  

if(chaineNotes2uuu.length>1){
  let ccf=chaineNotes2uuu.substring(0,chaineNotes2uuu.length-1);
  this.http.post<any>(this.url.add_addnotes,
    JSON.stringify({  
      note: ch,
      note2: ch2,
      idclasse:  this.idclasse,
      nommatiere: this.nommatire,
      evaluation:this.evalls1,
      evaluation1:this.evalls2,
      compet:this.competencevv,
      noteelevepasliste:ccf
     })).subscribe(data => {
      console.log(data);
    //  Swal.fire("Enregistrement effectué avec succèe.");
    //  window.location.reload();
    this.router.navigate(['Enregistrernotes']);
     this.showSuccess() ;
      });
}else{

  this.http.post<any>(this.url.add_addnotes,
    JSON.stringify({  
      note: ch,
      note2: ch2,
      idclasse:  this.idclasse,
      nommatiere: this.nommatire,
      evaluation:this.evalls1,
      evaluation1:this.evalls2,
      compet:this.competencevv,
     })).subscribe(data => {
      console.log(data);
    //  Swal.fire("Enregistrement effectué avec succèe.");
    //  window.location.reload();
    this.router.navigate(['Enregistrernotes']);
     this.showSuccess() ;
      });
}

}else{
  let chaineNotes2uuu="";
  for (let iff = 0; iff <this.ligneelevepasnom.length; iff++)
  {
      
    chaineNotes2uuu=chaineNotes2uuu+this.ligneelevepasnom[iff]["nom"]+":"+this.ligneelevepasnom[iff]["note1"]+";";
  }


if(chaineNotes2uuu.length>1){
  let ccf=chaineNotes2uuu.substring(0,chaineNotes2uuu.length-1);
  this.http.post<any>(this.url.add_addnotes,
    JSON.stringify({  
      note: ch,
      idclasse:  this.idclasse,
      nommatiere: this.nommatire,
      evaluation:this.evalls1,
      compet:this.competencevv,
      noteelevepasliste:ccf
     })).subscribe(data => {
      console.log(data);
    //  Swal.fire("Enregistrement effectué avec succèe.");
    //  window.location.reload();
     this.router.navigate(['Enregistrernotes']);
     this.showSuccess() ;
      });
}else{
  this.http.post<any>(this.url.add_addnotes,
    JSON.stringify({  
      note: ch,
      idclasse:  this.idclasse,
      nommatiere: this.nommatire,
      evaluation:this.evalls1,
      compet:this.competencevv
     })).subscribe(data => {
      console.log(data);
    //  Swal.fire("Enregistrement effectué avec succèe.");
    //  window.location.reload();
     this.router.navigate(['Enregistrernotes']);
     this.showSuccess() ;
      });
}

}

}
   }
   valChangecompe(e:Event):void{
    this.competencevv = (e.target as HTMLInputElement).value


  }
  /*valChangeautrenom(e:Event):void{
    this.texteelevenotepasliste = (e.target as HTMLInputElement).value
    console.log("*****************************"+this.texteelevenotepasliste);
  }*/


  valChange(e:Event, index:string):void{
    let v = (e.target as HTMLInputElement).value
    if(this.Notes.length==0){
     

   if(this.verif_note_isnumber(v)){
      this.Notes.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
      }else{
        let indexxx =0;
        let vv =0;
    for (let i =0; i <this.Notes.length; i++){
     // console.log(this.Notes[i].mat);

    if(this.Notes[i].mat==index){
     
      indexxx =i;
      vv =1;
      break;
    }
    }
    
    if(vv ==1){
      if(this.verif_note_isnumber(v)){
      this.Notes[indexxx].note=v;
    }else{

      this.showSuccessnote() ;
    }
     }else{
      if(this.verif_note_isnumber(v)){
      this.Notes.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
    }


  }

  console.log(this.Notes);

  

  }
  valChange2(e:Event, index:string):void{
    let v = (e.target as HTMLInputElement).value
    if(this.Notes2.length==0){
     

   if(this.verif_note_isnumber(v)){
      this.Notes2.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }



      }else{
        let indexxx =0;
        let vv =0;
    for (let i =0; i <this.Notes2.length; i++){
     // console.log(this.Notes[i].mat);

    if(this.Notes2[i].mat==index){
     
      indexxx =i;
      vv =1;
      break;
    }
    }
    
    if(vv ==1){
      if(this.verif_note_isnumber(v)){
      this.Notes2[indexxx].note=v;
    }else{

      this.showSuccessnote() ;
    }
     }else{
      if(this.verif_note_isnumber(v)){
      this.Notes2.push(Object.assign({mat:index, note:v}));
    }else{

      this.showSuccessnote() ;
    }
    }


  }

  console.log(this.Notes2);

  

  }





valChangenomprenom(e:Event):void{
  let v = (e.target as HTMLInputElement).value

  this.nomprenomligne=v;

  }
  valChangeeval1(e:Event):void{
    let v = (e.target as HTMLInputElement).value
    this.noteligne1 =v;
    }
    valChangeeval2(e:Event):void{
      let v = (e.target as HTMLInputElement).value
      this.noteligne2 =v;
      }

  
      deleteligne(inCartProductId: String){
        console.log("----------------------"+inCartProductId);
        const product = this.ligneelevepasnom.find(p => {
          return p.nom === inCartProductId;
        });

        if(product){
          this.ligneelevepasnom.splice(this.ligneelevepasnom.map(p => p.nom).indexOf(inCartProductId), 1);
        }
   
      }
      nouvelleligne():void{


        if(this.booleval==false){
          this.ligneelevepasnom.push({ 
            "nom": this.nomprenomligne,
            "note1": this.noteligne1,
            "note2": this.noteligne2,
          });
        }else{
          this.ligneelevepasnom.push({ 
            "nom": this.nomprenomligne,
            "note1": this.noteligne1,
  
          });
        }

        
     //   this.ligneelevepasnom.push(Object.assign({mat:"index1"}));
      }   







  verif_note_isnumber(note:String) :boolean{

    let tt:boolean;
   
   
    if( note.length==0){
     tt=false;

     //Swal.fire('La note ne doit pas être vide.');
   }else{
   
   
   
         if(!isNaN(Number(note))){
         
           if(Number(note)>=0 && Number(note)<=20){
             tt=true;
           }else{
             tt=false;
           //  Swal.fire("La note doit être comprise entre 0 et 20.");
              }
   
           }else{
           if(note!="-"){
             tt=false;
           //  Swal.fire("La note saisie n'est pas valide.");
          
         }else{
           tt=true;
         }
           }
       
         }
   
       return tt;
     }










}
