import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-consulter-absence',
  templateUrl: './consulter-absence.component.html',
  styleUrls: ['./consulter-absence.component.css']
})
export class ConsulterAbsenceComponent implements OnInit {


  typeuser: String="";
  listeclasse : any = [];
  listeclasseyyyyyyyy : any = [];


  Notes : any = [];
  Notes2 : any = [];

  ANJ : any = [];
  AJ : any = [];
  Rds : any = [];
  Cos : any = [];

  idclasse: String ="";
  //eval: String ="";
  //idmatire: String ="";
 nommatire: String ="";
 competencevv: String ="";
  public matierenom: any;
  public alleleve: any;
  evall: String="1";
  ligneelevepasnom : any = [];
  eval: String ="";
  evall1: String ="";
  evall2: String ="";
  noteligne1: String ="";
  noteligne2: String ="";
  nomprenomligne: String ="";
  //texteelevenotepasliste: String ="";
  evalls1: String ="";
  evalls2: String ="";
   booleval=false;
   public niveauvv: any;
public classevv: any;
   evaltab = ["1","2","3","4","5","6"];
  constructor(private _Activatedroute: ActivatedRoute,
    private http: HttpClient,private url:UrlsService, private router: Router,
    private toastr: ToastrService ) { 


    }
    showSuccessnotedd() {
      this.toastr.error('error', "les absences de cette classe n'existent pas !", {timeOut: 5000,});
    }
    showSuccess() {
      this.toastr.success('Success', 'Disciplines enregistrées avec succès!', {timeOut: 5000,});
    }
    showSuccessnote() {
      this.toastr.error('error', "La valeur saisie doit être un nombre !", {timeOut: 5000,});
    }
    showSuccessnotes() {
      this.toastr.error('error', "Enregistrement impossible toutes les valeurs ne sont pas des nombres !", {timeOut: 5000,});
    }
  ngOnInit(): void {


  
    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

    

    this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
        this.typeuser=localStorage.getItem('typeuser');
            if(this.typeuser==='SG' || this.typeuser==='Censeurs'){

              this.http.post<any>(this.url.classebyuser,
                JSON.stringify({  
                  user:  localStorage.getItem('login'),
            
                 })).subscribe(data => {
            
            
            
                  console.log(data);
                  this.listeclasseyyyyyyyy = [];
                  this.listeclasse = [];
                  this.listeclasse = JSON.parse(JSON.stringify(data)); 
                  for (let i =0; i <this.listeclasse.length; i++){
                    this.listeclasseyyyyyyyy.push(Object.assign({"idClasse":this.listeclasse[i].classe,
                                                    "loguin":this.listeclasse[i].matricule})); 
                  }
                  this.classevv = JSON.parse(JSON.stringify( this.listeclasseyyyyyyyy)); 
     
                  });
            }else{
              this.recuptype_all_classe().subscribe(
                data => {
                  this.classevv = JSON.parse(JSON.stringify(data)); 
                 // console.log(this.elevevv);
                },
                  );
                
                    }




  }








  choixniveau(e: any) {
  
    console.log("----------------------"+e.target.value);
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  }


  choixclasse(e: any) {
    this.idclasse=e.target.value;
    this.alleleve = [];



        this.http.post<any>(this.url.allabsence,
          JSON.stringify({  
            eval:this.evall,
            idclasse:this.idclasse,
           })).subscribe(data => {
            this.alleleve = JSON.parse(JSON.stringify(data)); 
               if(this.alleleve.length>0){


               }else{
                this.showSuccessnotedd();

               }

            });

  }

  choixeval(e: any) {
    this.evall=e.target.value;


    this.alleleve = [];



        this.http.post<any>(this.url.allabsence,
          JSON.stringify({  
            eval:this.evall,
            idclasse:this.idclasse,
           })).subscribe(data => {
            this.alleleve = JSON.parse(JSON.stringify(data)); 
               if(this.alleleve.length>0){


               }else{
                this.showSuccessnotedd();

               }

            });


  }


  recuptype_niveau() {
    return this.http
    .get<Eleve[]>(this.url.all_niveaux)
  }
  recuptype_all_classe() {
    return this.http
    .get<Eleve[]>(this.url.all_classse)
  }

    recuptype_classe_niveau(niv: String) {
    return this.http
    .get<Eleve[]>(this.url.classebyniveau+niv)
  }

  elevebyclasse(idclasse: String) {
    return this.http
    .get<Eleve[]>(this.url.elevebyclasse+idclasse)
  }



}
