<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">ENREGISTRER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>ENREGISTRER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




<section class="section-padding shop-details">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="mb-md-80">


                    <div class="shop-detail-slider mb-xl-30">
                        <div class="slide-item animate-img">
                            <a href="#">
                                <img src="assets/images/shop-lg-1.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="slide-item animate-img">
                            <a href="#">
                                <img src="assets/images/shop-lg-1.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="slide-item animate-img">
                            <a href="#">
                                <img src="assets/images/shop-lg-1.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                        <div class="slide-item animate-img">
                            <a href="#">
                                <img src="assets/images/shop-lg-1.jpg" class="image-fit" alt="">
                            </a>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-7">
                <div class="shop-right-side">
                    <!-- shop title -->
                    <div class="top-head">
                        <p class="double-lines text-orange fw-500 mb-xl-10"><span>Maxi Dress</span></p>
                        <h3 class="fw-700 title"><a href="#">Good Deeds Royal Blue Furniture</a></h3>
                    </div>
                    <!-- Shop pricing -->
                    <div class="shop-pricing-sec">
                        <div class="price">
                            <ins><i class="fas fa-pound-sign"></i> 245.00</ins>
                            <del><i class="fas fa-pound-sign"></i> 399.99</del>
                        </div>
                        <!-- stars -->
                        <div class="stars-review">
                            <div class="stars">
                                <span class="active"><i class="fas fa-star"></i></span>
                                <span class="active"><i class="fas fa-star"></i></span>
                                <span class="active"><i class="fas fa-star"></i></span>
                                <span class="active"><i class="fas fa-star"></i></span>
                                <span class="active"><i class="fas fa-star"></i></span>
                            </div>
                            <div class="reviews">
                                (25 Customer review)
                            </div>
                        </div>
                    </div>
                    <!-- Shop description -->
                    <p class="fw-500">Sweet as can be, the darling Lucy Love Sophia Rust Red Embroidered Dress is here to brighten your day! Slender straps support the apron neckline of this gauzy, woven dress bedecked in floral embroidery. Sheath silhouette ends at a mini hem.  Sleek, polished, and boasting an impeccably modern fit, this blue, 2-button Lazio suit features a notch lapel, flap pockets, and accompanying flat front trousers—all in pure wool by Vitale Barberis Canonico.</p>
                    <!-- shop meta -->
                    <ul class="custom shop-meta">
                        <li><strong>Availability:</strong> In stock</li>
                        <li><strong>Product Code:</strong> #4657</li>
                        <li><strong>Tags:</strong> Fashion, Hood, Classic</li>
                    </ul>
                    <!-- Shop content -->
                 
                 
                 
                 
                 
                 
                 

                </div>
            </div>
        </div>
    </div>
</section>

<!-- End Skillset -->
<app-footer-one></app-footer-one>































