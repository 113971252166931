
import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-detailmessage',
  templateUrl: './detailmessage.component.html',
  styleUrls: ['./detailmessage.component.scss']
})
export class DetailmessageComponent implements OnInit {
  public message: any;
  public elevevv: any;

  constructor(private _Activatedroute: ActivatedRoute,
    private http: HttpClient,private url:UrlsService, private router: Router) {




     }

  ngOnInit(): void {

  /*
    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG"||localStorage.getItem('typeuser')=="Personnels de l'établissement"  ){

      }else{
        this.router.navigate(['accueil']);
      }
    }else{
      this.router.navigate(['']);
    }
*/
    this._Activatedroute.paramMap.subscribe(params => { 

       console.log(params.get('id'));
       this.recuptype_message_id(params.get('id')).subscribe(
        data => {
          this.message = JSON.parse(JSON.stringify(data)); 
        },
          );



          this.http.post<any>(this.url.listeelevemessage,
            JSON.stringify({  
              idmessage: params.get('id'),
             })).subscribe(data => {
              console.log(data);
              this.elevevv  = JSON.parse(JSON.stringify(data)); 


              }); 
      });
  }



  recuptype_message_id(id: String) {
    return this.http
    .get<Eleve[]>(this.url.detailmessage+id)
  }

  

}
