<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">ENREGISTRER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>ENREGISTRER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




<div class="row">



    <div *ngFor="let ur of message" class="col-lg-4">
        <div class="shop-grid mb-xl-30">
            <div class="shop-image animate-img">
                <a routerLink='/shop-detail'>
                    <img src="assets/images/shop-1.jpg" class="image-fit" alt="">
                </a>
                <div class="shop-overlay">
                    <div class="shop-action">
                        <button class="custom-tooltip" data-tip="Quick View">
                            <i class="fal fa-eye"></i>
                        </button>
                        <button class="custom-tooltip" data-tip="Add to wishlist">
                            <i class="fal fa-heart"></i>
                        </button>
                        <button class="custom-tooltip" data-tip="Add to cart">
                            <i class="fal fa-shopping-cart"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="shop-caption">
                <h5><a routerLink='/shop-detail'>{{ur.titre}}</a></h5>
                <div class="shop-price">
                    <h6><a routerLink='/shop-detail'>{{ur.id}} | {{ur.date}} </a></h6>
                </div>
            </div>
        </div>
    </div>





</div>



<!-- End Skillset -->
<app-footer-one></app-footer-one>































