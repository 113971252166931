import { Component, OnInit } from '@angular/core';

import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UrlsService } from '../../../services/urls.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-classesanseleve',
  templateUrl: './classesanseleve.component.html',
  styleUrls: ['./classesanseleve.component.css']
})
export class ClassesanseleveComponent implements OnInit {







  selectedFile: File
  chemainfile='';

  elevevv = [];
  public items_eleve= [];
  public items1: any;
  banner : any = {
		
		pagetitle: "Importation des éléments de configuration",
		bg_image: "assets/images/etablissement/fon111.png",
		title: "Importation des éléments de configuration",
	}
  public allNote;
  constructor(private http: HttpClient,private url:UrlsService,
    private router: Router,private toastr: ToastrService
    ) { }

  showSuccess() {
    this.toastr.success('Success', 'Opération effectuée avec succès!', {timeOut: 3000,});
  }
  supprimer():void{

    
    this.http.post<any>(this.url.deleteclassesanseleve,
      JSON.stringify({  
        datay:"tete",
       })).subscribe(data => {
       this.showSuccess() ;
        });

  }

  ngOnInit(): void {
    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }
  }

}
