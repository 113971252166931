<!-- Start Header -->
<header class="header">
    <!-- topbar -->
    <div class="topbar bg-dark-blue">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-sm-6">
                    <ul class="custom-flex left-side">
                        <li>
                            <select class="custom-select form-control form-control-custom mb-0">
                                <option>French</option>
                                <option>English</option>
                                
                            </select>
                        </li>
                        
                    </ul>
                </div>
                <div class="col-lg-8 col-sm-6">
                    <div class="right-side">
                        <p class="no-margin text-custom-white">Bienvenue dans notre établissement</p>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- middle sec -->
    <div class="middle-head bg-light-white">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-6">
                    <div class="logo">
                        <a routerLink='/'>
                            <img src="assets/etablissement/logovv.png" >
                        </a>
                    </div>
                </div>
                <div class="col-lg-9 col-6">
                    <div class="right-side">
                        <ul class="custom-flex">
                            <li>
                                <a href="#" class="text-blue">
                                    <i class="fal fa-phone text-orange"></i>
                                    +000 000 00 00 00
                                </a>
                            </li>
                        </ul>
                       
                    </div>
                    <div class="hamburger-btn">
                        <div class="menu-btn">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- navigation -->
    <div class="navigation">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="main-nav">
                        <nav>
                            <ul class="custom-flex main-menu">




                                
                     


                                <li class="menu-item">
                                    <a routerLink='/' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">ACCUEIL</a>
                                </li>



                                <li class="menu-item menu-item-has-children" >
                                    <a href="#">Notes</a>
                                    <ul class="custom sub-menu">
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant'  || typeuser==='Notes'">
                                            <a routerLink='/Enregistrernotes' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enregistrer les notes</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant'  || typeuser==='Notes' || typeuser=='Censeurs'">
                                            <a routerLink='/Consulternotes' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Consulter les notes</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant'  || typeuser==='Notes'">
                                            <a routerLink='/Modifiernotesv' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modifier les notes</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' ">
                                            <a routerLink='/Exporternotes' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Exporter les notes excel</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant'|| typeuser=='Censeurs' ">
                                            <a routerLink='/ExportPV' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Exporter le PV des notes</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant'  || typeuser==='Notes'">
                                            <a routerLink='/ImprimBordereauNote' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Imprimer le bordereau de notes</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant'  || typeuser==='Notes'">
                                            <a routerLink='/Supimernote' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Supprimer les notes</a>
                                        </li>
                                    </ul>
                                </li>


                                <li class="menu-item menu-item-has-children" >
                                    <a href="#">Discipline</a>
                                    <ul class="custom sub-menu">
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        || typeuser=='SG'">
                                            <a routerLink='/EnregistrerAbsence' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enregistrer les absences</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        || typeuser=='SG' || typeuser=='Censeurs'">
                                            <a routerLink='/ConsulterAbsence' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Consulter les absences</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        || typeuser=='SG'">
                                            <a routerLink='/ModifAbsence' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modifier les absences</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        || typeuser=='SG'">
                                            <a routerLink='/EnregistrerCD' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enregistrer CD</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        || typeuser=='SG' || typeuser=='Censeurs'">
                                            <a routerLink='/ConsulterCD' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Consulter CD</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        || typeuser=='SG'">
                                            <a routerLink='/ModifierCD' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modifier CD</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        ">
                                            <a routerLink='/Expoerterabs' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Exporter les absences</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                        ">
                                            <a routerLink='/Expoertercd' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Exporter les CD</a>
                                        </li>
                                    </ul>
                                </li>



                                <li class="menu-item menu-item-has-children" *ngIf="typeuser=='Administrateur' || typeuser=='Super-administrateur' 
                                || typeuser=='Enseignant'  || typeuser=='Censeurs'
                                || typeuser=='SG'">
                                    <a href="#">Messages</a>
                                    <ul class="custom sub-menu">
                                        <li class="menu-item">
                                            <a routerLink='/PublierMessage' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Publier un message</a>
                                        </li>
                                        <li class="menu-item">
                                            <a routerLink='/Listemessage' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tous les messages</a>
                                        </li>
                                    </ul>
                                </li>

                                <li class="menu-item">
                                    <a routerLink='/Dashboardvv' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dachbord</a>
                                </li>


                                <li class="menu-item menu-item-has-children" *ngIf="typeuser==='Super-administrateur'">
                                    <a href="#">Paramètre</a>
                                    <ul class="custom sub-menu">
                                        <li class="menu-item">
                                            <a routerLink='/Importerdonnee' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Importer les données</a>
                                        </li>
                                        <li class="menu-item">
                                            <a routerLink='/Deletedata' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Supprimer les données</a>
                                        </li>
                                        <li class="menu-item">
                                            <a routerLink='/Classesanseleve' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Supprimer classe sans élève</a>
                                        </li>
                                        <li class="menu-item">
                                            <a routerLink='/InfoEtablissement' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enregistrer info établissement</a>
                                        </li>
                                        <li class="menu-item">
                                            <a routerLink='/Bloquertrimestre' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Bloquer/Débloquer un Trimestre</a>
                                        </li>
                                        <li class="menu-item">
                                            <a routerLink='/Remplirtableetatnote' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Remplir la table état  notes</a>
                                        </li>
                                    </ul>
                                </li>

                                

                                <li class="menu-item menu-item-has-children" >
                                    <a href="#">Utilisateur</a>
                                    <ul class="custom sub-menu">
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser==='Super-administrateur'">
                                            <a routerLink='/EnregistrerUser' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enregistrer un utilisateur</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser==='Super-administrateur'">
                                            <a routerLink='/ConsultUser' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Consulter les  utilisateurs</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser==='Super-administrateur'">
                                            <a routerLink='/ModifUser' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modifier un utilisateur</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser==='Super-administrateur'">
                                            <a routerLink='/Modiftypeuser' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Modifier le type d'un utilisateur</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser==='Super-administrateur'">
                                            <a routerLink='/Enseignenttouser' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Enseignants et utilisateur</a>
                                        </li>
                                        <li class="menu-item" *ngIf="typeuser=='Administrateur' || typeuser==='Super-administrateur'">
                                            <a routerLink='/Usersgcenseur' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">utilisateur et classes</a>
                                        </li>
                                        <li class="menu-item">
                                            <a (click)="Deconnexion()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Déconnexion</a>
                                        </li>
                                    </ul>
                                </li>


                                

                                
      
    







                                

                                 <!--<li class="menu-item">
                                    <a routerLink='/about' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">À propos de nous</a>
                                </li>-->






                            </ul>
                        </nav>
                        <div class="nav-right">
                            <ul class="custom-flex">


                                <!--
                                <li class="menu-item menu-item-has-children">
                                    <a href="#"> <img src="../assets/etablissement/LOGOBB3.png"></a>
                                    <ul class="custom sub-menu">
                                        <li class="menu-item">
                                            <a routerLink='' routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mon profile</a>
                                        </li>
                                        <li class="menu-item">
                                            <a (click)="Deconnexion()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Deconnexion</a>
                                        </li>
                                    </ul>
                                </li>-->


                                









                                <li class="search">
                                    <a href="#">
                                        <i class="fal fa-search"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="search-popup" id="search-popup">
            <form action="#" class="search-form">
                <div class="input-group">
                    <input type="text" name="#" class="form-control form-control-custom" placeholder="Search">
                    <div class="input-group-prepend">
                        <button type="submit" class="theme-btn"><i class="fal fa-search"></i></button>
                    </div>
                </div>
            </form>
        </div>
        <div class="body-overlay" id="body-overlay"></div>
    </div>
</header> 
<!-- End Header -->