<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">MODIFIER CD</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>DISCIPLINE</a></li>
                        <li>MODIFIER CD</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




 

<section class="section-padding project-details">
    <div class="container">
        <div class="row">

            <br /><br /><br />
            <div class="col-6">
                <div class="row">

                    <div class="col-lg-2">
                        <h3 *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">Niveau</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">
                            <select selected="" class="form-control"  (change)="choixniveau($event)" >
                                <option    >Tous les niveaux</option>
                                <option *ngFor="let u of niveauvv" [value]="u.nomniveau"    >{{u.nomniveau}}</option>
                              </select> 
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <h3 *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='SG' || typeuser==='Censeurs'">Classe</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='SG' || typeuser==='Censeurs'">
                            <select selected="" class="form-control"  (change)="choixclasse($event)" >
                                <option    >Toutes les classe</option>
                                <option *ngFor="let u of classevv" [value]="u.idClasse"    >{{u.idClasse}}</option>
                              </select> 
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <h3>Eval</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="choixeval($event)" >
                                <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
                              </select> 
                        </div>
                    </div>

                </div>


                <div class="table-responsive">
                    <table class="table check-tbl">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Matricule</th>
                                <th>Noms Prenoms</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
        
        
                            <tr *ngFor="let ur of alleleve" class="alert">
                                <td class="product-item-name">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>
                                <td> <button (click)="eleve(ur.matricule,ur.nom,ur.prenom)" class='btn btn-primary' ><i class="fa fa-pencil"></i></button> </td>
                            </tr>
        
                        </tbody>
                    </table>
                </div>
                
            </div>
            <div class="col-6">
           
    

                    <div class="row">
                    <div class="col-lg-12">
                        <h3 style="font-size:1.5em">{{matricul}} {{nomprenomligne}}</h3>
                    </div>
                      </div>

                    <div class="row">

                        <div class="col-lg-4">
                            <h3>Motif</h3>
                        </div>
                    
                        <div class="col-lg-8">
                            <div class="form-group">
                                <textarea  type="text" [value]="motiff" (change)="Motif($event)" class="form-control" placeholder="Motif">
                                </textarea> 
                            </div>
                        </div>
                
                    </div>

                     <div class="row">

                        <div class="col-lg-4">
                            <h3>Nbre. jour(s)</h3>
                        </div>
                    
                        <div class="col-lg-8">
                            <div class="form-group">
                                <input  type="text"  [value]="nbjours" (change)="Nbrej($event)" class="form-control" placeholder="Nbre. jour(s)">
                            </div>
                        </div>
                
                    </div>

    
    
    
    
            
    
            </div>





            
        </div>
    </div>
</section>




























































<!-- End Skillset -->
<app-footer-one></app-footer-one>