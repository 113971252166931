import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User } from '../../../service/user';
import {Router} from '@angular/router';
import {ActivatedRoute} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-modif-user-propre',
  templateUrl: './modif-user-propre.component.html',
  styleUrls: ['./modif-user-propre.component.scss']
})
export class ModifUserPropreComponent implements OnInit {


  cars = [
    { id: "Super-administrateur", name: "Super-administrateur" },
    { id: "Administrateur", name: "Administrateur" },
    { id: "Enseignant", name: "Enseignant" },
    { id: "Personnels de l'établissement", name: "Personnels de l'établissement" },
    { id: "Parents", name: "Parents" },
    { id: "Eleves", name: "Eleves" },
    { id: "Invites", name: "Invites" },
    { id: "Censeurs", name: "Censeurs" },
    { id: "SG", name: "SG" },
    { id: "Notes", name: "Notes" },

];
sexe = [
  { id: "M", name: "M" },
  { id: "F", name: "F" },
];
typeuser: String="-";
sexegettt: String="M";
iduser: String="";
public uservv: User;
nom="";
prenom="";
tel="";
sexeuser="";
typeuserv="";
loginuser="";
pass="";


ChangeNom: String="";
ChangePrenom: String="";
ChangeTel: String="";
ChangeLogin: String="";
ChangePassword: String="";
  constructor(private _Activatedroute: ActivatedRoute,private router: Router ,private http: HttpClient,private url:UrlsService
    ,private toastr: ToastrService) { 

  }

  ngOnInit(): void {
    /*if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"){

      }else{
        this.router.navigate(['accueil']);
      }
    }else{
      this.router.navigate(['']);
    }*/

    this._Activatedroute.paramMap.subscribe(params => { 
      this.iduser = (params.get('id')); 

    
      this.recuptype_user(this.iduser).subscribe(
        data => {
          this.uservv = JSON.parse(JSON.stringify(data)); 
      
          this.nom=this.uservv?.nomuser;
          this.prenom=this.uservv?.prenomuser;
          this.tel=this.uservv?.phonenumber;
          this.sexeuser=this.uservv?.sexe;
          this.typeuserv=this.uservv?.typeuser;
          this.loginuser=this.uservv?.login;
          this.pass=this.uservv?.password;
          
        
        },
          );


     // console.log("idagent1111-------**"+ this.idagent) ;

     });

  }



  sexeget(e: any) {

    this.sexegettt=e.target.value;
  }


  Vue(e: any) {

    this.typeuser=e.target.value;
  }
  recuptype_user(id:String) {
    return this.http
    .get<User[]>(this.url.detailuser+id)
  }
  valChangeNom(e:Event):void{
    this.ChangeNom = (e.target as HTMLInputElement).value
  }
  valChangePrenom(e:Event):void{
    this.ChangePrenom = (e.target as HTMLInputElement).value
  }
  valChangeTel(e:Event):void{
    this.ChangeTel = (e.target as HTMLInputElement).value
  }
  valChangeLogin(e:Event):void{
    this.ChangeLogin = (e.target as HTMLInputElement).value
  }
  valChangePassword(e:Event):void{
    this.ChangePassword = (e.target as HTMLInputElement).value
  }
  valChangePasswordc(e:Event):void{
    //this.ChangePassword = (e.target as HTMLInputElement).value
  }
  Enregistrer() {



    this.http.post<any>(this.url.updateuser,
      JSON.stringify({  


        Password:this.ChangePassword ,
        Login:this.ChangeLogin,
        Tel:this.ChangeTel,
        Sexe:this.sexegettt,
        Prenom: this.ChangePrenom,
        Nom:this.ChangeNom,
        typeuser:this.typeuser,
        id:this.iduser
       })).subscribe(data => {
      
       
        }); 


}
}
