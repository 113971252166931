import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-consulternotes',
  templateUrl: './consulternotes.component.html',
  styleUrls: ['./consulternotes.component.css']
})
export class ConsulternotesComponent implements OnInit {
  listeclasse : any = [];
  listeclasseyyyyyyyy : any = [];


  classevvd : any = [];
  classevvdf : any = [];
  typeuser: String="";
  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notess: any;
  public matierclasseF: any;
  booleval=false;
  eval1="1";
  eval2="2";
  public notessff: any;
  public notesstrimestre: any;
  //public eleveabsv: any;
  public alleleve: any;
  eleveabs = [];
  public elevebynote: any;
  vuevv: String="Vue par tout le monde";
  evall: String="";
  nommatierevv: String="";
  ifchoixeval: String="eval";
  idclassevv: String="";
  classematierevv: any = [];
  evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];
  
    constructor(private http: HttpClient,private url:UrlsService, private router: Router, private toastr: ToastrService ) { 
  
    }
    showSuccessnotehh() {
      this.toastr.error('Error', "Les notes de cette matière n'existent pas", {timeOut: 5000,});
    }
    ngOnInit(): void {
    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

    this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  

        this.typeuser=localStorage.getItem('typeuser');


            if(this.typeuser==='Enseignant'){


              this.http.post<any>(this.url.getclassebyenseignant,
                JSON.stringify({  
                  anseignant:  localStorage.getItem('login'),
            
                 })).subscribe(data => {
            
            
            
                  console.log(data);
            
                  this. classevvd = [];
                  this. classevvdf = [];
                  this. classevv = [];
                  this.classematierevv = [];
                  this.classevvd = JSON.parse(JSON.stringify(data)); 
                  for (let i =0; i <this.classevvd.length; i++){

                    this.classematierevv.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                    "id_matiere":this.classevvd[i].id_matiere,
                    "matriculE":this.classevvd[i].matriculE,
                    "nom_matiere":this.classevvd[i].nom_matiere})); 
            
                    let vv =0;
                    for (let ig =0; ig <this.classevvdf.length; ig++){
                      if(this.classevvd[i].id_classe==this.classevvdf[ig].idClasse){
                        vv =1;
                        break;
                      }
                    }
                        if( vv ==0){
                    this.classevvdf.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                                                    "id_matiere":this.classevvd[i].id_matiere,
                                                    "matriculE":this.classevvd[i].matriculE,
                                                    "nom_matiere":this.classevvd[i].nom_matiere}));     
                                                    
                                                  }
                  }
                  this.classevv = JSON.parse(JSON.stringify( this.classevvdf)); 
            
            /*
                  window.location.reload();
                 this.router.navigate(['Enregistrernotes']);
            */
            
            
            
                  });
            }else{

              if(this.typeuser==='Censeurs'){

                this.http.post<any>(this.url.classebyuser,
                  JSON.stringify({  
                    user:  localStorage.getItem('login'),
              
                   })).subscribe(data => {
              
              
              
                    console.log(data);
                    this.listeclasseyyyyyyyy = [];
                    this.listeclasse = [];
                    this.listeclasse = JSON.parse(JSON.stringify(data)); 
                    for (let i =0; i <this.listeclasse.length; i++){
                      this.listeclasseyyyyyyyy.push(Object.assign({"idClasse":this.listeclasse[i].classe,
                                                      "loguin":this.listeclasse[i].matricule})); 
                    }
                    this.classevv = JSON.parse(JSON.stringify( this.listeclasseyyyyyyyy)); 
       
                    });
              }else{




              this.recuptype_all_classe().subscribe(
                data => {
                  this.classevv = JSON.parse(JSON.stringify(data)); 
                 // console.log(this.elevevv);
                },
                  );
                    }

                  }








    }
    recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }
  
    recuptype_matiere_classe(classs: String) {
      return this.http
      .get<Eleve[]>(this.url.matierebyclasse+classs)
    }



    choixniveau(e: any) {
    
    
      this.recuptype_classe_niveau(e.target.value).subscribe(
        data => {
          this.classevv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );
    }
  
  
    choixclasse(e: any) {


      if(this.typeuser==='Enseignant'){
        this.idclassevv=e.target.value;
        this. matierclasse = [];
        this. matierclasseF = [];
        for (let i =0; i <this.classematierevv.length; i++){
          if(e.target.value==this.classematierevv[i].idClasse){
  
          this.matierclasseF.push(Object.assign({"id_matiere":this.classematierevv[i].id_matiere,
                                          "nom_matiere":this.classematierevv[i].nom_matiere}));     
                                          
                }
        }
        this.matierclasse = JSON.parse(JSON.stringify( this.matierclasseF)); 
  
  
      }else{
        
        this.idclassevv=e.target.value;
        this.recuptype_matiere_classe(e.target.value).subscribe(
          data => {
            this.matierclasse = JSON.parse(JSON.stringify(data)); 
            console.log(this.matierclasse);
          },
            );
        }



     

  
    }
  
    choixeval(e: any) {
     

      this.alleleve = [];
      this.notessff = [];
      this.elevebynote = [];
      this.notess = [];
      this.evall=e.target.value;
      if(this.evall=="Trimestre 1"){
        this.eval1="1";
        this.eval2="2";
        this.booleval=true;
      }else if(this.evall=="Trimestre 2"){
        this.eval1="3";
        this.eval2="4";
        this.booleval=true;
      }else if(this.evall=="Trimestre 3"){
        this.eval1="5";
        this.eval2="6";
        this.booleval=true;
      }else{
      this.booleval=false;
       }
       this.alleleve = [];
       this.notessff = [];
       this.elevebynote = [];
       this.notess = [];
 
 
       if(this.nommatierevv==""||this.nommatierevv=="Matières"){
  
       }else{
         if(this.evall=="Trimestre 1"){
           this.eval1="1";
           this.eval2="2";
          this.elevebyclasse(this.idclassevv).subscribe(
            data => {
              this.alleleve = JSON.parse(JSON.stringify(data)); 
              for (let i =0; i <this.alleleve.length; i++){
                let p=i+1;
                this.elevebynote.push(Object.assign({index: p,mat:this.alleleve[i]["matricule"],
                nom:this.alleleve[i]["nom"]+" "+this.alleleve[i]["prenom"],note1:"",note2:""}));
              }
    
              this.http.post<any>(this.url.getnote,
                JSON.stringify({  
                  idclasse:  this.idclassevv,
                  nommatiere:  this.nommatierevv,
                  evaluation:this.eval1,
                 })).subscribe(data => {
 
                   if(data.length==0){
                     this.showSuccessnotehh();
                   }else{
 
                  this.notessff = JSON.parse(JSON.stringify(data)); 
                  for (let i =0; i <this.elevebynote.length; i++){
                    for (let h =0; h <this.notessff.length; h++){
                    if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                      this.elevebynote[i]["note1"]=this.notessff[h]["note"];
                         }
                        }
                       }
                     }
                  });
              
                  this.notessff = [];
                  this.http.post<any>(this.url.getnote,
                    JSON.stringify({  
                      idclasse:  this.idclassevv,
                      nommatiere:  this.nommatierevv,
                      evaluation:this.eval2,
                     })).subscribe(data => {
 
                       if(data.length==0){
                         this.showSuccessnotehh();
                       }else{
     
 
                      this.notessff = JSON.parse(JSON.stringify(data)); 
                      for (let i =0; i <this.elevebynote.length; i++){
                        for (let h =0; h <this.notessff.length; h++){
                        if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                          this.elevebynote[i]["note2"]=this.notessff[h]["note"];
                             }
                            }
                           }
                         }
                      });
            },
              );
    
  
        }else if(this.evall=="Trimestre 2"){
          this.eval1="3";
          this.eval2="4";
         this.elevebyclasse(this.idclassevv).subscribe(
           data => {
             this.alleleve = JSON.parse(JSON.stringify(data)); 
             for (let i =0; i <this.alleleve.length; i++){
               let p=i+1;
               this.elevebynote.push(Object.assign({index: p,mat:this.alleleve[i]["matricule"],
               nom:this.alleleve[i]["nom"]+" "+this.alleleve[i]["prenom"],note1:"",note2:""}));
             }
   
             this.http.post<any>(this.url.getnote,
               JSON.stringify({  
                 idclasse:  this.idclassevv,
                 nommatiere:  this.nommatierevv,
                 evaluation:this.eval1,
                })).subscribe(data => {
                 if(data.length==0){
                   this.showSuccessnotehh();
                 }else{
 
                 this.notessff = JSON.parse(JSON.stringify(data)); 
                 for (let i =0; i <this.elevebynote.length; i++){
                   for (let h =0; h <this.notessff.length; h++){
                   if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                     this.elevebynote[i]["note1"]=this.notessff[h]["note"];
                        }
                       }
                      }
                     }
                 });
             
                 this.notessff = [];
                 this.http.post<any>(this.url.getnote,
                   JSON.stringify({  
                     idclasse:  this.idclassevv,
                     nommatiere:  this.nommatierevv,
                     evaluation:this.eval2,
                    })).subscribe(data => {
                     if(data.length==0){
                       this.showSuccessnotehh();
                     }else{
                     this.notessff = JSON.parse(JSON.stringify(data)); 
                     for (let i =0; i <this.elevebynote.length; i++){
                       for (let h =0; h <this.notessff.length; h++){
                       if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                         this.elevebynote[i]["note2"]=this.notessff[h]["note"];
                            }
                           }
                          }
                         }
                     });
           },
             );
   
        }else if(this.evall=="Trimestre 3"){
          this.eval1="5";
          this.eval2="6";
         this.elevebyclasse(this.idclassevv).subscribe(
           data => {
             this.alleleve = JSON.parse(JSON.stringify(data)); 
             for (let i =0; i <this.alleleve.length; i++){
               let p=i+1;
               this.elevebynote.push(Object.assign({index: p,mat:this.alleleve[i]["matricule"],
               nom:this.alleleve[i]["nom"]+" "+this.alleleve[i]["prenom"],note1:"",note2:""}));
             }
   
             this.http.post<any>(this.url.getnote,
               JSON.stringify({  
                 idclasse:  this.idclassevv,
                 nommatiere:  this.nommatierevv,
                 evaluation:this.eval1,
                })).subscribe(data => {
                 if(data.length==0){
                   this.showSuccessnotehh();
                 }else{
                 this.notessff = JSON.parse(JSON.stringify(data)); 
                 for (let i =0; i <this.elevebynote.length; i++){
                   for (let h =0; h <this.notessff.length; h++){
                   if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                     this.elevebynote[i]["note1"]=this.notessff[h]["note"];
                        }
                       }
                      }
                     }
                 });
             
                 this.notessff = [];
                 this.http.post<any>(this.url.getnote,
                   JSON.stringify({  
                     idclasse:  this.idclassevv,
                     nommatiere:  this.nommatierevv,
                     evaluation:this.eval2,
                    })).subscribe(data => {
                     if(data.length==0){
                       this.showSuccessnotehh();
                     }else{
                     this.notessff = JSON.parse(JSON.stringify(data)); 
                     for (let i =0; i <this.elevebynote.length; i++){
                       for (let h =0; h <this.notessff.length; h++){
                       if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                         this.elevebynote[i]["note2"]=this.notessff[h]["note"];
                            }
                           }
                          }
                         }
                     });
           },
             );
   
        }else{
        
          this.http.post<any>(this.url.getnote,
            JSON.stringify({  
              idclasse:  this.idclassevv,
              nommatiere:  this.nommatierevv,
              evaluation:this.evall,
             })).subscribe(data => {
             
               if(data.length==0){
                 this.showSuccessnotehh();
               }else{
              if(data.length==0){
                console.log("-----------****-------");
             this.notess = [];
    
              }else{
                console.log("------------------");
                this.notess = [];
                this.notess = JSON.parse(JSON.stringify(data)); 
              }
 
             }
 
              });
        
        }
       }
    }
  
    elevebyclasse(idclasse: String) {
      return this.http
      .get<Eleve[]>(this.url.elevebyclasse+idclasse)
    }
  

    choixmatire(e: any) {


      this.nommatierevv=e.target.value;
      this.alleleve = [];
      this.notessff = [];
      this.elevebynote = [];
      this.notess = [];


      if(this.nommatierevv==""||this.nommatierevv=="Matières"){
 
      }else{
        if(this.evall=="Trimestre 1"){
          this.eval1="1";
          this.eval2="2";
         this.elevebyclasse(this.idclassevv).subscribe(
           data => {
             this.alleleve = JSON.parse(JSON.stringify(data)); 
             for (let i =0; i <this.alleleve.length; i++){
               let p=i+1;
               this.elevebynote.push(Object.assign({index: p,mat:this.alleleve[i]["matricule"],
               nom:this.alleleve[i]["nom"]+" "+this.alleleve[i]["prenom"],note1:"",note2:""}));
             }
   
             this.http.post<any>(this.url.getnote,
               JSON.stringify({  
                 idclasse:  this.idclassevv,
                 nommatiere:  this.nommatierevv,
                 evaluation:this.eval1,
                })).subscribe(data => {

                  if(data.length==0){
                    this.showSuccessnotehh();
                  }else{

                 this.notessff = JSON.parse(JSON.stringify(data)); 
                 for (let i =0; i <this.elevebynote.length; i++){
                   for (let h =0; h <this.notessff.length; h++){
                   if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                     this.elevebynote[i]["note1"]=this.notessff[h]["note"];
                        }
                       }
                      }
                    }
                 });
             
                 this.notessff = [];
                 this.http.post<any>(this.url.getnote,
                   JSON.stringify({  
                     idclasse:  this.idclassevv,
                     nommatiere:  this.nommatierevv,
                     evaluation:this.eval2,
                    })).subscribe(data => {

                      if(data.length==0){
                        this.showSuccessnotehh();
                      }else{
    

                     this.notessff = JSON.parse(JSON.stringify(data)); 
                     for (let i =0; i <this.elevebynote.length; i++){
                       for (let h =0; h <this.notessff.length; h++){
                       if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                         this.elevebynote[i]["note2"]=this.notessff[h]["note"];
                            }
                           }
                          }
                        }
                     });
           },
             );
   
 
       }else if(this.evall=="Trimestre 2"){
         this.eval1="3";
         this.eval2="4";
        this.elevebyclasse(this.idclassevv).subscribe(
          data => {
            this.alleleve = JSON.parse(JSON.stringify(data)); 
            for (let i =0; i <this.alleleve.length; i++){
              let p=i+1;
              this.elevebynote.push(Object.assign({index: p,mat:this.alleleve[i]["matricule"],
              nom:this.alleleve[i]["nom"]+" "+this.alleleve[i]["prenom"],note1:"",note2:""}));
            }
  
            this.http.post<any>(this.url.getnote,
              JSON.stringify({  
                idclasse:  this.idclassevv,
                nommatiere:  this.nommatierevv,
                evaluation:this.eval1,
               })).subscribe(data => {
                if(data.length==0){
                  this.showSuccessnotehh();
                }else{

                this.notessff = JSON.parse(JSON.stringify(data)); 
                for (let i =0; i <this.elevebynote.length; i++){
                  for (let h =0; h <this.notessff.length; h++){
                  if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                    this.elevebynote[i]["note1"]=this.notessff[h]["note"];
                       }
                      }
                     }
                    }
                });
            
                this.notessff = [];
                this.http.post<any>(this.url.getnote,
                  JSON.stringify({  
                    idclasse:  this.idclassevv,
                    nommatiere:  this.nommatierevv,
                    evaluation:this.eval2,
                   })).subscribe(data => {
                    if(data.length==0){
                      this.showSuccessnotehh();
                    }else{
                    this.notessff = JSON.parse(JSON.stringify(data)); 
                    for (let i =0; i <this.elevebynote.length; i++){
                      for (let h =0; h <this.notessff.length; h++){
                      if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                        this.elevebynote[i]["note2"]=this.notessff[h]["note"];
                           }
                          }
                         }
                        }
                    });
          },
            );
  
       }else if(this.evall=="Trimestre 3"){
         this.eval1="5";
         this.eval2="6";
        this.elevebyclasse(this.idclassevv).subscribe(
          data => {
            this.alleleve = JSON.parse(JSON.stringify(data)); 
            for (let i =0; i <this.alleleve.length; i++){
              let p=i+1;
              this.elevebynote.push(Object.assign({index: p,mat:this.alleleve[i]["matricule"],
              nom:this.alleleve[i]["nom"]+" "+this.alleleve[i]["prenom"],note1:"",note2:""}));
            }
  
            this.http.post<any>(this.url.getnote,
              JSON.stringify({  
                idclasse:  this.idclassevv,
                nommatiere:  this.nommatierevv,
                evaluation:this.eval1,
               })).subscribe(data => {
                if(data.length==0){
                  this.showSuccessnotehh();
                }else{
                this.notessff = JSON.parse(JSON.stringify(data)); 
                for (let i =0; i <this.elevebynote.length; i++){
                  for (let h =0; h <this.notessff.length; h++){
                  if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                    this.elevebynote[i]["note1"]=this.notessff[h]["note"];
                       }
                      }
                     }
                    }
                });
            
                this.notessff = [];
                this.http.post<any>(this.url.getnote,
                  JSON.stringify({  
                    idclasse:  this.idclassevv,
                    nommatiere:  this.nommatierevv,
                    evaluation:this.eval2,
                   })).subscribe(data => {
                    if(data.length==0){
                      this.showSuccessnotehh();
                    }else{
                    this.notessff = JSON.parse(JSON.stringify(data)); 
                    for (let i =0; i <this.elevebynote.length; i++){
                      for (let h =0; h <this.notessff.length; h++){
                      if(this.elevebynote[i]["mat"]==this.notessff[h]["matricule"]){
                        this.elevebynote[i]["note2"]=this.notessff[h]["note"];
                           }
                          }
                         }
                        }
                    });
          },
            );
  
       }else{
       
         this.http.post<any>(this.url.getnote,
           JSON.stringify({  
             idclasse:  this.idclassevv,
             nommatiere:  this.nommatierevv,
             evaluation:this.evall,
            })).subscribe(data => {
            
              if(data.length==0){
                this.showSuccessnotehh();
              }else{
             if(data.length==0){
               console.log("-----------****-------");
            this.notess = [];
   
             }else{
               console.log("------------------");
               this.notess = [];
               this.notess = JSON.parse(JSON.stringify(data)); 
             }

            }

             });
       
       }
      }






    


    }
  
  
  
  

  }
  