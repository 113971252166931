<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">MODIFIER LES ABSENCES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>DISCIPLINE</a></li>
                        <li>MODIFIER LES ABSENCES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




 

<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">




                <br /><br /><br />

                <div class="row">

                    <div class="col-lg-2">
                        <h3 *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">Niveau</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">
                            <select selected="" class="form-control"  (change)="choixniveau($event)" >
                                <option    >Tous les niveaux</option>
                                <option *ngFor="let u of niveauvv" [value]="u.nomniveau"    >{{u.nomniveau}}</option>
                              </select> 
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <h3 *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='SG' ">Classe</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='SG' ">
                            <select selected="" class="form-control"  (change)="choixclasse($event)" >
                                <option    >Toutes les classe</option>
                                <option *ngFor="let u of classevv" [value]="u.idClasse"    >{{u.idClasse}}</option>
                              </select> 
                        </div>
                    </div>

                    <div class="col-lg-2">
                        <h3>Evaluation</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group">
                            <select selected="" class="form-control"  (change)="choixeval($event)" >
                                <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
                              </select> 
                        </div>
                    </div>

                </div>


                <div class="table-responsive">
                    <table class="table check-tbl">
                        <thead>
                            <tr>
                                <th>N</th>
                                <th>Matricule</th>
                                <th>Noms Prenoms</th>
                                <th>A NJ(J)</th>
                                <th>A J(J)</th>
                                <th>Rds (J)</th>
                                <th>Cos (J)</th>
                            </tr>
                        </thead>
                        <tbody>
        
        
                            <tr *ngFor="let ur of alleleve" class="alert">
                                <td class="product-item-name">{{ur?.conteur}}</td>
                                <td class="product-item-price">{{ur?.Matricule}}</td>
                                <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>

                                <td> <input class="form-control" pInputText type="number" [value]="ur?.anj" (change)="ANJvv($event, ur?.Matricule)"></td>
                                <td> <input class="form-control" pInputText type="number" [value]="ur?.aj" (change)="AJvv($event, ur?.Matricule)"></td>
                                <td> <input class="form-control" pInputText type="number" [value]="ur?.reds" (change)="Rdsvv($event, ur?.Matricule)"></td>
                                <td> <input class="form-control" pInputText type="number" [value]="ur?.cons" (change)="Cosvv($event, ur?.Matricule)"></td>
                                 
                          
                            </tr>
        
                        </tbody>
                    </table>
                </div>
                






                <div class="project-head">

               


                    <div class="action">
                       
                        <button (click)="Enregistrer()" class="theme-btn btn-orange">Enregistrer</button>
                    
                    </div>





                </div>
        











               
            </div>
        </div>
    </div>
</section>




























































<!-- End Skillset -->
<app-footer-one></app-footer-one>