import { Component, OnInit } from '@angular/core';
import { Eleve } from '../../../service/eleve';
import {ActivatedRoute} from '@angular/router';
import {Router} from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { UrlsService } from '../../../services/urls.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-remplirtableetatnote',
  templateUrl: './remplirtableetatnote.component.html',
  styleUrls: ['./remplirtableetatnote.component.css']
})
export class RemplirtableetatnoteComponent implements OnInit {







  selectedFile: File
  chemainfile='';

  elevevv = [];
  classenn = [];
  niveaunn = [];
  public items_eleve= [];
  public items1: any;
  banner : any = {
		
		pagetitle: "Importation des éléments de configuration",
		bg_image: "assets/images/etablissement/fon111.png",
		title: "Importation des éléments de configuration",
	}
  public allNote;
  constructor(private http: HttpClient,private url:UrlsService,
    private router: Router,private toastr: ToastrService
    ) { }
    ngOnInit(): void {
      if(localStorage.getItem('token')){
        if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"){
  
        }else{
          this.router.navigate(['']);
        }
      }else{
        this.router.navigate(['']);
      }




    }
  showSuccess() {
    this.toastr.success('Success', 'Remplissage effectué avec succès!', {timeOut: 3000,});
  }
  showSuccessnotes() {
    this.toastr.error('error', "Les données ont déjà été importer allez vider puis reésayer !", {timeOut: 10000,});
  }








  recuptype_all_eleve() {
    return this.http
    .get<Eleve[]>(this.url.remplissagetableetatnote)
  }

  public supprimer(){


      this.recuptype_all_eleve().subscribe(
        data => {
          this.elevevv = JSON.parse(JSON.stringify(data)); 
          console.log(this.elevevv);
          this.showSuccess();
        },
          );


  }



}
