import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import {Router} from '@angular/router';
import { Infoe } from '../../../service/infoe';
@Component({
  selector: 'app-info-etablissement',
  templateUrl: './info-etablissement.component.html',
  styleUrls: ['./info-etablissement.component.css']
})
export class InfoEtablissementComponent implements OnInit {

  cars = [
    { id: "Super-administrateur", name: "Super-administrateur" },
    { id: "Administrateur", name: "Administrateur" },
    { id: "Enseignant", name: "Enseignant" },
    { id: "Personnels de l'établissement", name: "Personnels de l'établissement" },
    { id: "Parents", name: "Parents" },
    { id: "Eleves", name: "Eleves" },
    { id: "Invites", name: "Invites" },
    { id: "Censeurs", name: "Censeurs" },
    { id: "SG", name: "SG" },
    { id: "Notes", name: "Notes" },
];
sexe = [
  { id: "M", name: "M" },
  { id: "F", name: "F" },
];
infoe : any = [];
NomFvv: String="";
NomEvv: String="";
Telvv: String="";
Villevv: String="";
Regionvv: String="";
Departementvv: String="";
devisevv: String="";
Nomresponsablevv: String="";
  constructor(private router: Router ,private http: HttpClient,private url:UrlsService,private toastr: ToastrService) { 

  }
  showSuccess() {
    this.toastr.success('Success', 'Enregistrement effectuer avec succès!', {timeOut: 3000,});
  }
  showSuccessnotes() {
    this.toastr.error('error', "cet utilisateur existe déjà !", {timeOut: 5000,});
  }
  ngOnInit(): void {

    


    this.recuptype_infoe().subscribe(
      data => {
        this.infoe = JSON.parse(JSON.stringify(data)); 
        if(this.infoe.length>0){
          this.NomFvv=this.infoe[0]["nomF"];
          this.NomEvv=this.infoe[0]["nomE"];
          this.Telvv=this.infoe[0]["tel"];
          this.Villevv=this.infoe[0]["ville"];
          this.Regionvv=this.infoe[0]["region"];
          this.Departementvv=this.infoe[0]["departement"];
          this.devisevv=this.infoe[0]["devise"];
          this.Nomresponsablevv=this.infoe[0]["nomresponsable"];

        }
      },
        );




  }







  NomF(e:Event):void{
    this.NomFvv = (e.target as HTMLInputElement).value
  }
  NomE(e:Event):void{
    this.NomEvv = (e.target as HTMLInputElement).value
  }
  Tel(e:Event):void{
    this.Telvv = (e.target as HTMLInputElement).value
  }
  Ville(e:Event):void{
    this.Villevv = (e.target as HTMLInputElement).value
  }
  Region(e:Event):void{
    this.Regionvv = (e.target as HTMLInputElement).value
  }
  Departement(e:Event):void{
    this.Departementvv = (e.target as HTMLInputElement).value
  }
  devise(e:Event):void{
    this.devisevv = (e.target as HTMLInputElement).value
  }

  Nomresponsable(e:Event):void{
    this.Nomresponsablevv = (e.target as HTMLInputElement).value
  }








  recuptype_infoe() {
    return this.http
    .get<Infoe[]>(this.url.allinfoe)
  }





  Enregistrer() {

    this.http.post<any>(this.url.addinfoetablissemen,
      JSON.stringify({  

        nomf:this.NomFvv ,
        nome:this.NomEvv,
        ville:this.Villevv,
        tel:this.Telvv,
        nomresponsable: this.Nomresponsablevv,
        region:this.Regionvv,
        tedepartementl:this.Departementvv,
        devise:this.devisevv
       })).subscribe(data => {
       this.showSuccess();
        }); 




}
}
