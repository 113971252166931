<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/images/subheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">Product Showcase</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>Home</a></li>
                        <li>Shop</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start News -->
<section class="section-padding">
    <div class="container">
        <div class="row">
            <div class="col-lg-9">
                <div class="shop-header">
                    <div class="product-show">
                        <p>Showing 1-12 of 18 result</p>
                    </div>
                    <div class="product-filter">
                        <p>
                            <a href="#">Filter Product <i class="fal fa-filter text-orange"></i></a>
                        </p>
                    </div>
                </div>









                
                <div class="row">
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-1.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-2.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-3.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-4.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-5.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-6.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-7.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-8.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-9.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-10.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-11.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="shop-grid mb-xl-30">
                            <div class="shop-image animate-img">
                                <a routerLink='/shop-detail'>
                                    <img src="assets/images/shop-12.jpg" class="image-fit" alt="">
                                </a>
                                <div class="shop-overlay">
                                    <div class="shop-action">
                                        <button class="custom-tooltip" data-tip="Quick View">
                                            <i class="fal fa-eye"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to wishlist">
                                            <i class="fal fa-heart"></i>
                                        </button>
                                        <button class="custom-tooltip" data-tip="Add to cart">
                                            <i class="fal fa-shopping-cart"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="shop-caption">
                                <h5><a routerLink='/shop-detail'>Lucy Love Sophia Rust Chair</a></h5>
                                <div class="shop-price">
                                    <ins><i class="fas fa-pound-sign"></i> 85.00</ins>
                                    <del><i class="fas fa-pound-sign"></i> 270.00</del>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>











                <div class="row">
                    <div class="col-12">
                        <ul class="custom pagination justify-content-center mt-4 mb-md-80">
                            <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-left"></i></a></li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">...</a></li>
                            <li class="page-item"><a class="page-link" href="#">10</a></li>
                            <li class="page-item"><a class="page-link" href="#"><i class="far fa-angle-double-right"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <aside class="col-lg-3">
                <app-shop-sidebar></app-shop-sidebar>
            </aside>
        </div>
    </div>
</section>
<!-- End News -->
<app-footer-one></app-footer-one>