import { Component, OnInit } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-supimernote',
  templateUrl: './supimernote.component.html',
  styleUrls: ['./supimernote.component.css']
})
export class SupimernoteComponent implements OnInit {

  classevvd : any = [];
  classevvdf : any = [];
  typeuser: String="";
  public items1: any;
  public niveauvv: any;
  public classevv: any;
  public matierclasse: any;
  public notess: any;
  public matierclasseF: any;
  booleval=false;
  eval1="1";
  eval2="2";
  public notessff: any;
  public notesstrimestre: any;
  //public eleveabsv: any;
  public alleleve: any;
  eleveabs = [];
  public elevebynote: any;
  vuevv: String="Vue par tout le monde";
  evall: String="";
  nommatierevv: String="";
  ifchoixeval: String="eval";
  idclassevv: String="";
  classematierevv: any = [];
  evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];
  
    constructor(private http: HttpClient,private url:UrlsService, private router: Router, private toastr: ToastrService ) { 
  
    }
    showSuccess() {
      this.toastr.success('Success', 'Notes supprimé avec succès!', {timeOut: 5000,});
    }
    showSuccessnotehh() {
      this.toastr.error('Error', "Les notes de cette matière n'existent pas", {timeOut: 5000,});
    }
    ngOnInit(): void {
    if(localStorage.getItem('token')){
      if(localStorage.getItem('typeuser')=="Administrateur" ||localStorage.getItem('typeuser')=="Super-administrateur"
      ||localStorage.getItem('typeuser')=="Enseignant"||localStorage.getItem('typeuser')=="Censeurs"
      ||localStorage.getItem('typeuser')=="SG" ){

      }else{
        this.router.navigate(['']);
      }
    }else{
      this.router.navigate(['']);
    }

    this.recuptype_niveau().subscribe(
      data => {
        this.niveauvv = JSON.parse(JSON.stringify(data)); 
       // console.log(this.elevevv);
      },
        );
  

        this.typeuser=localStorage.getItem('typeuser');


            if(this.typeuser==='Enseignant'){


              this.http.post<any>(this.url.getclassebyenseignant,
                JSON.stringify({  
                  anseignant:  localStorage.getItem('login'),
            
                 })).subscribe(data => {
            
            
            
                  console.log(data);
            
                  this. classevvd = [];
                  this. classevvdf = [];
                  this. classevv = [];
                  this.classematierevv = [];
                  this.classevvd = JSON.parse(JSON.stringify(data)); 
                  for (let i =0; i <this.classevvd.length; i++){

                    this.classematierevv.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                    "id_matiere":this.classevvd[i].id_matiere,
                    "matriculE":this.classevvd[i].matriculE,
                    "nom_matiere":this.classevvd[i].nom_matiere})); 
            
                    let vv =0;
                    for (let ig =0; ig <this.classevvdf.length; ig++){
                      if(this.classevvd[i].id_classe==this.classevvdf[ig].idClasse){
                        vv =1;
                        break;
                      }
                    }
                        if( vv ==0){
                    this.classevvdf.push(Object.assign({"idClasse":this.classevvd[i].id_classe,
                                                    "id_matiere":this.classevvd[i].id_matiere,
                                                    "matriculE":this.classevvd[i].matriculE,
                                                    "nom_matiere":this.classevvd[i].nom_matiere}));     
                                                    
                                                  }
                  }
                  this.classevv = JSON.parse(JSON.stringify( this.classevvdf)); 
            
            /*
                  window.location.reload();
                 this.router.navigate(['Enregistrernotes']);
            */
            
            
            
                  });
            }else{
              this.recuptype_all_classe().subscribe(
                data => {
                  this.classevv = JSON.parse(JSON.stringify(data)); 
                 // console.log(this.elevevv);
                },
                  );
                    }










    }
    recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }
  
    recuptype_matiere_classe(classs: String) {
      return this.http
      .get<Eleve[]>(this.url.matierebyclasse+classs)
    }



    choixniveau(e: any) {
    
    
      this.recuptype_classe_niveau(e.target.value).subscribe(
        data => {
          this.classevv = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );
    }
  
  
    choixclasse(e: any) {
      this.idclassevv=e.target.value;

      if(this.typeuser==='Enseignant'){
        this. matierclasse = [];
        this. matierclasseF = [];
        for (let i =0; i <this.classematierevv.length; i++){
          if(this.idclassevv==this.classematierevv[i].idClasse){
  
          this.matierclasseF.push(Object.assign({"id_matiere":this.classematierevv[i].id_matiere,
                                          "nom_matiere":this.classematierevv[i].nom_matiere}));     
                                          
                }
        }
        this.matierclasse = JSON.parse(JSON.stringify( this.matierclasseF)); 
  
  
      }else{
        this.recuptype_matiere_classe(this.idclassevv).subscribe(
          data => {
            this.matierclasse = JSON.parse(JSON.stringify(data)); 
          },
            );
        }



     

  
    }
    elevebyclasse(idclasse: String) {
      return this.http
      .get<Eleve[]>(this.url.elevebyclasse+idclasse)
    }
  
  
    choixeval(e: any) {
      this.evall=e.target.value;

    }
  

    choixmatire(e: any) {


      this.nommatierevv=e.target.value;


    }


    Supprimer():void{

  if(this.evall=="Trimestre 1"||this.evall=="Trimestre 2"||this.evall=="Trimestre 3"){
    let evalv1="";
    let evalv2="";
    if(this.evall=="Trimestre 1"){
      evalv1="1";
      evalv2="2";
    }else if(this.evall=="Trimestre 2"){
      evalv1="3";
      evalv2="4";
    }else if(this.evall=="Trimestre 3"){
      evalv1="5";
      evalv2="6";
    }

    this.http.post<any>(this.url.deletenotes,
      JSON.stringify({  
        idclasse:   this.idclassevv,
        nommatiere: this.nommatierevv,
        evaluation:evalv1,
        evaluation1:evalv2,
       })).subscribe(data => {
       this.showSuccess() ;
        });
  }else{

      this.http.post<any>(this.url.deletenotes,
        JSON.stringify({  
          idclasse:   this.idclassevv,
          nommatiere: this.nommatierevv,
          evaluation:this.evall,
         })).subscribe(data => {
         this.showSuccess() ;
          });

        }
    }











  
  
  
  

  }
  