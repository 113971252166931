import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

import { ToastrService } from 'ngx-toastr';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

//import Swal from 'sweetalert2';
import {Router} from '@angular/router';
@Component({
  selector: 'app-dashboardvv',
  templateUrl: './dashboardvv.component.html',
  styleUrls: ['./dashboardvv.component.css']
})
export class DashboardvvComponent implements OnInit {
  classevvd : any = [];
  classevvdf : any = [];
  etatallmatiere1 : any = [];
  etatallmatiere2 : any = [];
  etatallmatiere3 : any = [];
  etatallmatiere4 : any = [];
  etatallmatiere5 : any = [];
  etatallmatiere6 : any = [];
  data1 = [
    {
         y: 0,
         color: "#FF7A2A",
         name: "Orange"
    },
    {
         y:  100,
         color:"#D2D2D2",
         name: "Gray"
    },
  ]
  data2 = [
    {
         y: 0,
         color: "#FF7A2A",
         name: "Orange"
    },
    {
         y:  100,
         color:"#D2D2D2",
         name: "Gray"
    },
  ]
  data3 = [
    {
         y: 0,
         color: "#FF7A2A",
         name: "Orange"
    },
    {
         y:  100,
         color:"#D2D2D2",
         name: "Gray"
    },
  ]
  data4 = [
    {
         y: 0,
         color: "#FF7A2A",
         name: "Orange"
    },
    {
         y:  100,
         color:"#D2D2D2",
         name: "Gray"
    },
  ]
  data5 = [
    {
         y: 0,
         color: "#FF7A2A",
         name: "Orange"
    },
    {
         y:  100,
         color:"#D2D2D2",
         name: "Gray"
    },
  ]
  data6 = [
    {
         y: 0,
         color: "#FF7A2A",
         name: "Orange"
    },
    {
         y:  100,
         color:"#D2D2D2",
         name: "Gray"
    },
  ]
  pourceneval1=0;
  pourceneval2=0;
  pourceneval3=0;
  pourceneval4=0;
  pourceneval5=0;
  pourceneval6=0;

  public classevv1: any;
  public classevv2: any;
  public classevv3: any;
  public classevv4: any;
  public classevv5: any;
  public classevv6: any;

  public niveauvv1: any;
  public niveauvv2: any;
  public niveauvv3: any;
  public niveauvv4: any;
  public niveauvv5: any;
  public niveauvv6: any;

  public matierclasse1: any;
  public matierclasse2: any;
  public matierclasse3: any;
  public matierclasse4: any;
  public matierclasse5: any;
  public matierclasse6: any;







  typeuser: String="";
  public items1: any;
  public notess: any;
  public matierclasseF: any;
  booleval=false;
  eval1="1";
  eval2="2";

  public notessff: any;
  public notesstrimestre: any;
  //public eleveabsv: any;
  public alleleve: any;
  eleveabs = [];
  public elevebynote: any;
  vuevv: String="Vue par tout le monde";
  evall: String="";
  nommatierevv: String="";
  ifchoixeval: String="eval";
 
  evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];

  constructor(private http: HttpClient,private url:UrlsService, private router: Router, private toastr: ToastrService) {

   }


   porcent(evalvv: String) {
     return this.http
     .get<Eleve[]>(this.url.etatremplisagenoteallget+evalvv)
   }
  ngOnInit(): void {

     this.porcent("1").subscribe(
          data => {

               console.log(data);

               this.etatallmatiere1 = JSON.parse(JSON.stringify(data)); 
               this.pourceneval1 = Math.round((this.etatallmatiere1[0]["porcent"]) * 100) / 100
               this.data1 = [
                 {
                      y: this.pourceneval1,
                      color: "#0ce629",
                      name: "Orange"
                 },
                 {
                      y:  100,
                      color:"#D2D2D2",
                      name: "Gray"
                 },
               ]
               Highcharts.chart('container1', {
                 chart: {
                   animation: false,
                   type: 'pie',
                   backgroundColor: null
              },
              title: {
                   text: null
              },
              tooltip: {
                   valueSuffix: '%',
                   enabled: false
              },
              exporting: {
                   enabled: false
              },
              credits: {
                   enabled: false
              },
              plotOptions: {
                   pie: {
                        shadow: false,
                        center: ['50%', '50%'],
                        cursor: 'pointer',
                        dataLabels: {
                        enabled: false
                        },
                   },
              },
                 series: [{
                   type: 'pie',
                   name: 'Spending',
                   data: this.data1,
                   size: '95%',
                   innerSize: '70%',
                   dataLabels: {
                        formatter: function () {
                             return this.y > 5 ? this.point.name : null;
                        },
                        color: '#ffffff',
                        distance: -30
                   }
              }]
           
           
             });

          },
            );











    /*this.http.post<any>(this.url.etatremplisagenoteall,
      JSON.stringify({  
        evaluation:  "1",
       })).subscribe(data => {


          console.log(data);

        this.etatallmatiere1 = JSON.parse(JSON.stringify(data)); 
        this.pourceneval1 = Math.round((this.etatallmatiere1[0]["porcent"]) * 100) / 100
        this.data1 = [
          {
               y: this.pourceneval1,
               color: "#0ce629",
               name: "Orange"
          },
          {
               y:  100,
               color:"#D2D2D2",
               name: "Gray"
          },
        ]
        Highcharts.chart('container1', {
          chart: {
            animation: false,
            type: 'pie',
            backgroundColor: null
       },
       title: {
            text: null
       },
       tooltip: {
            valueSuffix: '%',
            enabled: false
       },
       exporting: {
            enabled: false
       },
       credits: {
            enabled: false
       },
       plotOptions: {
            pie: {
                 shadow: false,
                 center: ['50%', '50%'],
                 cursor: 'pointer',
                 dataLabels: {
                 enabled: false
                 },
            },
       },
          series: [{
            type: 'pie',
            name: 'Spending',
            data: this.data1,
            size: '95%',
            innerSize: '70%',
            dataLabels: {
                 formatter: function () {
                      return this.y > 5 ? this.point.name : null;
                 },
                 color: '#ffffff',
                 distance: -30
            }
       }]
    
    
      });

        });*/


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
this.porcent("2").subscribe(
     data => {
          this.etatallmatiere2 = JSON.parse(JSON.stringify(data)); 
          this.pourceneval2 = Math.round((this.etatallmatiere2[0]["porcent"]) * 100) / 100
          this.data2 = [
            {
                 y: this.pourceneval2,
                 color: "#0ce629",
                 name: "Orange"
            },
            {
                 y:  100,
                 color:"#D2D2D2",
                 name: "Gray"
            },
          ]
          Highcharts.chart('container2', {
            chart: {
              animation: false,
              type: 'pie',
              backgroundColor: null
         },
         title: {
              text: null
         },
         tooltip: {
              valueSuffix: '%',
              enabled: false
         },
         exporting: {
              enabled: false
         },
         credits: {
              enabled: false
         },
         plotOptions: {
              pie: {
                   shadow: false,
                   center: ['50%', '50%'],
                   cursor: 'pointer',
                   dataLabels: {
                   enabled: false
                   },
              },
         },
            series: [{
              type: 'pie',
              name: 'Spending',
              data: this.data2,
              size: '95%',
              innerSize: '70%',
              dataLabels: {
                   formatter: function () {
                        return this.y > 5 ? this.point.name : null;
                   },
                   color: '#ffffff',
                   distance: -30
              }
         }]
      
      
        });

     },
       ); 
/*
this.http.post<any>(this.url.etatremplisagenoteall,
  JSON.stringify({  
    evaluation:  "2",
   })).subscribe(data => {
    this.etatallmatiere2 = JSON.parse(JSON.stringify(data)); 
    this.pourceneval2 = Math.round((this.etatallmatiere2[0]["porcent"]) * 100) / 100
    this.data2 = [
      {
           y: this.pourceneval2,
           color: "#0ce629",
           name: "Orange"
      },
      {
           y:  100,
           color:"#D2D2D2",
           name: "Gray"
      },
    ]
    Highcharts.chart('container2', {
      chart: {
        animation: false,
        type: 'pie',
        backgroundColor: null
   },
   title: {
        text: null
   },
   tooltip: {
        valueSuffix: '%',
        enabled: false
   },
   exporting: {
        enabled: false
   },
   credits: {
        enabled: false
   },
   plotOptions: {
        pie: {
             shadow: false,
             center: ['50%', '50%'],
             cursor: 'pointer',
             dataLabels: {
             enabled: false
             },
        },
   },
      series: [{
        type: 'pie',
        name: 'Spending',
        data: this.data2,
        size: '95%',
        innerSize: '70%',
        dataLabels: {
             formatter: function () {
                  return this.y > 5 ? this.point.name : null;
             },
             color: '#ffffff',
             distance: -30
        }
   }]


  });
    });*/

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


this.porcent("3").subscribe(
     data => {
          this.etatallmatiere3 = JSON.parse(JSON.stringify(data)); 
          this.pourceneval3 = Math.round((this.etatallmatiere3[0]["porcent"]) * 100) / 100
          this.data3 = [
            {
                 y: this.pourceneval3,
                 color: "#0ce629",
                 name: "Orange"
            },
            {
                 y:  100,
                 color:"#D2D2D2",
                 name: "Gray"
            },
          ]
          Highcharts.chart('container3', {
            chart: {
              animation: false,
              type: 'pie',
              backgroundColor: null
         },
         title: {
              text: null
         },
         tooltip: {
              valueSuffix: '%',
              enabled: false
         },
         exporting: {
              enabled: false
         },
         credits: {
              enabled: false
         },
         plotOptions: {
              pie: {
                   shadow: false,
                   center: ['50%', '50%'],
                   cursor: 'pointer',
                   dataLabels: {
                   enabled: false
                   },
              },
         },
            series: [{
              type: 'pie',
              name: 'Spending',
              data: this.data3,
              size: '95%',
              innerSize: '70%',
              dataLabels: {
                   formatter: function () {
                        return this.y > 5 ? this.point.name : null;
                   },
                   color: '#ffffff',
                   distance: -30
              }
         }]
      
      
        });

     },
       ); 


/*
this.http.post<any>(this.url.etatremplisagenoteall,
  JSON.stringify({  
    evaluation:  "3",
   })).subscribe(data => {

    this.etatallmatiere3 = JSON.parse(JSON.stringify(data)); 
    this.pourceneval3 = Math.round((this.etatallmatiere3[0]["porcent"]) * 100) / 100
    this.data3 = [
      {
           y: this.pourceneval3,
           color: "#0ce629",
           name: "Orange"
      },
      {
           y:  100,
           color:"#D2D2D2",
           name: "Gray"
      },
    ]
    Highcharts.chart('container3', {
      chart: {
        animation: false,
        type: 'pie',
        backgroundColor: null
   },
   title: {
        text: null
   },
   tooltip: {
        valueSuffix: '%',
        enabled: false
   },
   exporting: {
        enabled: false
   },
   credits: {
        enabled: false
   },
   plotOptions: {
        pie: {
             shadow: false,
             center: ['50%', '50%'],
             cursor: 'pointer',
             dataLabels: {
             enabled: false
             },
        },
   },
      series: [{
        type: 'pie',
        name: 'Spending',
        data: this.data3,
        size: '95%',
        innerSize: '70%',
        dataLabels: {
             formatter: function () {
                  return this.y > 5 ? this.point.name : null;
             },
             color: '#ffffff',
             distance: -30
        }
   }]


  });
    });*/

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


this.porcent("4").subscribe(
     data => {
          this.etatallmatiere4 = JSON.parse(JSON.stringify(data)); 
          this.pourceneval4 = Math.round((this.etatallmatiere4[0]["porcent"]) * 100) / 100
          this.data4 = [
            {
                 y: this.pourceneval4,
                 color: "#0ce629",
                 name: "Orange"
            },
            {
                 y:  100,
                 color:"#D2D2D2",
                 name: "Gray"
            },
          ]
          Highcharts.chart('container4', {
            chart: {
              animation: false,
              type: 'pie',
              backgroundColor: null
         },
         title: {
              text: null
         },
         tooltip: {
              valueSuffix: '%',
              enabled: false
         },
         exporting: {
              enabled: false
         },
         credits: {
              enabled: false
         },
         plotOptions: {
              pie: {
                   shadow: false,
                   center: ['50%', '50%'],
                   cursor: 'pointer',
                   dataLabels: {
                   enabled: false
                   },
              },
         },
            series: [{
              type: 'pie',
              name: 'Spending',
              data: this.data4,
              size: '95%',
              innerSize: '70%',
              dataLabels: {
                   formatter: function () {
                        return this.y > 5 ? this.point.name : null;
                   },
                   color: '#ffffff',
                   distance: -30
              }
         }]
      
      
        });

     },
       ); 
/*

this.http.post<any>(this.url.etatremplisagenoteall,
  JSON.stringify({  
    evaluation:  "4",
   })).subscribe(data => {

    this.etatallmatiere4 = JSON.parse(JSON.stringify(data)); 
    this.pourceneval4 = Math.round((this.etatallmatiere4[0]["porcent"]) * 100) / 100
    this.data4 = [
      {
           y: this.pourceneval4,
           color: "#0ce629",
           name: "Orange"
      },
      {
           y:  100,
           color:"#D2D2D2",
           name: "Gray"
      },
    ]
    Highcharts.chart('container4', {
      chart: {
        animation: false,
        type: 'pie',
        backgroundColor: null
   },
   title: {
        text: null
   },
   tooltip: {
        valueSuffix: '%',
        enabled: false
   },
   exporting: {
        enabled: false
   },
   credits: {
        enabled: false
   },
   plotOptions: {
        pie: {
             shadow: false,
             center: ['50%', '50%'],
             cursor: 'pointer',
             dataLabels: {
             enabled: false
             },
        },
   },
      series: [{
        type: 'pie',
        name: 'Spending',
        data: this.data4,
        size: '95%',
        innerSize: '70%',
        dataLabels: {
             formatter: function () {
                  return this.y > 5 ? this.point.name : null;
             },
             color: '#ffffff',
             distance: -30
        }
   }]


  });
    });
*/
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


this.porcent("5").subscribe(
     data => {

          this.etatallmatiere5 = JSON.parse(JSON.stringify(data)); 
          this.pourceneval5 = Math.round((this.etatallmatiere5[0]["porcent"]) * 100) / 100
          this.data5 = [
            {
                 y: this.pourceneval5,
                 color: "#0ce629",
                 name: "Orange"
            },
            {
                 y:  100,
                 color:"#D2D2D2",
                 name: "Gray"
            },
          ]
          Highcharts.chart('container5', {
            chart: {
              animation: false,
              type: 'pie',
              backgroundColor: null
         },
         title: {
              text: null
         },
         tooltip: {
              valueSuffix: '%',
              enabled: false
         },
         exporting: {
              enabled: false
         },
         credits: {
              enabled: false
         },
         plotOptions: {
              pie: {
                   shadow: false,
                   center: ['50%', '50%'],
                   cursor: 'pointer',
                   dataLabels: {
                   enabled: false
                   },
              },
         },
            series: [{
              type: 'pie',
              name: 'Spending',
              data: this.data5,
              size: '95%',
              innerSize: '70%',
              dataLabels: {
                   formatter: function () {
                        return this.y > 5 ? this.point.name : null;
                   },
                   color: '#ffffff',
                   distance: -30
              }
         }]
      
      
        });
     },
       ); 

/*this.http.post<any>(this.url.etatremplisagenoteall,
  JSON.stringify({  
    evaluation:  "5",
   })).subscribe(data => {

    this.etatallmatiere5 = JSON.parse(JSON.stringify(data)); 
    this.pourceneval5 = Math.round((this.etatallmatiere5[0]["porcent"]) * 100) / 100
    this.data5 = [
      {
           y: this.pourceneval5,
           color: "#0ce629",
           name: "Orange"
      },
      {
           y:  100,
           color:"#D2D2D2",
           name: "Gray"
      },
    ]
    Highcharts.chart('container5', {
      chart: {
        animation: false,
        type: 'pie',
        backgroundColor: null
   },
   title: {
        text: null
   },
   tooltip: {
        valueSuffix: '%',
        enabled: false
   },
   exporting: {
        enabled: false
   },
   credits: {
        enabled: false
   },
   plotOptions: {
        pie: {
             shadow: false,
             center: ['50%', '50%'],
             cursor: 'pointer',
             dataLabels: {
             enabled: false
             },
        },
   },
      series: [{
        type: 'pie',
        name: 'Spending',
        data: this.data5,
        size: '95%',
        innerSize: '70%',
        dataLabels: {
             formatter: function () {
                  return this.y > 5 ? this.point.name : null;
             },
             color: '#ffffff',
             distance: -30
        }
   }]


  });
    });
*/
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

this.porcent("6").subscribe(
     data => {
          this.etatallmatiere6 = JSON.parse(JSON.stringify(data)); 
          this.pourceneval6 = Math.round((this.etatallmatiere6[0]["porcent"]) * 100) / 100
          this.data6 = [
            {
                 y: this.pourceneval6,
                 color: "#0ce629",
                 name: "Orange"
            },
            {
                 y:  100,
                 color:"#D2D2D2",
                 name: "Gray"
            },
          ]
          Highcharts.chart('container6', {
            chart: {
              animation: false,
              type: 'pie',
              backgroundColor: null
         },
         title: {
              text: null
         },
         tooltip: {
              valueSuffix: '%',
              enabled: false
         },
         exporting: {
              enabled: false
         },
         credits: {
              enabled: false
         },
         plotOptions: {
              pie: {
                   shadow: false,
                   center: ['50%', '50%'],
                   cursor: 'pointer',
                   dataLabels: {
                   enabled: false
                   },
              },
         },
            series: [{
              type: 'pie',
              name: 'Spending',
              data: this.data6,
              size: '95%',
              innerSize: '70%',
              dataLabels: {
                   formatter: function () {
                        return this.y > 5 ? this.point.name : null;
                   },
                   color: '#ffffff',
                   distance: -30
              }
         }]
      
      
        });

     },
       ); 


/*
this.http.post<any>(this.url.etatremplisagenoteall,
  JSON.stringify({  
    evaluation:  "6",
   })).subscribe(data => {

    this.etatallmatiere6 = JSON.parse(JSON.stringify(data)); 
    this.pourceneval6 = Math.round((this.etatallmatiere6[0]["porcent"]) * 100) / 100
    this.data6 = [
      {
           y: this.pourceneval6,
           color: "#0ce629",
           name: "Orange"
      },
      {
           y:  100,
           color:"#D2D2D2",
           name: "Gray"
      },
    ]
    Highcharts.chart('container6', {
      chart: {
        animation: false,
        type: 'pie',
        backgroundColor: null
   },
   title: {
        text: null
   },
   tooltip: {
        valueSuffix: '%',
        enabled: false
   },
   exporting: {
        enabled: false
   },
   credits: {
        enabled: false
   },
   plotOptions: {
        pie: {
             shadow: false,
             center: ['50%', '50%'],
             cursor: 'pointer',
             dataLabels: {
             enabled: false
             },
        },
   },
      series: [{
        type: 'pie',
        name: 'Spending',
        data: this.data6,
        size: '95%',
        innerSize: '70%',
        dataLabels: {
             formatter: function () {
                  return this.y > 5 ? this.point.name : null;
             },
             color: '#ffffff',
             distance: -30
        }
   }]


  });
    });*/

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////












      this.recuptype_all_classe().subscribe(
        data => {
          this.classevv1 = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );
          this.recuptype_niveau().subscribe(
            data => {
              this.niveauvv1 = JSON.parse(JSON.stringify(data)); 
             // console.log(this.elevevv);
            },
              );


              this.recuptype_all_classe().subscribe(
                data => {
                  this.classevv2 = JSON.parse(JSON.stringify(data)); 
                 // console.log(this.elevevv);
                },
                  );
                  this.recuptype_niveau().subscribe(
                    data => {
                      this.niveauvv2 = JSON.parse(JSON.stringify(data)); 
                     // console.log(this.elevevv);
                    },
                      );

                      this.recuptype_all_classe().subscribe(
                        data => {
                          this.classevv3 = JSON.parse(JSON.stringify(data)); 
                         // console.log(this.elevevv);
                        },
                          );
                          this.recuptype_niveau().subscribe(
                            data => {
                              this.niveauvv3 = JSON.parse(JSON.stringify(data)); 
                             // console.log(this.elevevv);
                            },
                              );
                              this.recuptype_all_classe().subscribe(
                                data => {
                                  this.classevv4= JSON.parse(JSON.stringify(data)); 
                                 // console.log(this.elevevv);
                                },
                                  );
                                  this.recuptype_niveau().subscribe(
                                    data => {
                                      this.niveauvv4 = JSON.parse(JSON.stringify(data)); 
                                     // console.log(this.elevevv);
                                    },
                                      );
                                      this.recuptype_all_classe().subscribe(
                                        data => {
                                          this.classevv5 = JSON.parse(JSON.stringify(data)); 
                                         // console.log(this.elevevv);
                                        },
                                          );
                                          this.recuptype_niveau().subscribe(
                                            data => {
                                              this.niveauvv5 = JSON.parse(JSON.stringify(data)); 
                                             // console.log(this.elevevv);
                                            },
                                              );
                                              this.recuptype_all_classe().subscribe(
                                                data => {
                                                  this.classevv6 = JSON.parse(JSON.stringify(data)); 
                                                 // console.log(this.elevevv);
                                                },
                                                  );
                                                  this.recuptype_niveau().subscribe(
                                                    data => {
                                                      this.niveauvv6 = JSON.parse(JSON.stringify(data)); 
                                                     // console.log(this.elevevv);
                                                    },
                                                      );
  }



  recuptype_niveau() {
    return this.http
    .get<Eleve[]>(this.url.all_niveaux)
  }
  recuptype_all_classe() {
    return this.http
    .get<Eleve[]>(this.url.all_classse)
  }

    recuptype_classe_niveau(niv: String) {
    return this.http
    .get<Eleve[]>(this.url.classebyniveau+niv)
  }

  recuptype_matiere_classe(classs: String) {
    return this.http
    .get<Eleve[]>(this.url.matierebyclasse+classs)
  }



  choixniveau1(e: any) {
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv1 = JSON.parse(JSON.stringify(data)); 
      },
        );
  }
  choixclasse1(e: any) {
        this.http.post<any>(this.url.etatremplisagenote,
          JSON.stringify({  
            idclasse:  e.target.value,
            evaluation:  "1",
           })).subscribe(data => {
            this.matierclasse1 = JSON.parse(JSON.stringify(data)); 

            });
  }







  choixniveau2(e: any) {
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv2 = JSON.parse(JSON.stringify(data)); 
      },
        );
  }
  choixclasse2(e: any) {
        this.http.post<any>(this.url.etatremplisagenote,
          JSON.stringify({  
            idclasse:  e.target.value,
            evaluation:  "2",
           })).subscribe(data => {
            this.matierclasse2 = JSON.parse(JSON.stringify(data)); 

            });
  }










  choixniveau3(e: any) {
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv3 = JSON.parse(JSON.stringify(data)); 
      },
        );
  }
  choixclasse3(e: any) {
        this.http.post<any>(this.url.etatremplisagenote,
          JSON.stringify({  
            idclasse:  e.target.value,
            evaluation:  "3",
           })).subscribe(data => {
            this.matierclasse3 = JSON.parse(JSON.stringify(data)); 

            });
  }





  choixniveau4(e: any) {
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv4 = JSON.parse(JSON.stringify(data)); 
      },
        );
  }
  choixclasse4(e: any) {
        this.http.post<any>(this.url.etatremplisagenote,
          JSON.stringify({  
            idclasse:  e.target.value,
            evaluation:  "4",
           })).subscribe(data => {
            this.matierclasse4 = JSON.parse(JSON.stringify(data)); 

            });
  }









  choixniveau5(e: any) {
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv5 = JSON.parse(JSON.stringify(data)); 
      },
        );
  }
  choixclasse5(e: any) {
        this.http.post<any>(this.url.etatremplisagenote,
          JSON.stringify({  
            idclasse:  e.target.value,
            evaluation:  "5",
           })).subscribe(data => {
            this.matierclasse5 = JSON.parse(JSON.stringify(data)); 

            });
  }









  choixniveau6(e: any) {
    this.recuptype_classe_niveau(e.target.value).subscribe(
      data => {
        this.classevv6= JSON.parse(JSON.stringify(data)); 
      },
        );
  }
  choixclasse6(e: any) {
        this.http.post<any>(this.url.etatremplisagenote,
          JSON.stringify({  
            idclasse:  e.target.value,
            evaluation:  "6",
           })).subscribe(data => {
            this.matierclasse6 = JSON.parse(JSON.stringify(data)); 

            });
  }









}
