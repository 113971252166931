<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">MODIFIER UN UTILISATEUR</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>UTILISATEUR</a></li>
                        <li>MODIFIER UN UTILISATEUR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->




 

<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">


    
                <div class="row">

                    <div class="col-lg-10 col-md-12">
                        <ng2-smart-table [settings]="settings" class="table-responsive"
                        [source]="users"
                        (custom)="Custom($event)">
                        >
                        </ng2-smart-table>  
                    </div>
            
                </div>


            </div>
        </div>
    </div>
</section>


<!-- End Skillset -->
<app-footer-one></app-footer-one>

















