<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">MODIFIER LE TYPE D'UN UTILISATEUR</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>UTILISATEUR</a></li>
                        <li>MODIFIER LE TYPE D'UN UTILISATEUR</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->





<section class="section-padding">
    <div >

        <div class="row">


            <div class="col-lg-6 ">

                <div class="col-lg-12 ">
                <div class="contact-info-box mb-md-30">
                
                
                    <div class="transportation-form ptb-100">
                

                        <div class="action">
                    
                            <button (click)="Enregistrer()" class="theme-btn btn-orange">Enregistrer</button>
                        
                        </div>
    
    
    
                    </div>

                </div>
                </div>
                <br />
                <div class="col-lg-12 " >
                <div class="form-group">
                    <select selected="" class="form-control"  (change)="Vue($event)" >
                        <option    style="background-color: transparent;  color: #07095f; ">Type utilisateur</option>
                        <option *ngFor="let u of cars" [value]="u.id"    style="background-color: transparent;  color: #07095f; ">{{u.name}}</option>
                      </select> 
                </div>
               </div>
               <br />
                <div class="col-lg-12 " >
                    <div class="contact-info-box mb-md-30">
                        <div class="table-responsive">
                            <table class="table check-tbl">
                                <thead>
                                    <tr>
                                 
                                        <th>ID</th>
                                        <th>Noms Prenoms</th>
                                        <th >Login</th>
                                        <th >Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                    
                    
                                   <tr *ngFor="let ur of uservvte" class="alert">
                                 
                                        <td class="product-item-price">{{ur?.id}}</td>
                                        <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>
                                        <td class="product-item-price">{{ur?.login}}</td>
                                        <td> <button  (click)="deleteligne(ur?.id)" class='btn btn-danger' ><i class="fa fa-trash" aria-hidden="true"></i></button> </td>
                                  
                                      
                                    </tr>
                    
                                </tbody>
                            </table> 
                        </div>
                    </div>
                </div>
                <br />

            </div>




















            <div class="col-lg-6 ">

        
                <div class="section-header">
                    <div class="section-heading">
                        <h3 class="text-orange mb-xl-10 sub-heading"><span>Choisir les utilisateurs</span></h3>
                    </div>
                </div>
                <br />



        
                <ng2-smart-table [settings]="settings" class="table-responsive"
                [source]="users"
                (custom)="Custom($event)">
                >
                </ng2-smart-table> 
                
                
            </div>





        </div>


       
    </div>
</section>






<!-- End Skillset -->
<app-footer-one></app-footer-one>