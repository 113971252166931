import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UrlsService } from '../../../services/urls.service';
import { Eleve } from '../../../service/eleve';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User } from '../../../service/user';
//import Swal from 'sweetalert2';
import {Router} from '@angular/router';

@Component({
  selector: 'app-modiftypeuser',
  templateUrl: './modiftypeuser.component.html',
  styleUrls: ['./modiftypeuser.component.css']
})
export class ModiftypeuserComponent implements OnInit {

  settings = {
   
    columns: {
      id: {
        title: 'ID',
        type: 'string',
      //  width: '20px'
      },
      nomuser: {
        title: 'nom',
        type: 'string',
       // width: '200px'
      },
      prenomuser: {
        title: 'prenom',
        type: 'string',
     //   width: '200px'
      },
      login: {
        title: 'Login',
        type: 'string',
      //  width: '20px'
      },
      typeuser: {
        title: 'Type utilisateur',
        type: 'string',
      //  width: '20px'
      },

    },

    pager: {
      display: true,
      perPage: 5,
    },
    actions: {
     delete: false,
     add: false,
     edit: false,
     select: false,
     title: 'Détail',
     custom: [
      {
        name: "routeToAPage1",
        title: " <i class='fa fa-user-plus' aria-hidden='true'></i>"
      }
    ],
   },
   attr: {
    class: 'table table-hover'
  }


  };




  cars = [
    { id: "Super-administrateur", name: "Super-administrateur" },
    { id: "Administrateur", name: "Administrateur" },
    { id: "Enseignant", name: "Enseignant" },
    { id: "Personnels de l'établissement", name: "Personnels de l'établissement" },
    { id: "Parents", name: "Parents" },
    { id: "Eleves", name: "Eleves" },
    { id: "Invites", name: "Invites" },
    { id: "Censeurs", name: "Censeurs" },
    { id: "SG", name: "SG" },
    { id: "Notes", name: "Notes" },
];
typeuser: String="-";
  public users: any;

  public items1: any;
  public enseignantvv: any;
  public alluservv: any;
  uservvte = [];
  classeenregis = [];
  public classevv: any;
  public niveauvv: any;
  public matierclasse: any;
  public notess: any;
  booleval=false;
  eval1="1";
  eval2="2";
  public notessff: any;
  public notesstrimestre: any;
  //public eleveabsv: any;
  public alleleve: any;
  eleveabs = [];
  public elevebynote: any;
  vuevv: String="Vue par tout le monde";
  evall: String="";
  nommatierevv: String="";
  ifchoixeval: String="eval";
  idclassevv: String="";
  evaltab = ["Trimestre 1","Trimestre 2","Trimestre 3","1","2","3","4","5","6"];
  
    constructor(private http: HttpClient,private url:UrlsService, private router: Router,
      private toastr: ToastrService  ) { 
  
    }
    showSuccess() {
      this.toastr.success('Success', 'Type modifier avec succès!', {timeOut: 5000,});
    }
    showSuccessnote() {
      this.toastr.error('Error', "Aucun utilisateur n'est choisi", {timeOut: 5000,});
    }
    showSuccessnoteqq() {
      this.toastr.error('Error', "Impossible d'ajouter plusieurs utilisateurs", {timeOut: 5000,});
    }
    ngOnInit(): void {
      this.recuptype_user().subscribe(
        data => {
          this.users = JSON.parse(JSON.stringify(data)); 
         // console.log(this.elevevv);
        },
          );

   
    }



    recuptype_niveau() {
      return this.http
      .get<Eleve[]>(this.url.all_niveaux)
    }
    recuptype_all_classe() {
      return this.http
      .get<Eleve[]>(this.url.all_classse)
    }
  
      recuptype_classe_niveau(niv: String) {
      return this.http
      .get<Eleve[]>(this.url.classebyniveau+niv)
    }

    
    recup_enseignant() {
      return this.http
      .get<Eleve[]>(this.url.enseignantall)
    }

    recuptype_user() {
      return this.http
      .get<User[]>(this.url.users)
    }

    Vue(e: any) {

      this.typeuser=e.target.value;
    }
    Custom(event){


      if(this.uservvte.length>=1){
       this.showSuccessnoteqq();
      }else{
      this.uservvte.push({ 
        "id": event.data.id,
        "nom": event.data.nomuser,
        "prenom": event.data.prenomuser,
        "type user": event.data.typeuser,
        "login": event.data.login,
      });
    }


  
     }


    deleteligne(inCartProductId: String){

      const product = this.uservvte.find(p => {
        return p.id === inCartProductId;
      });

      if(product){
        this.uservvte.splice(this.uservvte.map(p => p.id).indexOf(inCartProductId), 1);
      }
 
    }
    Enregistrer() {
   
         if(this.typeuser==""){
          this.showSuccessnote();
          
        }else{

          this.http.post<any>(this.url.updateusertype,
            JSON.stringify({  
              typeuser: this.typeuser,
              id: this.uservvte[0]["id"]
             })).subscribe(data => {
             this.showSuccess();

             this.users = [];
             this.recuptype_user().subscribe(
              data => {
                this.users = JSON.parse(JSON.stringify(data)); 
               // console.log(this.elevevv);
              },
                );


              });
        }









    }
  
  
  
  

  }
  