<div class="sidebar">
    <div class="widget about-author-widget mb-xl-30">
        <h5 class="widget-title">About Me</h5>
        <div class="author-box">
            <img src="assets/images/sidebar-author.png" alt="author">
            <h6>Rosalina D. Willaimson</h6>
            <p class="fw-500">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                incididunt ut labore.</p>
            <ul class="social-icon custom-flex">
                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                <li><a href="#"><i class="fab fa-behance"></i></a></li>
                <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
                <li><a href="#"><i class="fab fa-youtube"></i></a></li>
            </ul>
        </div>
    </div>
    <div class="widget search-widget mb-xl-30">
        <h5 class="widget-title">Search Objects</h5>
        <form action="#">
            <input type="text" placeholder="Search your keyword...">
            <button type="submit"><i class="far fa-search"></i></button>
        </form>
    </div>
    <div class="widget popular-feeds mb-xl-30">
        <h5 class="widget-title">Popular Feeds</h5>
        <div class="popular-feed-loop">
            <div class="single-popular-feed">
                <div class="feed-img animate-img">
                    <a routerLink='/news-detail'>
                        <img src="assets/images/recent-1.png" class="image-fit rounded-circle" alt="">
                    </a>
                </div>
                <div class="feed-desc">
                    <h6 class="post-title"><a routerLink='/news-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
            <div class="single-popular-feed">
                <div class="feed-img animate-img">
                    <a routerLink='/news-detail'>
                        <img src="assets/images/recent-2.png" class="image-fit rounded-circle" alt="">
                    </a>
                </div>
                <div class="feed-desc">
                    <h6 class="post-title"><a routerLink='/news-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
            <div class="single-popular-feed">
                <div class="feed-img animate-img">
                    <a routerLink='/news-detail'>
                        <img src="assets/images/recent-3.png" class="image-fit rounded-circle" alt="">
                    </a>
                </div>
                <div class="feed-desc">
                    <h6 class="post-title"><a routerLink='/news-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
            <div class="single-popular-feed">
                <div class="feed-img animate-img">
                    <a routerLink='/news-detail'>
                        <img src="assets/images/recent-4.png" class="image-fit rounded-circle" alt="">
                    </a>
                </div>
                <div class="feed-desc">
                    <h6 class="post-title"><a routerLink='/news-detail'>Lorem ipsum dolor sit cing elit, sed do.</a></h6>
                    <span class="time"><i class="far fa-calendar-alt"></i> 24th March 2020</span>
                </div>
            </div>
        </div>
    </div>
    <div class="widget categories-widget mb-xl-30">
        <h5 class="widget-title">Categories</h5>
        <ul class="custom">
            <li>
                <a href="#">Business<span>26</span></a>
            </li>
            <li>
                <a href="#">Consultant<span>30</span></a>
            </li>
            <li>
                <a href="#">Creative<span>71</span></a>
            </li>
            <li>
                <a href="#">UI/UX<span>56</span></a>
            </li>
            <li>
                <a href="#">Technology<span>60</span></a>
            </li>
        </ul>
    </div>
    <div class="widget social-widget mb-xl-30">
        <h5 class="widget-title">Never Miss News</h5>
        <ul class="custom-flex">
            <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li><a href="#"><i class="fab fa-twitter"></i></a></li>
            <li><a href="#"><i class="fab fa-behance"></i></a></li>
            <li><a href="#"><i class="fab fa-linkedin"></i></a></li>
            <li><a href="#"><i class="fab fa-pinterest"></i></a></li>
        </ul>
    </div>
    <div class="widget twitter-feed-widget mb-xl-30">
        <h5 class="widget-title">Twitter Feeds</h5>
        <ul class="custom">
            <li>
                <a href="#">
                    Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @
                    ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1
                </a>
                <span class="date">November 25, 2020</span>
            </li>
            <li>
                <a href="#">
                    Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @
                    ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1
                </a>
                <span class="date">November 25, 2020</span>
            </li>
            <li>
                <a href="#">
                    Rescue - #Gutenberg ready @ wordpress Theme for Creative Bloggers available on @
                    ThemeForest https://t.co/2r1POjOjgVC… https://t.co/rDAnPyClu1
                </a>
                <span class="date">November 25, 2020</span>
            </li>
        </ul>
    </div>
    <div class="widget instagram-feed-widget mb-xl-30">
        <h5 class="widget-title">Instagram Feeds</h5>
        <ul class="custom-flex">
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-1.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-2.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-3.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-4.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-5.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-6.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-7.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-8.jpg" class="image-fit" alt="">
                </a>
            </li>
            <li class="animate-img">
                <a href="#">
                    <img src="assets/images/insta-9.jpg" class="image-fit" alt="">
                </a>
            </li>
        </ul>
    </div>
    <div class="widget popular-tag-widget mb-xl-30">
        <h5 class="widget-title">Popular Tags</h5>
        <div class="tags">
            <a href="#">Popular</a>
            <a href="#">Design</a>
            <a href="#">UX</a>
            <a href="#">Usability</a>
            <a href="#">Develop</a>
            <a href="#">Icon</a>
            <a href="#">Business</a>
            <a href="#">Consult</a>
            <a href="#">Kit</a>
            <a href="#">Keyboard</a>
            <a href="#">Mouse</a>
            <a href="#">Tech</a>
        </div>
    </div>
    <div class="advertisement-banner">
        <img src="assets/images/blog-ad.jpg" class="image-fit" alt="">
    </div>
</div>