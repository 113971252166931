<app-header-one></app-header-one>
<!-- Start Subheader -->
<div class="normal-bg subheader" style="background-image: url('assets/etablissement/bg-pheader.jpg');">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="subheader-content">
                    <h1 class="page-title text-custom-white fw-600">MODIFIER LES NOTES</h1>
                    <ul class="custom-flex breadcrumb">
                        <li><a routerLink='/'>NOTES</a></li>
                        <li>MODIFIER LES NOTES</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Subheader -->
<!-- Start Services -->



<br />    <br />
<div class="row">
<div  class="col-lg-3"></div>
<div  class="col-lg-6 ">
    <div class="contact-info-box mb-md-30">

        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur'">
            <select selected="" class="form-control"  (change)="choixniveau($event)" >
                <option    >Tous les niveaux</option>
                <option *ngFor="let u of niveauvv" [value]="u.nomniveau"   >{{u.nomniveau}}</option>
              </select> 
        </div>
        <div class="form-group" *ngIf="typeuser==='Administrateur' || typeuser==='Super-administrateur' || typeuser==='Enseignant' ">
            <select selected="" class="form-control"  (change)="choixclasse($event)" >
                <option     >Toutes les classe</option>
                <option *ngFor="let u of classevv" [value]="u.idClasse"   >{{u.idClasse}}</option>
              </select> 
        </div>

        <div class="form-group">
            <select selected="" class="form-control"  (change)="choixeval($event)" >
                <option    >Evaluation</option>
                <option *ngFor="let u of evaltab" [value]="u"    >{{u}}</option>
              </select> 
        </div>

        <div class="form-group">
            <select selected="" class="form-control"  (change)="choixmatire($event)" >
                <option    >Matières</option>
                <option *ngFor="let u of matierclasse" [value]="u.nom_matiere"    >{{u.nom_matiere}}</option>
              </select> 
        </div>


    </div>
   </div>
   <div  class="col-lg-3"></div>
</div>




<section class="section-padding project-details">
    <div class="container">
        <div class="row">
            <div class="col-12">



            

                <div class="row">

                    <div class="col-lg-2">
                        <h3>Compétence</h3>
                    </div>
                
                    <div class="col-lg-10">
                        <div class="form-group">
                            <input class="form-control" [value]="competencevv"  type="text" (change)="valChangecompe($event)" placeholder="Compétence">
                        </div>
                    </div>
            
                </div>


                <div class="table-responsive" *ngIf="booleval == false">
                    <table class="table check-tbl">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Matricule</th>
                                <th>Noms Prenoms</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
            
            
                           <tr *ngFor="let ur of notess" class="alert">
                                <td class="product-item-name">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>
                                
                                <td> <input class="form-control" type="text" [value]="ur?.note" (change)="valChange($event, ur?.matricule)"></td>
                                <!--<td> <input class="form-control" [(ngModel)]="values[ur?.note]"  #ctrl="ngModel" required  (change)="valChange($event, ur?.matricule)"></td>-->
                                   
                            </tr>
            
                        </tbody>
                    </table>
                </div>
                





                <div class="table-responsive" *ngIf="booleval == true">
                    <table class="table check-tbl">
                        <thead>
                            <tr>
                                <th>Num</th>
                                <th>Matricule</th>
                                <th>Noms Prenoms</th>
                                <th>Eval {{eval1vv}}</th>
                                <th>Eval {{eval2vv}}</th>
                            </tr>
                        </thead>
                        <tbody>
            
            
                           <tr *ngFor="let ur of notess" class="alert">
                                <td class="product-item-name">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.matricule}}</td>
                                <td class="product-item-price">{{ur?.nom}} {{ur?.prenom}}</td>
                                <td> <input class="form-control" type="text" [value]="ur?.note1" (change)="eval1recup($event, ur?.matricule)"></td>
                                <td> <input class="form-control" type="text" [value]="ur?.note2" (change)="eval2recup($event, ur?.matricule)"></td>
                                <!--<td> <input class="form-control" [(ngModel)]="values[ur?.note]"  #ctrl="ngModel" required  (change)="valChange($event, ur?.matricule)"></td>-->
                                   
                            </tr>
            
                        </tbody>
                    </table>
                </div>









                <div class="action">
                    
                    <button (click)="Enregistrer()" class="theme-btn btn-orange">Enregistrer</button>
                
                </div>


               
            </div>
        </div>
    </div>
</section>




























































<!-- End Skillset -->
<app-footer-one></app-footer-one>